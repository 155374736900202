<!-- PROFORMA TEMPLATE -->
<div>
  <div mat-dialog-content id="dataToPrint">
    <!-- Table to format the header of the proforma page -->
    <table>
      <tbody>
        <tr>
          <td>
            <img style="width: auto; height: 40px; margin: 5px; margin-right: 45px;"
              src="../../../assets/images/globex-logo.png" alt="Globex">
          </td>
          <td>
            <div align="left" class="proformaH1"><b>{{"app.shipmentSummary.proformaInvoice" | translate}}</b></div>
            <div align="left" class="proformaH2"><b>{{"app.shipmentSummary.shipmentDate" | translate}}</b>
              {{data.Order.ORD_ShipmentDate | date: 'yyyy-MM-dd'}}</div>
          </td>
        </tr>
      </tbody>
    </table>
    <hr align="left" width="99%">
    <!-- Table to format the details about the proforma -->
    <table width="100%">
      <tbody>
        <!-- Shipper and Consignee title -->
        <tr>
          <td class="proformaH2" colspan="2"><b>{{"app.shipmentSummary.shipper" | translate}}</b></td>
          <td class="proformaH2" colspan="2"><b>{{"app.shipmentSummary.consignee" | translate}}</b></td>
        </tr>
        <!-- Company name -->
        <tr>
          <td width="*">{{"app.shipmentSummary.company" | translate}}</td>
          <td width="3*">{{ getCompanyName(data.OriginAddress.OAD_Company) }} </td>
          <td width="*">{{"app.shipmentSummary.company" | translate}}</td>
          <td width="3*">{{ getCompanyName(data.DestinationAddress.OAD_Company) }}</td>
        </tr>
        <!-- Street number and name -->
        <tr>
          <td>{{"app.shipmentSummary.address" | translate}}</td>
          <td>{{data.OriginAddress.OAD_StreetName}}</td>
          <td>{{"app.shipmentSummary.address" | translate}}</td>
          <td>{{data.DestinationAddress.OAD_StreetName}}
          </td>
        </tr>
        <!-- City and state-->
        <tr>
          <td>{{"app.shipmentSummary.city" | translate}}</td>
          <td>{{data.OriginAddress.OAD_City}}, {{ getStateName(toNumber(data.OriginAddress.OAD_State)) }}</td>
          <td>{{"app.shipmentSummary.city" | translate}}</td>
          <td>{{data.DestinationAddress.OAD_City}}, {{ getStateName(toNumber(data.DestinationAddress.OAD_State)) }}</td>
        </tr>
        <!-- Country -->
        <tr>
          <td>{{"app.shipmentSummary.country" | translate}}</td>
          <td>{{getCountryName(toNumber(data.OriginAddress.OAD_Country))}}</td>
          <td>{{"app.shipmentSummary.country" | translate}}</td>
          <td>{{getCountryName(toNumber(data.DestinationAddress.OAD_Country))}}</td>
        </tr>
        <!-- Zip Code -->
        <tr>
          <td>{{"app.shipmentSummary.zipCode" | translate}}</td>
          <td>{{data.OriginAddress.OAD_Zip}}</td>
          <td>{{"app.shipmentSummary.zipCode" | translate}}</td>
          <td>{{data.DestinationAddress.OAD_Zip}}</td>
        </tr>
        <!-- Phone -->
        <tr>
          <td>{{"app.shipmentSummary.phone" | translate}}</td>
          <td>{{data.OriginContact.OAC_Phone}}</td>
          <td>{{"app.shipmentSummary.phone" | translate}}</td>
          <td>{{data.DestinationContact.OAC_Phone}}</td>
        </tr>
        <!-- Email -->
        <tr>
          <td>{{"app.shipmentSummary.email" | translate}}</td>
          <td>{{data.OriginContact.OAC_Email}}</td>
          <td>{{"app.shipmentSummary.email" | translate}}</td>
          <td>{{data.DestinationContact.OAC_Email}}</td>
        </tr>
        <!-- Contact name -->
        <tr>
          <td>{{"app.shipmentSummary.contact" | translate}} </td>
          <td>{{data.OriginContact.OAC_Name}}</td>
          <td>{{"app.shipmentSummary.contact" | translate}} </td>
          <td>{{data.DestinationContact.OAC_Name}}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>{{"app.misc.taxID" | translate}}</td>
          <td>{{data.Dutiable.ODU_TaxID}}</td>
        </tr>
        <!-- Separator -->
        <tr>
          <td colspan="4">
            <hr>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="data.IorAddress && data.IorAddress.OAD_Company">
      <table *ngIf="data.IorAddress.OAD_Company.toString().trim() != ''">
          <tr colspan="4" [ngClass]="data.IorAddress.OAD_Company ? '' : 'hide-ior'">
            <td width="150" style="font-weight: bold;white-space: nowrap;" class="Maintext">
              {{"app.shipmentSummary.iorTitle" | translate }}</td>
            <td width="100" class="Maintext">{{"app.shipmentSummary.iorTaxID" | translate }} :
              {{data.IorAddress.OAD_TaxID}}</td>
          </tr>
          <tr [ngClass]="data.IorAddress.OAD_Company ? '' : 'hide-ior'">
            <td width="100" class="Maintext">{{"app.shipmentSummary.iorNotes" | translate }}</td>
            <td width="225" class="Maintext">
              <span class="Maintext">{{data.IorAddress.OAD_SpecialInstructions}}</span>
            </td>
          </tr>
          <tr [ngClass]="data.IorAddress.OAD_Company ? '' : 'hide-ior'">
            <td width="100" class="Maintext">{{"app.shipmentSummary.iorCompany" | translate }}</td>
            <td width="225" class="Maintext">
              <span class="Maintext">{{ getCompanyName(data.IorAddress.OAD_Company)}}</span>
            </td>
          </tr>
          <tr [ngClass]="data.IorAddress.OAD_Company ? '' : 'hide-ior'">
            <td class="Maintext">{{"app.shipmentSummary.iorAddress" | translate }}</td>
            <td class="Maintext" colspan="4" style="white-space: nowrap;">
              <span class="Maintext">{{data.IorAddress.OAD_StreetName + (data.IorAddress.OAD_Unit ? ', ' +
                data.IorAddress.OAD_Unit : '') + ', ' + data.IorAddress.OAD_City + ', ' +
                getStateName(toNumber(data.IorAddress.OAD_State)) + ', ' +
                getCountryName(toNumber(data.IorAddress.OAD_Country)) + ', ' + data.IorAddress.OAD_Zip}}</span>
            </td>
          </tr>
          <tr [ngClass]="{'hide-ior': !data.IorAddress.OAD_Company, 'top-aligned-row': data.IorAddress.OAD_Company}">
            <td class="Maintext">{{"app.shipmentSummary.iorPhone" | translate }}</td>
            <td class="Maintext">
              <span class="Maintext">{{data.IorContact.OAC_Phone}}</span>
            </td>
          </tr>
          <tr [ngClass]="data.IorAddress.OAD_Company ? '' : 'hide-ior'">
            <td class="Maintext">{{"app.shipmentSummary.iorEmail" | translate }}</td>
            <td class="Maintext">
              <span class="Maintext">{{data.IorContact.OAC_Email}}</span>
            </td>
          </tr>
          <tr [ngClass]="data.IorAddress.OAD_Company ? '' : 'hide-ior'">
            <td class="Maintext">{{"app.shipmentSummary.iorContact" | translate }}</td>
            <td class="Maintext">
              <span class="Maintext">{{data.IorContact.OAC_Name}}</span>
            </td>
          </tr>
          <tr [ngClass]="data.IorAddress.OAD_Company ? '' : 'hide-ior'">
            <td colspan="4">
              <hr>
            </td>
          </tr>
      </table>
    </div>
    <table>
      <tbody>
        <!-- Shipper broker and Consignee tax ID-->
        <tr>
          <td colspan="2"><b>{{"app.shipmentSummary.customsBroker" | translate}}: </b>{{data.Dutiable.ODU_Broker}} </td>
        </tr>
        <!-- Country of export -->
        <tr>
          <td colspan="2">
            <b>{{"app.shipmentSummary.countryOfExport" | translate}}: </b>{{data.Dutiable.ODU_CountryOfOrigin}}
          </td>
        </tr>
        <!-- Order ID -->
        <tr>
          <td colspan="2"><b>{{"app.shipmentSummary.awb" | translate}}: </b>{{data.Order.ORD_ID}}</td>
        </tr>
        <tr>
          <td colspan="4" style="font-size: 14;"><b>{{data.Dutiable.ODU_ExportReasonDetail}}</b></td>
        </tr>
      </tbody>
    </table>
    <br>
    <!-- Table to display the list of products -->
    <table mat-table [dataSource]="dataSource" align="left" width="100%" border="1" border-color="gray" rules="all"
      cellspacing="0">

      <!-- Description Column -->
      <ng-container matColumnDef="ODP_Description">
        <th mat-header-cell *matHeaderCellDef>{{"app.shipmentSummary.description" | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.ODP_Description}} </td>
      </ng-container>

      <!-- Quantity Column -->
      <ng-container matColumnDef="ODP_Quantity">
        <th mat-header-cell *matHeaderCellDef>{{"app.shipmentSummary.quantity" | translate}}</th>
        <td align="center" mat-cell *matCellDef="let row"> {{row.ODP_Quantity}} </td>
      </ng-container>

      <!-- Manufacture Country Code Column -->
      <ng-container matColumnDef="ODP_ManufactureCountryCode">
        <th mat-header-cell *matHeaderCellDef>{{"app.shipmentSummary.originCountry" | translate}}</th>
        <td align="center" mat-cell *matCellDef="let row"> {{row.ODP_ManufactureCountryCode}} </td>
      </ng-container>

      <!-- HSCode Column -->
      <ng-container matColumnDef="ODP_HSCode">
        <th mat-header-cell *matHeaderCellDef>{{"app.shipmentSummary.hsCode" | translate}}</th>
        <td align="center" mat-cell *matCellDef="let row"> {{row.ODP_HSCode}} </td>
      </ng-container>

      <!-- Unit Value Column -->
      <ng-container matColumnDef="ODP_Value">
        <th mat-header-cell *matHeaderCellDef>{{"app.shipmentSummary.unitValue" | translate}}</th>
        <td align="right" mat-cell *matCellDef="let row"> {{row.ODP_Value | number : '1.2-2'}} $</td>
      </ng-container>

      <!-- Total Value Column -->
      <ng-container matColumnDef="totalValue">
        <th mat-header-cell *matHeaderCellDef>{{"app.shipmentSummary.totalValue" | translate}}</th>
        <td align="right" mat-cell *matCellDef="let row"> {{row.ODP_Value * row.ODP_Quantity | number : '1.2-2'}} $
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!--Total value for customs -->
    <div>&nbsp;</div>
    <div align="right">
      <b>{{"app.shipmentSummary.totalValueForCustoms" | translate}}:&nbsp;&nbsp; </b>
      {{totalValue | number : '1.2-2'}} $ &nbsp;{{data.Dutiable.ODU_Currency}}&nbsp;
    </div>

    <!--AuthorizedSignature-->
    <br>
    X______________________________________________________
    <br>
    {{"app.shipmentSummary.authorizedSignature" | translate}}
  </div>

  <!-- Container for action buttons at the bottom of the dialog -->
  <mat-dialog-actions>
    <div align="end" class="btn-position">
      <button mat-raised-button color="primary" printSectionId="dataToPrint" ngxPrint>
        {{"app.misc.print" | translate}}
      </button>
      <button mat-raised-button (click)="cancel()">
        {{"app.misc.close" | translate}}
      </button>
    </div>
  </mat-dialog-actions>
</div>