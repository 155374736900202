
import { Directive, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[appCancelButton]'
})
export class StopFormSubmissionDirective {
  @Input() formGroup: FormGroup;

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.formGroup) {
      this.formGroup.markAsPristine();
      this.formGroup.markAsUntouched();
    }
  }
}
