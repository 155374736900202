import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { spCSDAManageClientGetListResult } from "@app/shared/CSDAModels/ManageClientModels/Client/spCSDAManageClientGetListResult"
import Swal from "sweetalert2";
import { ManageClientsService } from "../_Services/manage-clients.service";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatTooltip } from "@angular/material/tooltip";
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { Router } from "@angular/router";
import { DivisionsComponent } from "../Divisions/Divisions.component";

const matTooltipDefaultOptions: MatTooltipDefaultOptions = {
  disableTooltipInteractivity: true,
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0
};

//-----------------------------------------------------------------------------------------------
// https://muhimasri.com/blogs/add-and-remove-table-rows-using-angular-material/
// https://stackblitz.com/edit/angular-editable-table-part-2-7hvbyt?file=src/app/app.component.ts
//-----------------------------------------------------------------------------------------------

@Component({
  selector: 'Clients',
  templateUrl: './Clients.component.html',
  styleUrls: ['./Clients.component.scss'],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipDefaultOptions }]
})

export class ClientsComponent implements OnInit, AfterViewInit {
  //---------------------------------------------------
  // Define the fields of the model which are displayed
  //---------------------------------------------------
  COLUMNS_SCHEMA = this.getSchema();
  displayedColumns: string[] = this.COLUMNS_SCHEMA.map((col) => col.key);
  dataSource = new MatTableDataSource([]);
  isProcessing = false;
  selectedClientId = -1;
  originalClient: spCSDAManageClientGetListResult;
  editing = false;

  constructor(
    private translateService: TranslateService,
    private ManageClientsService: ManageClientsService,
    private router: Router
  ) { }

  @Output('selectedClient') selectedClient: EventEmitter<number> = new  EventEmitter<number>();
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.isProcessing = true;

    this.ManageClientsService.getClients().subscribe(Result => {
      this.dataSource = new MatTableDataSource(Result.ClientsList);
      this.dataSource.sort = this.sort;
      this.isProcessing = false;
    });
}

  ngOnInit(): void {
  }

  getSchema() {
    return [
      // {
      //   key: 'isSelected',
      //   type: 'isSelected',
      //   label: '',
      //   visible: true
      // },
      {
        key: 'CLT_Name',
        type: 'text',
        label: 'Nom Complet',
        labelEn: 'Full Name'
      },
      {
        key: 'CLT_Desc',
        type: 'text',
        label: 'Description',
        labelEn: 'Description'
      },
      {
        key: 'isEdit',
        type: 'isEdit',
        label: ''
      }
    ];
  }
  
  addRow() {
    const newRow = {
      CLT_Id: -1,
      CLT_Name: '',
      CLT_Desc: '',
      isEdit: true,
    };

    this.editing = true;
    this.dataSource.data = [newRow, ...this.dataSource.data];
    //this.dataSource = [newRow, ...this.dataSource];
  }
  
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
	
  removeRow(element) {
    Swal.fire({
      title: 'Confirmation',
      icon: "question",
      showDenyButton: true,
      denyButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes"),

    }).then((result) => {
      if (result.isConfirmed) {
        this.isProcessing = true;

        this.ManageClientsService.deleteClient(element).subscribe(Result => {
          this.isProcessing = false;
          this.dataSource.data = this.dataSource.data.filter((u) => u.CLT_Id != element.CLT_Id);
          this.ManageClientsService.deactivateClient();
        });
      }
    });    
  }

  removeSelectedRows() {
    Swal.fire({
      title: 'Confirmation',
      icon: "question",
      showDenyButton: true,
      denyButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes"),

    }).then((result) => {
      if (result.isConfirmed) {
        this.dataSource.data = this.dataSource.data.filter((u) => !u.isSelected);
      }
    });    
  }

  saveChanges(element: any): boolean {
    this.isProcessing = true;

    const index = this.dataSource.data.findIndex(object => {
      return object.CLT_Id === element.CLT_Id;
    });

    this.ManageClientsService.saveClient(element).subscribe(Result => {
      this.dataSource.data[index].CLT_Id = Result.CLT_Id;
      this.isProcessing = false;
    });
    
    this.editing = false;
    return false;
  }

  editClient(element: any) {
    this.editing = true;
    element.isEdit = true;
    this.originalClient = JSON.parse(JSON.stringify(element));

    return false;
  }

  cancelEdit(element: any) {
    element.isEdit = false

    if(element.CLT_Id == -1) {
      this.dataSource.data = this.dataSource.data.filter((u) => u.CLT_Id != element.CLT_Id);
    }
    else {
        element.CLT_Name  = this.originalClient.CLT_Name;
        element.CLT_Desc  = this.originalClient.CLT_Desc;
    }
    
    this.editing = false;
    return false;
  }

  highlight(row){

    if(!this.editing) {
      if(this.selectedClientId != row.CLT_Id)
        this.selectedClient.emit(row.CLT_Id);
        
      this.selectedClientId = row.CLT_Id;
    }

    return false;
  }  
}
