// Use to display step 1
import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

/** Models from core module */
import { BaseComponentDirective } from "@app/core/base.component";
import { Language, Language as LanguageEnum } from "@app/core/enums/Language";
import { HeaderService } from "@app/core/services/header.service";

/** Enums */
import { PackageType } from "@app/core/enums/PackageType";
import { ReasonForExport } from "@app/core/enums/ReasonForExport";
import { Carriers } from "@app/core/enums/Carriers";
import { TypeOfCurrency } from "@app/core/enums/type-of-currency";
import { DocumentReturnType } from "@app/core/enums/DocumentReturnType";

/** Services */
import { LanguageService } from "@app/core/services/language.service";
import { ThirdPartyService } from "@app/core/services/third-party.service";
import { CreateNewShipmentService } from "../../services/create-new-shipment.service";
import { DrawerService } from '@app/core/services/drawer.service';
import { animate, state, style, transition, trigger } from "@angular/animations";

/** Models from CSDAModels */
import { QuoteDetail } from "@app/shared/CSDAModels/QuoteDetail";
import { Quote } from "@app/shared/CSDAModels/Quote";
import { GetOrderDetail } from "@app/shared/CSDAModels/GetOrderDetail";
import { ShipmentDutiableRow } from "@app/shared/CSDAModels/ShipmentDutiableRow";
import { CreateShipmentDetail } from "@app/shared/CSDAModels/CreateShipmentDetail";
import { ShipmentPackageModel } from "@app/shared/CSDAModels/ShipmentPackageModel";
import { ShipmentAddressModel } from "@app/shared/CSDAModels/ShipmentAddressModel";
import { ValidationService } from "@app/core/services/validation.service";
import { UnitOfMeasure } from "@app/core/enums/unit-of-measure";
import { StorageService } from "@app/core/services/storage.service";
import { Countries } from "@app/shared/CSDAModels/Countries";
import { Provinces } from "@app/shared/CSDAModels/Provinces";
import { spGetAddressListForDivisionResult } from "@app/shared/CSDAModels/spGetAddressListForDivisionResult";
import { Step1ShipmentFormComponent } from '../step1-shipment-form/step1-shipment-form.component';
import { PLTDocument } from "@app/shared/CSDAModels/PLTDocument";
import { MatCheckbox } from "@angular/material/checkbox";
import { UsersRights } from "@app/shared/CSDAModels/UserRights";
import { catchError, delay, switchMap, tap } from "rxjs/operators";
import { PaymentCardService } from "@app/shared/components/creditcard/service/payment-card.service";
import { PayWithCreditCardResult } from "@app/shared/CSDAModels/PayWithCreditCardResult";
import { VoidCreditCardTransactionResult } from "@app/shared/CSDAModels/VoidCreditCardTransactionResult";
import { ShipmentService } from "@app/features/active-orders/services/shipment.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { CreatePickupDialogComponent } from "@app/shared/components/create-pickup-dialog-box/create-pickup-dialog/create-pickup-dialog.component";
import { CreatePickupsDetail } from "@app/shared/CSDAModels/CreatePickupsDetail";
import { DatePipe } from "@angular/common";
import { EMPTY, Observable, Subject, throwError } from "rxjs";
import { ActionService } from "@app/core/services/action.service";

@Component({
  selector: 'app-create-shipment-stepper',
  templateUrl: './create-shipment-stepper.component.html',
  styleUrls: ['./create-shipment-stepper.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        left: '248px'
      })),
      state('closed', style({
        left: '0px'
      })),
      transition('open <=> closed', [
        animate('.15s')
      ])
    ]),
  ]
 
})
export class CreateShipmentStepperComponent extends BaseComponentDirective {

  order: QuoteDetail;
  shipmentFormGroup: FormGroup;

  @ViewChild('stepper') private myStepper: MatStepper;
  @ViewChild(Step1ShipmentFormComponent) Step1ShipmentForm: Step1ShipmentFormComponent;
  @ViewChild('dryIce') dryIce: MatCheckbox;

  quotes: Quote[] = [];
  quotesMessage = '';
  selectedIndex = 0;
  shpId: number;
  shipmentId: number;
  isQuickQuote = sessionStorage.getItem("quoteType") == "51";
  leftGetQuotesAndSaveButtons = '248px';
  animationState: 'open' | 'closed';
  
  /** Flags */
  displayPrint = false;
  isProcessing = false;
  stepOneCompleted = false;
  stepTwoCompleted = false;
  forceReference = false;

  /** List of enums */
  pkgTypes = PackageType;
  exportRaison = ReasonForExport;
  currentLanguage: LanguageEnum;
  countriesList: Countries[];
  statesProvincesList: Provinces[];
  addressesList: spGetAddressListForDivisionResult[];
  productFormVisible: boolean;
  isGuest: boolean = false;
  isCreditCard: boolean = sessionStorage.getItem("PaymentMethod") === "CreditCard";

  /** Constructor of the CreateShipmentStepperComponent class
   */
    constructor(
    private drawerService: DrawerService,
    private headerService: HeaderService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private createNewShipmentService: CreateNewShipmentService,
    private thirdPartyService: ThirdPartyService,
    private validationService: ValidationService,
    public paymentCardService: PaymentCardService,
		private shipmentService: ShipmentService,
    public dialog: MatDialog,
    public datepipe: DatePipe,  
    private actionService: ActionService
) {
    super();

    this.drawerService.isDrawerOpen$.subscribe(isOpen => {
        this.animationState = isOpen ? 'open' : 'closed';
        this.leftGetQuotesAndSaveButtons = isOpen ? '248px' : '0px';
    });
}

  /** Initialize the view components
  */
  onInit(): void {
    this.countriesList = JSON.parse(sessionStorage.getItem("Countries")) as Countries[];
    this.statesProvincesList = JSON.parse(sessionStorage.getItem("Provinces")) as Provinces[];
    this.addressesList = JSON.parse(sessionStorage.getItem("AddressesList")) as spGetAddressListForDivisionResult[];    
    this.forceReference = JSON.parse(sessionStorage.getItem("ForceReference")) as boolean;

    this.isGuest = sessionStorage.getItem("isGuest") !== null;

    if(this.isGuest) {
      this.leftGetQuotesAndSaveButtons = "0px";
      this.animationState = "closed";
    }

    if(sessionStorage.getItem("quoteType") === "51")
      this.headerService.setTitle(this.translateService.instant("app.menu.quickQuotes"));
    else
      this.headerService.setTitle(this.translateService.instant("app.menu.quotes"));
  }

  ngAfterViewInit(): void {
    this.initialiseCreateShipmentStepper();
  }

  /** Empty override of the onDestroy method
   */
  onDestroy(): void {
    this.storageService.removeOrderId();
  }

  /** Initialise the create shipment stepper value
   */
  initialiseCreateShipmentStepper(): void {
    this.isProcessing = true;

    // /** Set page title */
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if(sessionStorage.getItem("quoteType") === "51")
        this.headerService.setTitle(this.translateService.instant("app.menu.quickQuotes"));
      else
        this.headerService.setTitle(this.translateService.instant("app.menu.quotes"));
    });

      /** If we come from a third party then we take the order of this third party via the service */
    if (this.thirdPartyService.isFromThirdParty) {
      /** Get an existing order or a new instance of an order */
      const orderDetail: GetOrderDetail = {
        OrderId: null,
        Language: this.languageService.GetCurrentLanguage(),
        IsDevelopmentEnvironment: false
      };

      this.createNewShipmentService.getCurrentOrder(orderDetail).subscribe((result) => {
        if (result !== null) {
          this.order = this.thirdPartyService.order;
          this.order.ThirdPartyId = this.thirdPartyService.thirdPartyId;
          this.order.ThirdPartyOrderNumber = this.thirdPartyService.thirdPartyOrderNumber;
              
          sessionStorage.setItem("customPackages", JSON.stringify(result.CustomPackages));
          sessionStorage.setItem("customProducts", JSON.stringify(result.CustomProducts));
          sessionStorage.setItem("paperlessCountries", JSON.stringify(result.PaperLessCountries));

          this.createForms();
        }

        this.isProcessing = false;
      });
    }
    else {
      /** Get an existing order or a new instance of an order */
      const orderDetail: GetOrderDetail = {
        OrderId: + this.storageService.getOrderId(),
        Language: this.languageService.GetCurrentLanguage(),
        IsDevelopmentEnvironment: false
      };

      this.createNewShipmentService.getCurrentOrder(orderDetail).subscribe((result) => {
        if (result !== null) {
          const guestQuickQuote = sessionStorage.getItem("guestQuickQuote");
      
          if(guestQuickQuote) {
            this.order = JSON.parse(guestQuickQuote) as QuoteDetail;

            //-----------------------------------------------------------------------------------
            // This is just to make sure that the validation for the DryIce will not be triggered
            //-----------------------------------------------------------------------------------
            this.order.IsDryIce = false;

            this.order.Packages.forEach((packageItem: ShipmentPackageModel) => {
              packageItem.DryIceWeight = 0;
            });

            sessionStorage.removeItem("guestQuickQuote");
          }
          else
            this.order = result.Order;
          
          sessionStorage.setItem("customPackages", JSON.stringify(result.CustomPackages));
          sessionStorage.setItem("customProducts", JSON.stringify(result.CustomProducts));
          sessionStorage.setItem("paperlessCountries", JSON.stringify(result.PaperLessCountries));

          this.createForms();


          if(this.storageService.getGoToStepTwo()) {
            this.getQuotes();
          }
          else
          {
            this.isProcessing = false;

            /** If we came from a duplicate shipment request, the Orders number are null and the ThirdPartyId is CSDA  */
            if (this.storageService.getShipmentOrigin() === '/active-orders') {
              this.order.OrderId = null;
              this.order.ThirdPartyId = 2;
              this.order.ThirdPartyOrderNumber = null;
            }
          }
        }
      });
    }
  }

  /** Create all the necessary forms for the view
   */
  createForms(): void {
    this.productFormVisible = !this.order.IsDocumentOnly && this.order.Origin.CountryCode !== this.order.Destination.CountryCode;

    //---------------------------------------------------------------------------------------------
    // The backend and carriers use the ISO country code 2 letters while the UI uses the country id
    // Here we get the id from the code coming from the backend
    //---------------------------------------------------------------------------------------------
    const shipperCountry = this.countriesList.find(c => c.CON_Code == this.order.Origin.CountryCode);
    const consigneeCountry = this.countriesList.find(c => c.CON_Code == this.order.Destination.CountryCode);
    const iorCountry = this.countriesList.find(c => c.CON_Code == this.order.ImporterOfRecord?.CountryCode);
    const shipperStatesProvinces = this.statesProvincesList.find(s => s.PRV_Code == this.order.Origin.StateCode && s.PRV_CON_REF == shipperCountry?.CON_ID);
    const consigneeStatesProvinces = this.statesProvincesList.find(s => s.PRV_Code == this.order.Destination.StateCode && s.PRV_CON_REF == consigneeCountry?.CON_ID);
    const iorStatesProvinces = this.statesProvincesList.find(s => s.PRV_Code == this.order.ImporterOfRecord?.StateCode && s.PRV_CON_REF == iorCountry?.CON_ID);
    
    var dateShipment;
    var today = new Date(new Date().getTime());

    if(typeof this.order.ShipmentDate != 'undefined') {
      dateShipment = new Date(this.order.ShipmentDate) < today ? today : [this.order.ShipmentDate];
    }else {
      dateShipment = today;
    }
    
    this.shipmentFormGroup = this.formBuilder.group({
      shipperForm: this.formBuilder.group({
        companyName: [this.order.Origin.AddressContacts[0].CompanyName, [this.isRequired(!this.isQuickQuote)]],
        personName: [this.order.Origin.AddressContacts[0].PersonName, [this.isRequired(!this.isQuickQuote)]],
        phone: [this.order.Origin.AddressContacts[0].Phone, [this.isRequired(!this.isQuickQuote)]],
        extension: [this.order.Origin.AddressContacts[0].Extension],
        email: [this.order.Origin.AddressContacts[0].Email, [this.isRequired(!this.isQuickQuote)]],
        countryID: [shipperCountry?.CON_ID, Validators.required],
        countryName: [this.order.Origin.CountryName],
        zipCode: [this.order.Origin.ZipCode, Validators.required],
        stateID: [shipperStatesProvinces?.PRV_ID, [this.isRequired(shipperStatesProvinces !== undefined && !this.isQuickQuote), Validators.maxLength(2)]],
        stateName: [this.order.Origin.StateName],
        cityName: [this.order.Origin.CityName, [this.isRequired(!this.isQuickQuote)]],
        unit: [this.order.Origin.Unit],
        streetName: [this.order.Origin.StreetName, [this.isRequired(!this.isQuickQuote)]],
        specialInstruction: [this.order.Origin.SpecialInstructions, []],
        isResidential: [this.order.Origin.IsResidential, []],
        taxID: ""
      }),
      consigneeForm: this.formBuilder.group({
        companyName: [this.order.Destination.AddressContacts[0].CompanyName, [this.isRequired(!this.isQuickQuote)]],
        personName: [this.order.Destination.AddressContacts[0].PersonName, [this.isRequired(!this.isQuickQuote)]],
        phone: [this.order.Destination.AddressContacts[0].Phone, [this.isRequired(!this.isQuickQuote)]],
        email: [this.order.Destination.AddressContacts[0].Email, [this.isRequired(!this.isQuickQuote)]],
        extension: [this.order.Destination.AddressContacts[0].Extension],
        countryID: [consigneeCountry?.CON_ID, Validators.required],
        countryName: [this.order.Destination.CountryName],
        zipCode: [this.order.Destination.ZipCode, Validators.required],
        stateID: [consigneeStatesProvinces?.PRV_ID, [this.isRequired(consigneeStatesProvinces !== undefined && !this.isQuickQuote), Validators.maxLength(2)]],
        stateName: [this.order.Destination.StateName],
        cityName: [this.order.Destination.CityName, [this.isRequired(!this.isQuickQuote)]],
        unit: [this.order.Destination.Unit],
        streetName: [this.order.Destination.StreetName, [this.isRequired(!this.isQuickQuote)]],
        specialInstruction: [this.order.Destination.SpecialInstructions, []],
        isResidential: [this.order.Destination.IsResidential, []],
        taxID: ""
      }),
      iorForm: this.formBuilder.group({
        companyName: [this.order.ImporterOfRecord?.AddressContacts[0]?.CompanyName],
        personName: [this.order.ImporterOfRecord?.AddressContacts[0]?.PersonName],
        phone: [this.order.ImporterOfRecord?.AddressContacts[0]?.Phone],
        email: [this.order.ImporterOfRecord?.AddressContacts[0]?.Email],
        extension: [this.order.ImporterOfRecord?.AddressContacts[0]?.Extension],
        countryID: [iorCountry?.CON_ID],
        countryName: [this.order.ImporterOfRecord?.CountryName],
        zipCode: [this.order.ImporterOfRecord?.ZipCode],
        stateID: [iorStatesProvinces?.PRV_ID],
        stateName: [this.order.ImporterOfRecord?.StateName],
        cityName: [this.order.ImporterOfRecord?.CityName],
        unit: [this.order.ImporterOfRecord?.Unit],
        streetName: [this.order.ImporterOfRecord?.StreetName],
        specialInstruction: [this.order.ImporterOfRecord?.SpecialInstructions, []],
        isResidential: [this.order.ImporterOfRecord?.IsResidential, []],
        taxID: [this.order.ImporterOfRecord?.TaxID, []]
      }),      
      optionForm: this.formBuilder.group({
        IsDocumentOnly: [this.order.IsDocumentOnly],
        PaidByShipper: [this.order.PaidByShipper],
        IsSignatureRequired: [this.order.IsSignatureRequired],
        IsSaturdayDelivery: [this.order.IsSaturdayDelivery],
        IsDryIce: [this.order.IsDryIce],
        IsSpecialHandling: [this.order.IsSpecialHandling],
        ShipmentDate: dateShipment,
        IsInsurance: [this.order.IsInsurance],
        InsuranceValue: [this.order.InsuranceValue],
        InsuranceCurrency: [this.order.InsuranceCurrency],
        Instructions: [this.order.Instructions],
        Reference: [this.order.Reference, this.forceReference ? [this.isRequired(!this.isQuickQuote)] : null],
        UnitOfMeasure: [this.order.UnitOfMeasure !== undefined ? this.order.UnitOfMeasure : UnitOfMeasure.Metric],
        GlobalCurrency: [this.order.InsuranceCurrency],
        ContentDescription: [this.order.ContentDescription],
        VehicleTypes: [this.order.VehicleID]
      }),
      packageForm: this.getCurrentPackages(),
      dutiableForm: this.formBuilder.group({
        Broker: [this.order.Dutiable.Broker],
        TaxID: [this.order.Dutiable.TaxID],
        IORTaxID: [this.order.Dutiable.IORTaxID],
        DutiesTaxesPaidBy: [this.order.Dutiable.DutiesAndTaxesPaidBy],
        Currency: [this.order.Dutiable.Currency === TypeOfCurrency.NONE ? TypeOfCurrency.CAD : this.order.Dutiable.Currency],
        ExportReason: [this.order.Dutiable.ExportReason],
        ExportReasonDetail: [this.order.Dutiable.ExportReasonDetail],
        OriginCountryCode: [this.order.Dutiable.OriginCountryCode],
        SuplementaryDocumentation: [this.order.Dutiable.SuplementaryDocumentation],
        PaperLess: [this.order.PaperLess],
        InvoiceType: [this.order.InvoiceType],
        InvoiceNumber: [this.order.Dutiable.InvoiceNumber],
        InvoiceDate: [this.order.Dutiable.InvoiceDate]
      }),
      productForm: this.getCurrentProducts(),
      documentForm: this.getCurrentDocuments()
    });
  }

  getCurrentPackages(): FormArray {
    var formArray: FormArray = this.formBuilder.array([]);

    this.order.Packages.forEach((item, index) => {
      formArray.push(this.formBuilder.group({
        customPackage: [null],
        packageType: item.PackageType,
        quantity: [item.Quantity, [this.isRequired(!this.isQuickQuote)]],
        depth: [item.Depth, [this.isRequired(!this.isQuickQuote), Validators.min(0.5)]],
        width: [item.Width, [this.isRequired(!this.isQuickQuote), Validators.min(0.5)]],
        height: [item.Height, [this.isRequired(!this.isQuickQuote), Validators.min(0.5)]],
        weight: [item.Weight, [this.isRequired(!this.isQuickQuote), Validators.min(0.5)]],
        dryIceWeight: [item.DryIceWeight, [this.dryIceValidator.bind(this)]],
      }));
    });

    return formArray;
  }
  
  dryIceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.dryIce?.checked ? Validators.required(control) : null;
    };
  }

  isRequired(isRequired: boolean) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isRequired && !control.value) {
        return { required: true };
      }
      return null;
    };
  }
  

  getCurrentProducts(): FormArray {
    var formArray: FormArray = this.formBuilder.array([]);

    this.order.Dutiable.ShipmentDutiableRows.forEach((item, index) => {
      formArray.push(this.formBuilder.group({
        name: item.ItemName,
        description: [item.ItemDescription, [this.isRequired(this.productFormVisible && !this.isQuickQuote)]],
        hsCode: item.HSCode,
        countryOfOrigin: [item.CountryOfOrigin, [this.isRequired(this.productFormVisible && !this.isQuickQuote)]],
        unitPrice: [item.DeclaredUnitValue, [this.isRequired(this.productFormVisible && !this.isQuickQuote)]],
        qty: [item.Quantity, [this.isRequired(this.productFormVisible && !this.isQuickQuote)]]
        ,weight: [item.Weight, [this.isRequired(this.productFormVisible && !this.isQuickQuote)]]
      }));
    });
    
    return formArray;
  }

  getCurrentDocuments(): FormArray {
    var formArray: FormArray = this.formBuilder.array([]);

    if(this.order.Documents) {
      if(this.order.Documents.length > 0) {
        this.order.Documents.forEach((item, index) => {
          formArray.push(this.formBuilder.group({
            documentType: item.DocumentType,
            fileName: item.FileName,
            content: item.Content
          }));
        });
      }
    }
    
    return formArray;
  }

  /** Save the current state of the order
   */
  saveOrder(): void {
    this.isProcessing = true;
    this.prepareQuoteRequest();
    this.createNewShipmentService.saveOrder(this.order).subscribe(result => {

      if (result !== null && result !== undefined) {
        this.order.OrderId = result; // In case it was created and then modified again immediatelly
      }
      this.isProcessing = false;
    })
  }
  /** Get the parent options form group */
  options(): FormGroup {
    return this.shipmentFormGroup.get("optionForm") as FormGroup;
  }

  // By Marc Roussel on 2022-4-23 to show a popup if the insurance is higher than the total declaration
  isInsuranceAndDeclarationAmountsCorrect(): boolean {
    var IsCorrect = true;
    var TotalDeclared = 0;
    var IsInsurance = this.options().controls['IsInsurance'].value
    var IsDeclarationAvailable = (this.order.Destination.CountryName !== this.order.Origin.CountryName || this.order.Destination.CountryCode !== this.order.Origin.CountryCode) && this.order.IsDocumentOnly === false;
    
    var TotalInsurance = this.options().controls['InsuranceValue'].value;
    const productForm = this.shipmentFormGroup.value.productForm;
    
    if(productForm) {
      productForm.map((item: { qty: number; unitPrice: number; }) => {
        TotalDeclared += item.qty * item.unitPrice;
      });
    }

    // Larry: added !IsInsurance car si nos avons la section douanièere mais pas d'assurance ca nous donnait le message quand meme
    IsCorrect = (IsDeclarationAvailable && IsInsurance && TotalInsurance <= TotalDeclared) || !IsDeclarationAvailable || !IsInsurance;

    return IsCorrect;
  }
  
  // The total products weight must not be higher than the total packages weight
  isDutiableWeightHigherThanPackagesWeight() : boolean {
    var IsHigher = false;
    var TotalProductsWeight = 0;
    var TotalPackagesWeight = 0;
    const productForm = this.shipmentFormGroup.value.productForm;
    const packagesForm = this.shipmentFormGroup.value.packageForm;
    
    if(productForm) {
      productForm.map((item: { qty: number, weight: number }) => {
        TotalProductsWeight += item.qty * item.weight;
      });
    }
    
    if(packagesForm) {
      packagesForm.map((item: { quantity: number, weight: number }) => {
        TotalPackagesWeight += item.quantity * item.weight;
      });
    }

    IsHigher = TotalProductsWeight > TotalPackagesWeight;

    return IsHigher;
  }
  
  private getName(control: AbstractControl): string | null {
    let group = <FormGroup>control.parent;

    if (!group) {
      return null;
    }

    let name: string;

    Object.keys(group.controls).forEach(key => {
      let childControl = group.get(key);

      if (childControl !== control) {
        return;
      }

      name = key;
    });

    return name;
  }

  public findInvalidControlsRecursive(formToInvestigate:FormGroup|FormArray):string[] {
    var invalidControls:string[] = [];
    let recursiveFunc = (form:FormGroup|FormArray) => {
      Object.keys(form.controls).forEach(field => { 
        const control = form.get(field);
        
        if (control.invalid)
          invalidControls.push(this.getName(control) + " : " + control.value);

        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        }        
      });
    }

    recursiveFunc(formToInvestigate);
    return invalidControls;
  }

  /** Get all quotes when the get quotes button is press in step 1
   * ref: https://stackoverflow.com/questions/46469233/can-i-programmatically-move-the-steps-of-a-mat-horizontal-stepper-in-angular-a
   */
  getQuotes(): void {

    //var invalidControls = this.findInvalidControlsRecursive(this.shipmentFormGroup)
    const dutiableForm = this.shipmentFormGroup.value.dutiableForm;
    
    if((dutiableForm.InvoiceNumber == null || dutiableForm.InvoiceNumber == undefined || dutiableForm.InvoiceNumber == '')  && !this.shipmentFormGroup.controls["productForm"].enabled)
      this.shipmentFormGroup.controls["dutiableForm"].get("InvoiceNumber").setValue("1");

    if (this.shipmentFormGroup.valid) {

      this.prepareQuoteRequest();
      this.quotes = [];

      if ((this.order.Destination.CountryName !== this.order.Origin.CountryName ||
        this.order.Destination.CountryCode !== this.order.Origin.CountryCode) &&
        this.order.IsDocumentOnly === false &&
        this.order.Dutiable.ShipmentDutiableRows.length === 0) {
        Swal.fire({
          icon: "warning",
          title: this.translateService.instant("app.error.errorInvalidProductNumber"),
          confirmButtonText: 'OK',
        })

        this.isProcessing = false;
        this.myStepper.previous();
      }
      else {

        if(!this.isInsuranceAndDeclarationAmountsCorrect()) {
          this.isProcessing = false;

          Swal.fire({
            icon: "error",
            title: this.translateService.instant("app.error.errorInsuranceToHeight"),
            confirmButtonText: 'OK',
            didClose: () => {
              this.Step1ShipmentForm.insElement.nativeElement.focus();
              this.Step1ShipmentForm.insElement.nativeElement.select();
            }
          });
        }
        else if(this.isDutiableWeightHigherThanPackagesWeight() && !this.order.IsDocumentOnly && this.order.Origin.CountryCode !== this.order.Destination.CountryCode) {
          this.isProcessing = false;
          this.storageService.setGoToStepTwo("false");
          this.stepOneCompleted = false;
          this.stepTwoCompleted = false;
          this.myStepper.previous();

          Swal.fire({
            icon: "error",
            title: this.translateService.instant("app.error.errorDeclarationWeightToHigh"),
            confirmButtonText: 'OK',
            didClose: () => {
              this.Step1ShipmentForm.insElement.nativeElement.focus();
              this.Step1ShipmentForm.insElement.nativeElement.select();
            }
          });
        }
        else {
          this.isProcessing = true;
          this.createNewShipmentService.getQuotes(this.order, this.isQuickQuote).subscribe(result => {
            if (result !== null) {
              this.quotes = result.Quotes;
              var validation = this.validationService.validate(result);
              this.quotesMessage = validation.message.split("<br>").join("\n");
              this.quotes = this.quotes.sort((a, b) => a.Price.TotalAmount - b.Price.TotalAmount);
              this.isProcessing = false;
              this.storageService.setGoToStepTwo("false");
              this.stepOneCompleted = true;
              this.stepTwoCompleted = false;

              if(this.isGuest)
                sessionStorage.setItem("guestQuickQuote", JSON.stringify(this.order)); // QuoteDetail

              this.myStepper.next();
            }
            else {
              this.isProcessing = false;
              this.storageService.setGoToStepTwo("false");
              this.myStepper.previous();
            }
          })
        }
      }
    }
    else {
      // Display an error message to the user
      Swal.fire({
        icon: "warning",
        title: this.translateService.instant("app.error.errorInvalidForm"),
        confirmButtonText: 'OK'
      })

      this.isProcessing = false;
      this.storageService.setGoToStepTwo("false");
      this.myStepper.previous();
    }
  }

  /** Set quotes request values with the customer entries
   */
  private prepareQuoteRequest(): void {
    const shipperForm = this.shipmentFormGroup.value.shipperForm;
    const consigneeForm = this.shipmentFormGroup.value.consigneeForm;
    const iorForm = this.shipmentFormGroup.value.iorForm;
    const packageForm = this.shipmentFormGroup.getRawValue().packageForm;
    const productForm = this.shipmentFormGroup.value.productForm;
    const dutiableForm = this.shipmentFormGroup.value.dutiableForm;
    const optionForm = this.shipmentFormGroup.value.optionForm;
    const documentForm = this.shipmentFormGroup.value.documentForm;

    const packages: ShipmentPackageModel[] = packageForm.map(item => {
      const pack: ShipmentPackageModel = {
        PackageType: item.packageType,
        Description: "",
        Quantity: item.quantity,
        Depth: item.depth,
        Width: item.width,
        Height: item.height,
        Weight: item.weight,
        DryIceWeight: item.dryIceWeight
      };
      return pack;
    });

    //---------------------------------------------------------------------------------------------
    // The backend and carriers use the ISO country code 2 letters while the UI uses the country id
    // Here we get the Code and Name from the Countries and Provinces backend
    //---------------------------------------------------------------------------------------------
    const shipperCountries = this.countriesList.find(s => s.CON_ID == shipperForm.countryID);
    const consigneeCountries = this.countriesList.find(s => s.CON_ID == consigneeForm.countryID);
    const iorCountries = this.countriesList.find(s => s.CON_ID == iorForm.countryID);
    const shipperStatesProvinces = this.statesProvincesList.find(s => s.PRV_ID == shipperForm.stateID);
    const consigneeStatesProvinces = this.statesProvincesList.find(s => s.PRV_ID == consigneeForm.stateID);
    const iorStatesProvinces = this.statesProvincesList.find(s => s.PRV_ID == iorForm.stateID);

    var originName = "";
    var destinationName = "";
    var iorName = "";

    if(typeof shipperForm.companyName === "number")
      originName = this.addressesList.find(a => a.Row == shipperForm.companyName).CompanyName;
    else
      originName = shipperForm.companyName;

    //-------------------------------------------------------------------------------------------------------------
    // Fix: Y'avait shipperForm ici au lieu de consigneeForm ce qui faisait
    //      qu'on envoyait le code de la company autocomplete parce que la condition n'était pas sur la bonne forme
    //      ce qui faisait qu'on avait pas de quotes
    //-------------------------------------------------------------------------------------------------------------
    if(typeof consigneeForm.companyName === "number")
      destinationName = this.addressesList.find(a => a.Row == consigneeForm.companyName).CompanyName;
    else
      destinationName = consigneeForm.companyName;

    if(typeof iorForm.companyName === "number")
      iorName = this.addressesList.find(a => a.Row == iorForm.companyName).CompanyName;
    else
      iorName = iorForm.companyName;

    const origin: ShipmentAddressModel = {
      StreetName: shipperForm.streetName,
      CountryCode: shipperCountries?.CON_Code,
      CountryName: shipperCountries?.CON_Name,
      StateCode: shipperStatesProvinces?.PRV_Code,
      StateName: shipperStatesProvinces?.PRV_Name,
      CityName: shipperForm.cityName,
      ZipCode: shipperForm.zipCode,
      Valid: null,
      Corrected: null,
      IsEnglish: this.translateService.currentLang === "en",
      IsResidential: shipperForm.isResidential === true,
      Name: originName,
      Unit: shipperForm.unit,
      SpecialInstructions: shipperForm.specialInstruction,
      TaxID: shipperForm.taxID,
      AddressContacts: [{
        PersonName: shipperForm.personName,
        CompanyName: originName,
        Email: shipperForm.email,
        EmailNotification: false,
        Phone: shipperForm.phone,
        Extension: shipperForm.extension,
        Fax: null,
        Pager: null
      }]
    };

    const destination: ShipmentAddressModel = {
      StreetName: consigneeForm.streetName,
      CountryCode: consigneeCountries ? consigneeCountries.CON_Code : "",
      CountryName: consigneeCountries ? consigneeCountries.CON_Name : "",
      StateCode: consigneeStatesProvinces ? consigneeStatesProvinces.PRV_Code : "",
      StateName: consigneeStatesProvinces ? consigneeStatesProvinces.PRV_Name : "",
      CityName: consigneeForm.cityName,
      ZipCode: consigneeForm.zipCode,
      Valid: null,
      Corrected: null,
      IsEnglish: this.translateService.currentLang === "en",
      IsResidential: consigneeForm.isResidential === true,
      Name: destinationName,
      Unit: consigneeForm.unit,
      SpecialInstructions: consigneeForm.specialInstruction,
      TaxID: consigneeForm.TaxID,
      AddressContacts: [{
        PersonName: consigneeForm.personName,
        CompanyName: destinationName,
        Email: consigneeForm.email,
        EmailNotification: false,
        Phone: consigneeForm.phone,
        Extension: consigneeForm.extension,
        Fax: null,
        Pager: null
      }]
    };

    const importOfRecord: ShipmentAddressModel = {
      StreetName: iorForm.streetName,
      CountryCode: iorCountries ? iorCountries.CON_Code : "",
      CountryName: iorCountries ? iorCountries.CON_Name : "",
      StateCode: iorStatesProvinces ? iorStatesProvinces.PRV_Code : "",
      StateName: iorStatesProvinces ? iorStatesProvinces.PRV_Name : "",
      CityName: iorForm.cityName ?? "",
      ZipCode: iorForm.zipCode ?? "",
      Valid: true,
      Corrected: false,
      IsEnglish: this.translateService.currentLang === "en",
      IsResidential: iorForm.isResidential === true,
      Name: iorName ?? "",
      Unit: iorForm.unit ?? "",
      SpecialInstructions: iorForm.specialInstruction ?? "",
      TaxID: iorForm.taxID,
      AddressContacts: [{
        PersonName: iorForm.personName ?? "",
        CompanyName: iorName ?? "",
        Email: iorForm.email ?? "",
        EmailNotification: false,
        Phone: iorForm.phone ?? "",
        Extension: iorForm.extension ?? "",
        Fax: "",
        Pager: ""
      }]
    };

    const products = productForm ? productForm.map(item => {
      const row: ShipmentDutiableRow = {
        ItemName: item.description,
        ItemDescription: item.description,
        Quantity: item.qty,
        CountryOfOrigin: item.countryOfOrigin,
        HSCode: item.hsCode,
        DeclaredUnitValue: item.unitPrice,
        DeclaredTotalValueAsString: '',
        DeclaredTotalValueAsDecimal: null,
        Weight: item.weight
      };
      return row;
    }) : [];

    const dutiable = {
      Broker: dutiableForm.Broker,
      TaxID: dutiableForm.TaxID,
      IORTaxID: dutiableForm.IORTaxID,
      Currency: dutiableForm.Currency,
      DutiesAndTaxesPaidBy: dutiableForm.DutiesTaxesPaidBy,
      ExportReason: dutiableForm.ExportReason,
      ExportReasonDetail: dutiableForm.ExportReasonDetail,
      SuplementaryDocumentation: dutiableForm.SuplementaryDocumentation,
      OriginCountryCode: shipperForm.countryName,
      TotalDeclaredValue: 0,
      ShipmentDutiableRows: products,
      InvoiceNumber: dutiableForm.InvoiceNumber,
      InvoiceDate: dutiableForm.InvoiceDate,
      DutiesTaxesPaidBy: dutiableForm.DutiesTaxesPaidBy
    };

    const documents = documentForm ? documentForm.map(item => {
      const row: PLTDocument = {
        DocumentType: item.documentType,
        FileName: item.fileName,
        Content: item.content,
      };
      return row;
    }) : [];
    
    this.order = {
      Language: this.languageService.GetCurrentLanguage(),
      IsDevelopmentEnvironment: false,
      OrderId: this.order.OrderId,
      Carriers: [Carriers.DHL, Carriers.FEDEX, Carriers.PUROLATOR],
      IsDocumentOnly: optionForm.IsDocumentOnly,
      ShipmentDate: optionForm.ShipmentDate,
      PaidByShipper: optionForm.PaidByShipper,
      IsSignatureRequired: optionForm.IsSignatureRequired,
      IsSaturdayDelivery: optionForm.IsSaturdayDelivery,
      IsDryIce: optionForm.IsDryIce,
      IsSpecialHandling: optionForm.IsSpecialHandling,
      IsInsurance: optionForm.IsInsurance,
      InsuranceValue: optionForm.InsuranceValue,
      InsuranceCurrency: optionForm.InsuranceCurrency,
      CurrencyType: TypeOfCurrency.CAD,
      ContentDescription: optionForm.ContentDescription,
      Origin: origin,
      Destination: destination,
      ImporterOfRecord: importOfRecord,
      Packages: packages,
      Dutiable: dutiable,
      ValidateAddress: true,
      ThirdPartyId: this.order.ThirdPartyId,
      ThirdPartyOrderNumber: this.order.ThirdPartyOrderNumber,
      IsFromShoppingCart: false,
      Instructions: optionForm.Instructions,
      Reference: optionForm.Reference,
      UnitOfMeasure: optionForm.UnitOfMeasure,
      PaperLess: dutiableForm.PaperLess,
      InvoiceType: dutiableForm.InvoiceType,
      Documents: documents,
      VehicleID: optionForm.VehicleTypes
    }
  }

  /** Stop processing and return to STEP 1 */
  returnToStepOne(event: any) {
    this.isProcessing = false;
    this.storageService.setGoToStepTwo("false");
    this.myStepper.previous();
  }

  /** Call when a quote is selected in step 2 */
  selectQuote(event: Quote): void {
    if(this.isCreditCard) {
      var PaymentTxnId = "";

      Swal.fire({
        title: '',
        html: '<span style="white-space: nowrap;">' + this.translateService.instant("app.misc.confirmCreateShipment") + '</span>',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.translateService.instant("app.misc.yes"),
        cancelButtonText: this.translateService.instant("app.misc.no")
      }).then((result) => {
        if (result.isConfirmed) {
          this.storageService.setGoToStepTwo("false");
          this.isProcessing = true;
          
          //---------------------------------------------------------------------
          // Request payment if the payment method of this division is CreditCard
          //---------------------------------------------------------------------
          this.paymentCardService.executePayment(event.Price.TotalAmount, this.getCurrentLanguage()).subscribe((payWithCreditCardResult: PayWithCreditCardResult) => {
            if (payWithCreditCardResult.PaymentSuccess) {
              Swal.fire({
                icon: "info",
                html: '<div class="swal-title">' + payWithCreditCardResult.PaymentMessage + '</div>',
                confirmButtonText: 'OK',
                customClass: {
                  popup: 'swal-text-pre-line'
                }
              }).then((result) => {
                this.continueWithShipment(event, payWithCreditCardResult.PaymentTxnId);
              });
            }
            else {
              this.isProcessing = false;
              Swal.fire({
                icon: "info",
                title: payWithCreditCardResult.PaymentMessage,
                confirmButtonText: 'OK',
              });
            }
          });
        }
      });    
    }
    else {
      this.isProcessing = true;
      this.continueWithShipment(event, "");
    }
  }

  continueWithShipment(event: Quote, PaymentTxnId: string): void {
    const createShipmentDetail: CreateShipmentDetail = {
      Language: this.languageService.GetCurrentLanguage(),
      IsDevelopmentEnvironment: false,
      QuoteId: event.QuoteId,
      OrderIdToDelete: this.order.OrderId,
      DocumentReturnType: DocumentReturnType.PDF_LETTER,
      Instructions: this.order.Instructions,
      Reference: this.order.Reference,
      ThirdPartyId: this.order.ThirdPartyId,
      ThirdPartyOrderNumber: this.order.ThirdPartyOrderNumber,
      IsSignatureRequired: this.order.IsSignatureRequired,
      IsSignatureUnavailable: event.IsSignatureUnavailable,
      CustomField: PaymentTxnId,
      CustomFieldDescription: "Payment"
    };

    this.createNewShipmentService.createShipment(createShipmentDetail).subscribe((response) => {
      if (response !== null) {
        //------------------------------------------------------------------------
        // Marc - 2021-10-27
        // This condition checks if an order was created in Cubi table 
        // which is the result of a successful shipment placed at the carrier side
        //
        // We can't continue to the next step if an order wasn't created since
        // the next step is to show the order summary and there's no order
        //------------------------------------------------------------------------
        var ORD_ID = response.ShipmentResults[0].Shp_Id;

        if(ORD_ID) {
          this.shpId = response.ShipmentResults[0].Shp_Id;

          if(this.isCreditCard) {
            this.isProcessing = true;

            this.createPickup(ORD_ID.toString()).subscribe(result => {
              this.displayPrint = true;
              this.stepTwoCompleted = true;
              this.myStepper.selected.completed = true;
              this.myStepper.next();

              //------------------------------------------------------------------------------------------------------------------------------
              // For Globex, the waybill is generated when a pickup is created otherwise the waybill is generated when the shipment is created
              //------------------------------------------------------------------------------------------------------------------------------
              if(result.PickupResults[0].ServiceWaybill)
                this.actionService.seeWaybill(result.PickupResults[0].ServiceWaybill);
              else
                this.actionService.seeWaybill(response.ShipmentResults[0].ServiceWaybill);

              this.isProcessing = false;

            });
          } else {
            this.displayPrint = true;
            this.stepTwoCompleted = true;
            this.myStepper.selected.completed = true;
            this.myStepper.next();
            this.isProcessing = false;
          }
        }
        else {
          //------------------------------------------------
          // Void payment because the shipment was a failure
          //------------------------------------------------
          if(PaymentTxnId) {
            this.paymentCardService.voidCreditCardTransaction(PaymentTxnId).subscribe((voidCreditCardTransactionResult: VoidCreditCardTransactionResult) => {
              this.isProcessing = false;
            });
          }
          else
            this.isProcessing = false;
        }
      }

      this.myStepper.selected.completed = false;
      //this.isProcessing = false;
    });
  }
  createPickup(ShipmentId: string): Observable<any> {
    const resultSubject = new Subject<any>();
  
    /** Set the dialog box configuration */
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
  
    /** Open dialog to enter the pickup details */
    this.dialog.open(CreatePickupDialogComponent, dialogConfig).afterClosed().pipe(
      switchMap(dialogResult => {
        if (dialogResult !== false) {
          let pickup: CreatePickupsDetail = {
            ShipmentIds: [ShipmentId],
            PickupDate: this.datepipe.transform(dialogResult.pickupDate, 'yyyy-MM-dd'),
            ReadyByTime: dialogResult.readyByTime,
            BusinessHoursFrom: dialogResult.businessHoursFrom,
            BusinessHoursTo: dialogResult.businessHoursTo,
            Instructions: dialogResult.instructions,
            CustomerReference: dialogResult.customerReference,
            IsDevelopmentEnvironment: false,
            Language: this.getCurrentLanguage(),
            IsNoMessage: true
          };
  
          /** Take the only pickup in the list to update UI information */
          return this.shipmentService.createPickups(pickup).pipe(
            tap(pickupResponses => {
              this.isProcessing = false;
              resultSubject.next(pickupResponses);
              resultSubject.complete();
            }),
            catchError(error => {
              this.isProcessing = false;
              resultSubject.error(error);
              return throwError(error);
            })
          );
        } else {
          this.isProcessing = false;
          resultSubject.next(false);
          resultSubject.complete();
          return EMPTY;
        }
      })
    ).subscribe();
  
    return resultSubject.asObservable();
  }
  

  getCurrentLanguage(): Language {
    if (this.translateService.currentLang === "fr") {
      return this.currentLanguage = Language.French;
    }
    else {
      return this.currentLanguage = Language.English;
    }
  }

}
