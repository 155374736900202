<!-- Note - The mat-table is in a table because we need to span the expanded details row -->
<!-- Shopify orders page -->
<button mat-flat-button color="primary" style="margin-bottom:8px;width:212px" (click)="showEditProductsComponent()">
  {{ 'app.shopifyProducts.showProducts' | translate }}
  <mat-icon style="margin-left: 5px">edit</mat-icon>
</button>
<div class="shipment-container">

  <!-- Seach bar -->
  <div class="shipment-container__header">
    <div>
      <mat-icon style="color:rgb(95, 95, 95); margin:0 5px 0 15px" class="icon material-icons">
        search
      </mat-icon>
    </div>
    <div class="shipment-container__header__filter">
      <mat-form-field>
        <input matInput type="text" [(ngModel)]="searchInput" (keyup)="applySearch($event.target.value)"
          placeholder="{{ 'app.misc.search' | translate }}" />
        <button mat-button *ngIf="searchInput" matSuffix mat-icon-button aria-label="Clear" (click)="applySearch('')">
          <mat-icon>
            close
          </mat-icon>
        </button>
      </mat-form-field>
    </div>
    <a href="https://www.shopify.com/" target="_blank">
      <img style="width: auto; height: 40px; margin: 5px; margin-right: 45px;"
        src="../../../../../assets/images/shopify_logo_black.png" alt="Shopify logo" />
    </a>
  </div>

  <!-- Shopify orders data table -->
  <div class="my-table mat-elevation-z8 shipment-container__table">
    <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>

      <!-- Order number column -->
      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "app.shopifyOrders.orderNumber" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon class="icon material-icons" color="primary" style="margin-top: 5px;">
            expand_more
          </mat-icon>
          {{ element.orderNumber }}
        </td>
      </ng-container>

      <!-- Destination column -->
      <ng-container matColumnDef="destinationName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "app.shopifyOrders.destination" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div>
            {{ element.destinationName }} <br />
            {{element.destinationStreetName}} <br />
            {{ element.destinationCityName }},
            {{ element.destinationStateCode }},
            {{ element.destinationCountryCode }}
            <br />
          </div>
        </td>
      </ng-container>

      <!-- User shipment selection column -->
      <ng-container matColumnDef="trackingNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "app.shopifyOrders.yourShipmentDetail" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.shipmentCarrier !== undefined">
            <img *ngIf="element.shipmentCarrier.toUpperCase() === carrier.FedEx.toUpperCase()"
              src="../../../../../assets/images/fedex.png" class="carrier-image" alt="FedEx" />
            <img *ngIf="element.shipmentCarrier.toUpperCase() === carrier.DHL.toUpperCase()"
              src="../../../../../assets/images/dhl.png" class="carrier-image" alt="DHL" />
            <img *ngIf="element.shipmentCarrier.toUpperCase() === carrier.Purolator.toUpperCase()"
              src="../../../../../assets/images/purolator.png" class="carrier-image" alt="Purolator" />
            <img *ngIf="element.shipmentCarrier.toUpperCase() === carrier.Globex.toUpperCase()"
              src="../../../../../assets/images/globex-logo.png" class="carrier-image" alt="Globex" />
            <img *ngIf="element.shipmentCarrier.toUpperCase() === carrier.Loomis.toUpperCase()"
              src="../../../../../assets/images/loomis-logo.png" class="carrier-image" alt="Loomis" />
            </div>
          {{ element.trackingNumber }} <br>
          {{ element.shipmentService }} <br>
          {{ element.shipmentPrice }} <br>
          <span *ngIf="element.createdBy">{{ "app.shopifyOrders.createdBy" | translate }}</span>
          {{ element.createdBy }}
        </td>
      </ng-container>

      <!-- Shopify shipment column -->
      <ng-container matColumnDef="shippingService">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "app.shopifyOrders.shopifyClientShipmentDetail" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.shippingCarrier }} <br>
          {{ element.shippingServiceName }} <br>
          {{ element.shippingPrice }}
        </td>
      </ng-container>

      <!-- Action buttons column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="max-width: 80px;">
          {{ "app.shopifyOrders.createShipmentBtn" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" style="max-width: 80px;">
          <button mat-mini-fab [disabled]="element.OriginalModel.CSDADetail" color="primary" type="button"
            (click)="navigateToConstructShipment(element)">
            <mat-icon>border_color</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Template for order details row -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="orders-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

            <div>
              <mat-list>
                <mat-list-item *ngFor="let product of element.OriginalModel.Packages; let last = last">
                  <mat-divider *ngIf="!last"></mat-divider>
                  <div mat-line class="table-details">
                    <span class="orders-detail">{{ product.Quantity }}</span> &nbsp; &nbsp;
                    <span class="orders-detail">{{ product.ShopifyDetail.Name }} </span> &nbsp; &nbsp;
                    <span class="orders-detail"> H {{ product.Height }} cm x L {{ product.Length }} cm x
                      {{ product.Width }} cm</span> &nbsp; &nbsp;
                    <span class="orders-detail"> {{ product.Weight }} kgs</span>
                  </div>
                </mat-list-item>
              </mat-list>
            </div>

          </div>
        </td>
      </ng-container>

      <!-- Table header definition -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

      <!-- Table row definition -->
      <tr mat-row *matRowDef="let element; columns: displayedColumns" class="orders-element-row"
        [class.orders-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
      </tr>

      <!-- Extra row to show detail content column -->
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="orders-detail-row">
      </tr>
    </table>

    <!-- Table paginator -->
    <mat-paginator [pageSizeOptions]="[10, 25, 50 , 100]" showFirstLastButtons="true">
    </mat-paginator>

  </div>
</div>

<!-- The overlay that display while the app is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>
