import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env";
import { map } from "rxjs/internal/operators";
import { Observable } from "rxjs/Observable";
import { GetShopifyOrdersResult } from "@app/shared/CSDAModels/GetShopifyOrdersResult";
import { ShopifyOrdersTableModel } from "../models/shopify-orders-table-model";
import { LanguageService } from "@app/core/services/language.service";
import { QuoteDetail } from "@app/shared/CSDAModels/QuoteDetail";
import Swal from "sweetalert2";
import { ValidationService } from "@app/core/services/validation.service";

@Injectable({
	providedIn: "root"
})
export class ShopifyOrdersService {

	Order: QuoteDetail;
	constructor(
		private httpClient: HttpClient, 
		private languageService: LanguageService,
		private validationService : ValidationService
	) { }


	/** Get the shopify orders table model for the data table of the Shopify orders page
	 * @returns An array of shopify orders table model
	 */
	public getShopifyOrders(): Observable<ShopifyOrdersTableModel[]> {

		const currentLanguage = this.languageService.GetCurrentLanguage();

		return this.httpClient.post<GetShopifyOrdersResult>(environment.CSDAEndPoint + "/CSDA/GetShopifyOrders", currentLanguage)
			.pipe(
				map(data => data.ShopifyOrdersResults.map(response => {
					
					var validation = this.validationService.validate(data);

					if (validation.isValid === false) {
						Swal.fire('', validation.message, "error");
						return null;
					}

					if (validation.message !== "") {
						Swal.fire('', validation.message, "info");
					}

					return new ShopifyOrdersTableModel(response);
				}))
			);
	}
}
