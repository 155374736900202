import { Component, Injectable, OnInit } from '@angular/core';
import { NumberValueAccessor } from "@angular/forms";
import { Carriers } from "@app/core/enums/Carriers";
import { Language } from "@app/core/enums/Language";
import { PackageType } from "@app/core/enums/PackageType";
import { ReasonForExport } from "@app/core/enums/ReasonForExport";
import { ThirdPartyType } from "@app/core/enums/ThirdPartyType";
import { TypeOfCurrency } from "@app/core/enums/type-of-currency";
import { Contact } from "@app/shared/CSDAModels/Contact";
import { ShopifyOrdersResult } from "@app/shared/CSDAModels/GetShopifyOrdersResult";
import { QuoteDetail } from "@app/shared/CSDAModels/QuoteDetail";
import { ShipmentAddressModel } from "@app/shared/CSDAModels/ShipmentAddressModel";
import { ShipmentDutiable } from "@app/shared/CSDAModels/ShipmentDutiable";
import { ShipmentDutiableRow } from "@app/shared/CSDAModels/ShipmentDutiableRow";
import { ShipmentPackageModel } from "@app/shared/CSDAModels/ShipmentPackageModel";
import { DOCUMENT_TYPE } from "../enums/DOCUMENT_TYPE";
import { DutiesAndTaxesPaidBy } from "../enums/DutiesAndTaxesPaidBy";
import { INVOICE_TYPE } from "../enums/INVOICE_TYPE";
import { UnitOfMeasure } from "../enums/unit-of-measure";

@Injectable({providedIn: 'root'})
export class ThirdPartyService implements OnInit {
  public isFromThirdParty: boolean = false;
  public thirdPartyId: number;
  public thirdPartyOrderNumber: string;
  public order: QuoteDetail;

  
  constructor() { }
  
  ngOnInit(): void {
  }

  //-------------------------------------------------------------------------------------------------------------
  // Marc:  Ajoutez ici les SET pour chaque third party.  Les thirdPartyId sont listés dans la table ThirdParties
  //        ou utilisez l'action GetThirdPartiesList du CSAPI 
  //
  //        Dans une version 2.0 Je vais modifier le CSDA pour donner  un Order directement à la liste
  //        de chaque third party afin de ne pas avoir à transférer le data d'un modèle à un autre
  //-------------------------------------------------------------------------------------------------------------
  public setOrderFromShopify(row: ShopifyOrdersResult) {
    this.isFromThirdParty = true;
    this.thirdPartyId = ThirdPartyType.Shopify;
    this.thirdPartyOrderNumber = row.ShopifyOrder.OrderNumber.toString();
    this.ThirdPartyModelToCSDAModel(row, true);                 // Refactoring to come
  }
  //-------------------------------------------------------------------------
  // Marc: Changez le any pour le modèle WooCommerce quand il sera disponible
  //-------------------------------------------------------------------------
  public setOrderFromWooCommerce(row: ShopifyOrdersResult) {
    this.isFromThirdParty = true;
    this.thirdPartyId = ThirdPartyType.WooCommerce;
    this.thirdPartyOrderNumber = row.ShopifyOrder.OrderNumber.toString();
    this.ThirdPartyModelToCSDAModel(row, false);                // Refactoring to come
  }

  //----------------------------------------------------------------------------------------------------------
  // Marc:  Éventuellement ne sera plus nécessaire car le API va retourner le modèle correspondant à la grille
  //        du Third party
  //----------------------------------------------------------------------------------------------------------
  private ThirdPartyModelToCSDAModel(row: ShopifyOrdersResult, IsFromShoppingCart: boolean): void {
    var carriers: Carriers[] = [Carriers.DHL, Carriers.FEDEX, Carriers.PUROLATOR];
    var packages: ShipmentPackageModel[] = [];
    var originAddressContacts: Contact[] = [];
    var destinationAddressContacts: Contact[] = [];
    var products: ShipmentDutiableRow[] = [];
    var dutiable: ShipmentDutiable;

    originAddressContacts.push(
      {
        PersonName: row.Origin.AddressContacts[0].PersonName,
        CompanyName: row.Origin.AddressContacts[0].CompanyName,
        EmailNotification: false,
        Email: row.Origin.AddressContacts[0].Email,
        Phone: row.Origin.AddressContacts[0].Phone,
        Extension: row.Origin.AddressContacts[0].Extension,
        Fax: row.Origin.AddressContacts[0].Fax,
        Pager: row.Origin.AddressContacts[0].Pager
      }
    )

    destinationAddressContacts.push(
      {
        PersonName: row.Destination.AddressContacts[0].PersonName,
        CompanyName: row.Destination.AddressContacts[0].CompanyName,
        EmailNotification: false,
        Email: row.Destination.AddressContacts[0].Email,
        Phone: row.Destination.AddressContacts[0].Phone,
        Extension: row.Destination.AddressContacts[0].Extension,
        Fax: row.Destination.AddressContacts[0].Fax,
        Pager: row.Destination.AddressContacts[0].Pager
      }
    )

    const origin: ShipmentAddressModel = {
      StreetName: row.Origin.StreetName,
      CountryCode: row.Origin.CountryCode,
      CountryName: row.Origin.CountryName,
      StateCode: row.Origin.StateCode,
      StateName: row.Origin.StateName,
      CityName: row.Origin.CityName,
      ZipCode: row.Origin.ZipCode,
      Valid: null,
      Corrected: null,
      IsEnglish: true,
      IsResidential: false,
      Name: row.Origin.Name,
      Unit: row.Origin.Unit,
      SpecialInstructions: row.Origin.SpecialInstructions,
      TaxID: row.Origin.TaxID,
      AddressContacts: originAddressContacts
    };

    const destination: ShipmentAddressModel = {
      StreetName: row.Destination.StreetName,
      CountryCode: row.Destination.CountryCode,
      CountryName: row.Destination.CountryName,
      StateCode: row.Destination.StateCode,
      StateName: row.Destination.StateName,
      CityName: row.Destination.CityName,
      ZipCode: row.Destination.ZipCode,
      Valid: null,
      Corrected: null,
      IsEnglish: true,
      IsResidential: false,
      Name: row.Destination.Name,
      Unit: row.Destination.Unit,
      SpecialInstructions: row.Destination.SpecialInstructions,
      TaxID: row.Destination.TaxID,
      AddressContacts: destinationAddressContacts
    };

    row.Packages.forEach(function (item) {
      products.push({ 
        ItemName: item.ShopifyDetail.Name,
        ItemDescription: item.Description,
        Quantity: item.Quantity,
        CountryOfOrigin: item.ShopifyDetail.CountryOfOrigin,
        HSCode: item.ShopifyDetail.HSCode,
        DeclaredUnitValue: item.UnitPrice,
        DeclaredTotalValueAsDecimal: 0,
        DeclaredTotalValueAsString: "",
        Weight: item.Weight
      });
      packages.push({
        PackageType: PackageType.CUSTOM,
        Quantity: item.Quantity,
        Weight: item.Weight,
        Depth: item.Length,
        Height: item.Height,
        Width: item.Width,
        Description: item.Description,
        DryIceWeight: item.DryIceWeight
      });
    });

    dutiable = {
      Broker: "",
      TaxID: "",
      Currency: TypeOfCurrency.CAD,
      DutiesAndTaxesPaidBy: DutiesAndTaxesPaidBy.RECEIVER,
      ExportReason: ReasonForExport.NONE,
      ExportReasonDetail: "",
      SuplementaryDocumentation: false,
      OriginCountryCode: "CA",
      TotalDeclaredValue: 0,
      ShipmentDutiableRows: products,
      InvoiceNumber: "1",
      InvoiceDate: new Date(),
      IORTaxID: ""
    };

    // date of tommorow
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    this.order = {
      Language: Language.English,
      IsDevelopmentEnvironment: false,
      OrderId: null,
      Carriers: carriers,
      IsDocumentOnly: false,
      ShipmentDate: tomorrow,
      PaidByShipper: true,
      IsSignatureRequired: false,
      IsSaturdayDelivery: false,
      IsDryIce: false,
      IsSpecialHandling: false,
      IsInsurance: false,
      InsuranceValue: 0,
      InsuranceCurrency: TypeOfCurrency.CAD,
      CurrencyType: TypeOfCurrency.CAD,
      ContentDescription: "",
      Origin: origin,
      Destination: destination,
      ImporterOfRecord: new ShipmentAddressModel(),
      Packages: packages,
      Dutiable: dutiable,
      ValidateAddress: false,
      ThirdPartyId: this.thirdPartyId,
      ThirdPartyOrderNumber: this.thirdPartyOrderNumber,
      IsFromShoppingCart: IsFromShoppingCart,
      Instructions: "",
      Reference: "",
      UnitOfMeasure: UnitOfMeasure.Metric,
      PaperLess: false,
      InvoiceType: INVOICE_TYPE.SYSTEM,
      Documents: [{
        DocumentType: DOCUMENT_TYPE.OTHER,
        FileName: "",
        Content: ""
      }],
      VehicleID: 1
    }
  }
}
