// Build with --outputHashing=all for disabling the cache
// ng build --configuration=production --aot --outputHashing=all
// To Update
export const environment = {
  production: true,

  //---------------------------------------------------------------------
  // Endpoint URL to access (C)ubi (S)hipping (D)ashboard (A)PI Resources
  // NOTE: Must be the first one before deploying...
  //---------------------------------------------------------------------
  CSDAEndPoint: "https://cubishippingdashboardapi.azurewebsites.net",
  // CSDAEndPoint: "https://localhost:44316",

  //-------------------------------------------------------------------------
  // To use Google search AutoComplete, you need a key provided by the console.cloud.google.com/apis
  // Google AutoComplete API Key: https://console.cloud.google.com/apis Ask Patrick Légaré for information
  //
  // See in index.html for the Google API Key
  //-------------------------------------------------------------------------
  //GOOGLE_MAPS_API_KEY: 'AIzaSyCFti8OnaAdxfIi_zTfDI4a9FMhAWZ2d-c',
};
