<div class="table-container">
    <!-- Search bar -->
    <div class="table-container__actions">
      <div>
          <mat-icon style="color:rgb(95, 95, 95); margin:0 5px 0 15px" class="icon material-icons">
              search
          </mat-icon>
      </div>
      <div class="table-container__actions__filter">
          <mat-form-field>
              <input matInput type="text" [(ngModel)]="searchInput" (keyup)="applySearch($event.target.value)"
                  placeholder="{{ 'app.misc.search' | translate }}" />
              <button mat-button *ngIf="searchInput" matSuffix mat-icon-button aria-label="Clear"
                  (click)="applySearch('')">
                  <mat-icon>
                      close
                  </mat-icon>
              </button>
          </mat-form-field>
      </div>
  </div>
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="Pro_Name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'app.customProducts.name' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [attr.data-label]="'app.customProducts.name' | translate">
        <span class="dots" *ngIf="row.Pro_Action != 'UPDATE' && row.Pro_Action != 'ADD'">
          {{ row.Pro_Name }}
        </span>
        <mat-form-field appearance="outline" *ngIf="row.Pro_Action == 'UPDATE' || row.Pro_Action == 'ADD'">
          <mat-label>{{'app.customProducts.name' | translate}}</mat-label>
          <input matInput required maxlength="100" id="{{row.Pro_Name}}" [(ngModel)]="row.Pro_Name"
            [ngModelOptions]="{standalone: true}" name="{{row.Pro_Name}}" autocomplete="customProducts"
            placeholder=" {{'app.customProducts.name' | translate}}" />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Pro_Desc">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'app.customProducts.description' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [attr.data-label]="'app.customProducts.description' | translate">
        <span class="dots" *ngIf="row.Pro_Action != 'UPDATE' && row.Pro_Action != 'ADD'">
          {{ row.Pro_Desc }}
        </span>
        <mat-form-field appearance="outline" *ngIf="row.Pro_Action == 'UPDATE' || row.Pro_Action == 'ADD'">
          <mat-label>{{'app.customProducts.description' | translate}}</mat-label>
          <input matInput maxlength="200" id="{{row.Pro_Desc}}" [(ngModel)]="row.Pro_Desc"
            [ngModelOptions]="{standalone: true}" name="{{row.Pro_Desc}}" autocomplete="customProducts"
            placeholder=" {{'app.customProducts.description' | translate}}"
            />
        </mat-form-field>

      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Pro_CON_Ref">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'app.customProducts.countryOfOrigin' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [attr.data-label]="'app.customProducts.countryOfOrigin' | translate">
        <span class="dots" *ngIf="row.Pro_Action != 'UPDATE' && row.Pro_Action != 'ADD'">
          {{ getCountryName(row.Pro_CON_Ref) }}
        </span>
        <mat-form-field appearance="outline" *ngIf="row.Pro_Action == 'UPDATE' || row.Pro_Action == 'ADD'">
          <mat-label>
            {{'app.shopifyOrders.details.shipperConsigneeDetails.countryName' | translate}}
          </mat-label>
          <mat-select [(value)]="row.Pro_CON_Ref">
            <mat-option [value]="country.CON_ID" *ngFor="let country of countriesList">
              {{country.CON_Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Pro_HSCode">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'app.customProducts.hscode' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [attr.data-label]="'app.customProducts.hscode' | translate">
        <span class="dots" *ngIf="row.Pro_Action != 'UPDATE' && row.Pro_Action != 'ADD'">
          {{ row.Pro_HSCode }}
        </span>
        <mat-form-field appearance="outline" *ngIf="row.Pro_Action == 'UPDATE' || row.Pro_Action == 'ADD'">
          <mat-label>{{'app.customProducts.hscode' | translate}}</mat-label>
          <input matInput maxlength="10" id="{{row.Pro_HSCode}}" [(ngModel)]="row.Pro_HSCode"
            [ngModelOptions]="{standalone: true}" name="{{row.Pro_HSCode}}" autocomplete="customProducts"
            placeholder="{{'app.customProducts.hscode' | translate}}" />
        </mat-form-field>

      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Pro_Quantity">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'app.customProducts.quantity' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [attr.data-label]="'app.customProducts.quantity' | translate">
        <span class="dots" *ngIf="row.Pro_Action != 'UPDATE' && row.Pro_Action != 'ADD'">
          {{ row.Pro_Quantity }}
        </span>
        <mat-form-field appearance="outline" *ngIf="row.Pro_Action == 'UPDATE' || row.Pro_Action == 'ADD'">
          <mat-label>{{'app.customProducts.quantity' | translate}}</mat-label>
          <input matInput id="{{row.Pro_Quantity}}" [(ngModel)]="row.Pro_Quantity" [ngModelOptions]="{standalone: true}"
            name="{{row.Pro_Quantity}}" autocomplete="customProducts" class="text-right-align" type="text"
            pattern="[0-9.]*" placeholder="{{'app.customProducts.quantity' | translate}}"
            />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Pro_Weight">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'app.customProducts.weight' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [attr.data-label]="'app.customProducts.weight' | translate">
        <span class="dots" *ngIf="row.Pro_Action != 'UPDATE' && row.Pro_Action != 'ADD'">
          {{ row.Pro_Weight }}
        </span>
        <mat-form-field appearance="outline" *ngIf="row.Pro_Action == 'UPDATE' || row.Pro_Action == 'ADD'">
          <mat-label>{{'app.customProducts.weight' | translate}}</mat-label>
          <span matSuffix *ngIf="row.UnitOfMeasure == 0">&nbsp;kg</span>
          <span matSuffix *ngIf="row.UnitOfMeasure == 1">&nbsp;lbs</span>
          <input matInput id="{{row.Pro_Weight}}" [(ngModel)]="row.Pro_Weight" [ngModelOptions]="{standalone: true}"
            name="{{row.Pro_Weight}}" autocomplete="customProducts" class="text-right-align" type="text"
            pattern="[0-9.]*" placeholder="{{'app.customProducts.weight' | translate}}"
            />
        </mat-form-field>

      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Pro_UnitValue">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'app.customProducts.unitvalue' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [attr.data-label]="'app.customProducts.unitvalue' | translate">
        <span class="dots" *ngIf="row.Pro_Action != 'UPDATE' && row.Pro_Action != 'ADD'">
          {{ row.Pro_UnitValue }}
        </span>
        <mat-form-field appearance="outline" *ngIf="row.Pro_Action == 'UPDATE' || row.Pro_Action == 'ADD'">
          <mat-label>{{'app.customProducts.unitvalue' | translate}}</mat-label>
          <input matInput id="{{row.Pro_UnitValue}}" [(ngModel)]="row.Pro_UnitValue"
            [ngModelOptions]="{standalone: true}" name="{{row.Pro_UnitValue}}" autocomplete="customProducts"
            class="text-right-align" type="text" pattern="[0-9.]*"
            placeholder="{{'app.customProducts.unitvalue' | translate}}" />
        </mat-form-field>

      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="UnitOfMeasure">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'app.misc.unitOfMeasure' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [attr.data-label]="'app.misc.unitOfMeasure' | translate">
        <span class="dots" *ngIf="row.Pro_Action != 'UPDATE' && row.Pro_Action != 'ADD'">
          {{ getUOMName(row.UnitOfMeasure) }}
        </span>
        <mat-form-field appearance="outline" *ngIf="row.Pro_Action == 'UPDATE' || row.Pro_Action == 'ADD'">
          <mat-label> {{'app.misc.unitOfMeasure' | translate}}*</mat-label>
          <mat-select [(ngModel)]="row.UnitOfMeasure">
            <mat-option [value]="measure.code" *ngFor="let measure of measures">{{measure.name | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Actions
      </mat-header-cell>
      <mat-cell *matCellDef="let row" style="max-height: 10px;">
        <div *ngIf="row.Pro_Action == 'UPDATE' || row.Pro_Action == 'ADD'" style="margin-top: 12px">
          <span style="cursor: pointer;margin-right: 5px;margin-top: 10px;"
            matTooltip="{{'app.misc.cancel' | translate}}" matTooltipPosition="above"
            (click)="onCancel(row)">
            <mat-icon style="color:red" class="material-icons">
              cancel
            </mat-icon>
          </span>
          <span style="cursor: pointer;;margin-top: 10px;" matTooltip="{{'app.misc.save' | translate}}"
            matTooltipPosition="above" (click)="onSaveCustomProduct(row)">
            <mat-icon style="color: blue" class="material-icons">
              save
            </mat-icon>
          </span>
        </div>
        <div *ngIf="row.Pro_Action != 'UPDATE' && row.Pro_Action != 'ADD'" style="margin-top: 10px">
          <span style="margin-right: 10px;cursor:pointer" matTooltip="{{'app.misc.edit' | translate}}"
            matTooltipPosition="above"
            (click)="onModifyCustomProduct(row)">
            <mat-icon style="color: blue" class="material-icons">
              edit
            </mat-icon>
          </span>
          <span style="cursor:pointer" matTooltip="{{'app.misc.delete' | translate}}" matTooltipPosition="above"
            (click)="onRemoveCustomProduct(row)">
            <mat-icon style="color:red" class="material-icons">
              delete
            </mat-icon>
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Table header definition -->
    <mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true">
    </mat-header-row>

    <!-- Table row definition -->
    <mat-row *matRowDef="let row; columns: displayedColumns">
    </mat-row>
  </mat-table>
</div>
<br>
<button mat-raised-button color="primary" mat-button (click)="onAddCustomProduct()" [disabled]="!enableAdd">
  {{ 'app.customProducts.addNewProduct' | translate }}
  <mat-icon class="material-icons">
    add
  </mat-icon>
</button>

<!-- The overlay to display when the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>