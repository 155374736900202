<!-- Main menu section that appears on the left side of every page -->
<div class="main-menu" *ngIf="!isGuest">
  <mat-nav-list class="mat-nav-list">
    <!-- Menu list -->
    <div class="mat-nav-list__menu-top">
      <!-- Active orders -->
      <mat-list-item class="parent" [routerLink]="['/active-orders']" routerLinkActive="active-link">
        <mat-icon class="icon material-icons">dashboard</mat-icon>
        <div class="mat-list-text mat-nav-list__menu-top--margin">{{ 'app.menu.activeOrders' | translate }}</div>
      </mat-list-item>

      <!-- Quotes -->
      <mat-list-item class="parent" [routerLink]="['/quotes/8']" routerLinkActive="active-link" (click)='setQuoteType("8")'>
        <mat-icon class="icon material-icons">rate_review</mat-icon>
        <div class="mat-list-text mat-nav-list__menu-top--margin">{{ 'app.menu.quotes' | translate }}</div>
      </mat-list-item>

      <!-- Quick Quotes -->
      <mat-list-item class="parent" [routerLink]="['/quotes/51']" routerLinkActive="active-link" (click)='setQuoteType("51")'>
        <mat-icon class="icon material-icons">request_quote</mat-icon>
        <div class="mat-list-text mat-nav-list__menu-top--margin">{{ 'app.menu.quickQuotes' | translate }}</div>
      </mat-list-item>

      <!-- Shopify orders -->
      <mat-list-item class="parent" [routerLink]="['/shopify-orders']" routerLinkActive="active-link" *ngIf="shopifyAccess">
        <mat-icon class="icon material-icons">border_color</mat-icon>
        <div class="mat-list-text mat-nav-list__menu-top--margin">{{ 'app.menu.shopifyOrdersPageTitle' | translate }}
        </div>
      </mat-list-item>

      <!-- Pickups -->
      <mat-list-item class="parent" [routerLink]="['/pickupsList']" routerLinkActive="active-link">
        <mat-icon class="icon material-icons">departure_board</mat-icon>
        <div class="mat-list-text mat-nav-list__menu-top--margin">{{ 'app.menu.pickups' | translate }}</div>
      </mat-list-item>

      <!-- History -->
      <mat-list-item class="parent" [routerLink]="['/history']" routerLinkActive="active-link">
        <mat-icon class="icon material-icons">history</mat-icon>
        <div class="mat-list-text mat-nav-list__menu-top--margin">{{ 'app.menu.history' | translate }}</div>
      </mat-list-item>

      <!-- Address book -->
      <mat-list-item class="parent" [routerLink]="['/addressbook']" routerLinkActive="active-link">
        <mat-icon class="icon material-icons">library_books</mat-icon>
        <div class="mat-list-text mat-nav-list__menu-top--margin">{{ 'app.menu.addressBook' | translate }}</div>
      </mat-list-item>

      <!-- Custom packages -->
      <mat-list-item class="parent" [routerLink]="['/custom-packages']" routerLinkActive="active-link">
        <mat-icon class="icon material-icons">local_shipping</mat-icon>
        <div class="mat-list-text mat-nav-list__menu-top--margin">{{ 'app.menu.customPackages' | translate }}</div>
      </mat-list-item>

      <!-- Custom products -->
      <mat-list-item class="parent" [routerLink]="['/custom-products']" routerLinkActive="active-link">
        <mat-icon class="icon material-icons">art_track</mat-icon>
        <div class="mat-list-text mat-nav-list__menu-top--margin">{{ 'app.menu.customProducts' | translate }}</div>
      </mat-list-item>

      <!-- Manage Clients -->
      <mat-list-item class="parent" [routerLink]="['/manageclientsviewlayout']" routerLinkActive="active-link" *ngIf="clientManagementAccess">
        <mat-icon class="icon material-icons">manage_accounts</mat-icon>
        <div class="mat-list-text mat-nav-list__menu-top--margin">{{ 'app.menu.clientsManagement' | translate }}</div>
      </mat-list-item>
      
      <!-- Import Orders -->
      <mat-list-item class="parent" [routerLink]="['/importorders']" routerLinkActive="active-link" *ngIf="importAccess">
        <mat-icon class="icon material-icons">import_export</mat-icon>
        <div class="mat-list-text mat-nav-list__menu-top--margin">{{ 'app.menu.importOrders' | translate }}</div>
      </mat-list-item>

      <!-- Administration -->
      <mat-list-item class="parent" [routerLink]="['/administration']" routerLinkActive="active-link" *ngIf="AdministratorOrSystem">
        <mat-icon class="icon material-icons">admin_panel_settings</mat-icon>
        <div class="mat-list-text mat-nav-list__menu-top--margin">Administration</div>
      </mat-list-item>

    </div>
  </mat-nav-list>
</div>

