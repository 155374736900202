import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, Injectable, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NgxPrintModule } from "ngx-print";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AngularSplitModule } from "angular-split";

import { environment } from '../environments/environment';
import { AddressEditorComponent } from "./features/address-book/pages/address-editor/address-editor.component";
import { CustomPackagesComponent } from './features/custom-packages/custom-packages.component';
import { CustomPackagesModule } from './features/custom-packages/custom-packages/custom-packages.module';
import { CustomProductsComponent } from './features/custom-products/custom-products.component';
import { CustomProductsModule } from './features/custom-products/custom-products/custom-products.module';
import { ManageClientsViewLayoutComponent } from './features/manage-clients/Layout/Layout.component';
import { ClientsComponent } from './features/manage-clients/Clients/Clients.component';
import { DivisionsComponent } from "./features/manage-clients/Divisions/Divisions.component";
import { UsersComponent } from './features/manage-clients/Users/Users.component';
import { RatesComponent } from './features/manage-clients/Rates/Rates.component';
import { RateInsurancesComponent } from './features/manage-clients/RateInsurances/RateInsurances.component';
import { RateInsurancesModule } from "./features/manage-clients/RateInsurances/RateInsurances.module";
import { ImportComponent } from './features/import/import.component';

import { MatListModule } from '@angular/material/list';
import { ClientsModule } from "./features/manage-clients/Clients/Clients.module";
import { DivisionsModule } from './features/manage-clients/Divisions/Divisions.module';
import { ManageClientsViewLayoutModule } from './features/manage-clients/Layout/Layout.module';
import { UsersModule } from "./features/manage-clients/Users/Users.module";
import { RatesModule } from "./features/manage-clients/Rates/Rates.module";
import { ImportModule } from "./features/import/import.module";
import { UploadOrdersComponent } from './features/upload-orders/upload-orders.component';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { UploadOrdersModule } from "./features/upload-orders/upload-orders.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ParametersComponent } from './features/manage-clients/Parameters/Parameters.component';
import { ParametersModule } from "./features/manage-clients/Parameters/Parameters.module";
import { ProductModule } from "./features/product/product.module";
import { MatButtonModule } from "@angular/material/button";
import { ShopifyOrdersModule } from "./features/shopify-orders/shopify-orders.module";
import { UserRightsComponent } from './features/manage-clients/User-Rights/User-Rights.component';
import { UserRightsModule } from "./features/manage-clients/User-Rights/User-Rights.module";
import { AdminModule } from "./features/administration/admin/admin.module";

const maskConfig: Partial<IConfig> = {
	validation: false,
};

// const googleMapsParams = {
// 	apiKey: environment.GOOGLE_MAPS_API_KEY,
// 	libraries: ['places'],
// 	language: 'en',
// 	// region: 'DE'
// };
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    console.error('Global error handler:', error);
  }
}
  
@NgModule({
	declarations: [	AppComponent, 
				   	AddressEditorComponent, 
				   	CustomPackagesComponent, 
				   	CustomProductsComponent, 
				   	ManageClientsViewLayoutComponent,
				   	ClientsComponent, 
				   	DivisionsComponent,
       				UsersComponent,
           			RatesComponent,
              		RateInsurancesComponent,
					ParametersComponent,
					UserRightsComponent,
     				ImportComponent,
         			UploadOrdersComponent,
         			ParametersComponent], 
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		MatFormFieldModule,
		MatInputModule,
		MatAutocompleteModule,
		FormsModule,
		ReactiveFormsModule,
		NgxPrintModule,
		NgxMaskModule.forRoot(maskConfig),
		CoreModule,
		SharedModule,
		AppRoutingModule,
		GooglePlaceModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			},
			isolate: false
		}),
  		CustomPackagesModule,
  		CustomProductsModule,
  		ManageClientsViewLayoutModule,
		AngularSplitModule,
		ClientsModule,
  		DivisionsModule,
		UsersModule,
		RatesModule,
		RateInsurancesModule,
		ParametersModule,
		UserRightsModule,
		ImportModule,
		MatProgressBarModule,
		UploadOrdersModule,
		MatTooltipModule,
		ProductModule,
		MatButtonModule,
		ShopifyOrdersModule,
		MatListModule,
		AdminModule
	],
	providers: [],
	bootstrap: [AppComponent],
	schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}
