<style>
  /* ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-outline-start,
  ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-outline-end {
    border-width: 2px;
    border-color:black;
  } */
</style>

<!-- Step 1 : Construct shipment form https://reactgo.com/angular-turnoff-autocomplete/#:~:text=To%20disable%20the%20autocomplete%20for,its%20autocomplete%20attribute%20to%20off%20.&text=We%20can%20also%20disable%20the,the%20html%20element.-->
<div class="construct-shipment">
  <div class="construct-shipment__row" style="margin-top:10px" *ngIf="!isGuest">
    <button mat-raised-button type="button" mat-icon-button color="warn" style="margin:auto;cursor:pointer;font-size:2em" (click)="swapAddresses()" matTooltip="{{ 'app.misc.swapAddresses' | translate }}" matTooltipPosition="right">
      <mat-icon style="margin-top:-5px">
        swap_horiz
      </mat-icon>
    </button>
  </div>
  <!-- Contains shipper and consignee informations -->
  <div class="construct-shipment__row">
    <!-- Shipper card -->
    <div class="construct-shipment__card" [formGroup]="parentForm">
      <mat-card>
        <!-- Card title -->
        <mat-card-title>
          {{ "app.shopifyOrders.details.shipper" | translate }}
        </mat-card-title>

        <!-- Content that display a shipper form -->
        <mat-card-content>
          <div class="form" formGroupName="shipperForm">
            <!-- Compagny name input line -->
            <!-- https://www.tutsmake.com/angular-11-autocomplete-using-angular-material-example/ -->
            <mat-form-field appearance="outline" *ngIf="!isQuickQuote">
              <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.companyName' | translate}}</mat-label>
              <input matInput required maxlength="100" [matAutocomplete]="autoShipperCompanyName"
                placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.companyName' | translate}}"
                formControlName="companyName" id="companyNameText" (ngModelChange)="onShipperValueChanged()"
                autocomplete="shipper" />
              <mat-autocomplete #autoShipperCompanyName="matAutocomplete" [displayWith]="GetDisplay(true)"
                (optionSelected)="SelectedAddress(true, $event)">
                <mat-option style="line-height: normal;" *ngFor="let option of originAddresses | async"
                  [value]="option.Row">
                  <div style="font-size: 14px;font-weight: bold;">{{ option.AddressTitle }}</div>
                  <div style="font-size: 12px;color: gray">{{ option.AddressDetail }}</div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <!-- Person name input line -->
            <mat-form-field appearance="outline" *ngIf="!isQuickQuote">
              <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.personName' | translate}}</mat-label>
              <input matInput required autocomplete="shipper" minlength="3" maxlength="100"
                placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.personName' | translate}}"
                formControlName="personName" (ngModelChange)="onShipperValueChanged()" />
            </mat-form-field>

            <!-- Country input line with selector-->
            <mat-form-field appearance="outline">
              <mat-label>
                {{'app.shopifyOrders.details.shipperConsigneeDetails.countryName' | translate}}
              </mat-label>
              <mat-select required formControlName="countryID" (selectionChange)="changeCountryEvent(true)"
                (ngModelChange)="onShipperValueChanged()" [typeaheadDebounceInterval]="500">
                <mat-option [value]="country.CON_ID" *ngFor="let country of countriesList">
                  {{country.CON_Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Phone number and email input line -->
            <div class="form__row" *ngIf="!isQuickQuote">
              <!-- Phone number -->
              <mat-form-field appearance="outline">
                <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.phone' | translate}}</mat-label>
                <!-- <input required matInput type="text" prefix="+1 " mask="(000) 000-0000" autocomplete="shipper" -->
                <input required matInput type="text" mask="{{phoneMaskOrigin}}" autocomplete="shipper"
                  placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.phone' | translate}}"
                  formControlName="phone" />
              </mat-form-field>

              <!-- Phone extension -->
              <mat-form-field appearance="outline">
                <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.phone' | translate}} Ext.</mat-label>
                <input matInput type="text" autocomplete="shipper" maxlength="50"
                  placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.phone' | translate}} Ext."
                  formControlName="extension" />
              </mat-form-field>
            </div>

            <div class="form__row" *ngIf="!isQuickQuote">
              <!-- Email -->
              <mat-form-field appearance="outline">
                <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.email' | translate}}</mat-label>
                <input matInput required autocomplete="shipper" maxlength="200"
                  placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.email' | translate}}"
                  formControlName="email" />
              </mat-form-field>

              <!-- Checkbox to indicate if it is a residential address    -->
              <mat-form-field appearance="none" style="text-align: center;">
                <textarea matInput hidden></textarea>
                <mat-checkbox formControlName="isResidential">
                  {{'app.shopifyOrders.details.isResidential' | translate}}</mat-checkbox>
              </mat-form-field>
            </div>

            <!-- Google search box -->
            <mat-form-field class="full-width" [appearance]="appearance" *ngIf="!isQuickQuote">
              <mat-label style="color: rgb(175, 149, 0);">
                {{'app.misc.searchAddress' | translate}}
              </mat-label>
              <!------------------------------------------------------------------------------------------------------------------------
              -- Pour désactiver le autofill du browser quand un input est géré par
              -- Google Places AutoComplete faut ajouter onfocus comme vous le voyez ici-bas
              -- https://stackoverflow.com/questions/29931712/chrome-autofill-covers-autocomplete-for-google-maps-api-v3/56632769#56632769
              ------------------------------------------------------------------------------------------------------------------------->
              <input
                ngx-google-places-autocomplete
                matInput
                placeholder="{{'app.misc.searchAddress' | translate}}"
                class="form-control"
                autocomplete="shipper"
                (focus)="changeCountryEvent(true);"
                onfocus="this.setAttribute('autocomplete', 'shipper')"
                #shipperSearchAddress
              /> 
            </mat-form-field>

            <!-- Street No, Street Name and Unit -->
            <div class="form__row" *ngIf="!isQuickQuote">
              <!-- Street name input line -->
              <mat-form-field appearance="outline">
                <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.streetName' | translate}}</mat-label>
                <input matInput required autocomplete="shipper" maxlength="100"
                  placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.streetName' | translate}}"
                  formControlName="streetName" (ngModelChange)="onShipperValueChanged()" />
              </mat-form-field>

              <!-- Unit -->
              <mat-form-field appearance="outline">
                <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.unit' | translate}}</mat-label>
                <input matInput maxlength="100"
                  placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.unit' | translate}}"
                  autocomplete="shipper" formControlName="unit" #shipperUnitElement
                  (ngModelChange)="onShipperValueChanged()" />
              </mat-form-field>
            </div>

            <!-- City input line -->
            <mat-form-field appearance="outline">
              <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.cityName' | translate}}</mat-label>
              <input matInput [required]="!isQuickQuote" autocomplete="shipper" maxlength="100"
                placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.cityName' | translate}}"
                formControlName="cityName" (ngModelChange)="onShipperValueChanged()" />
            </mat-form-field>

            <!-- Postal code and province input line -->
            <div class="form__row">
              <!-- Postal Code -->
              <div style="width:50%">
                <mat-form-field appearance="outline">
                  <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.zipCode' | translate}}</mat-label>
                  <input matInput required autocomplete="shipper" maxlength="50"
                    placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.zipCode' | translate}}"
                    formControlName="zipCode" (ngModelChange)="onShipperValueChanged()" />
                </mat-form-field>
              </div>

              <!-- List of states / provinces -->
              <div style="width:49%" *ngIf="!isQuickQuote">
                <mat-form-field appearance="outline">
                  <mat-label>
                    {{'app.misc.state' | translate}}
                  </mat-label>
                  <mat-select matNativeControl formControlName="stateID" required
                    (ngModelChange)="onShipperValueChanged()">
                    <mat-option [value]="state.PRV_ID" *ngFor="let state of shipperFilteredStateProvinces">
                      {{state.PRV_Name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <!-- Special instructions -->
            <mat-form-field appearance="outline" *ngIf="!isQuickQuote">
              <mat-label>{{'app.shopifyOrders.details.specialInstruction' | translate}} (1000c)</mat-label>
              <textarea matInput maxlength="1000" autocomplete="shipper"
                placeholder="{{ 'app.shopifyOrders.details.specialInstruction' | translate }}"
                formControlName="specialInstruction">
                </textarea>
            </mat-form-field>
          </div>
          <!-- Button to save the address -->
          <button *ngIf="!isQuickQuote" [disabled]="disableShipperSaveAddress || !shipper().valid" type="button" mat-raised-button
            color="primary" (click)="saveCurrentAddress(true)">
            {{'app.addressEditor.saveAddress' | translate}}
            <mat-icon class="material-icons">
              save
            </mat-icon>
          </button>
        </mat-card-content>
      </mat-card>
    </div>

    <button *ngIf="isGuest" mat-raised-button type="button" mat-icon-button color="warn" style="margin:auto;cursor:pointer;font-size:2em" (click)="swapAddresses()" matTooltip="{{ 'app.misc.swapAddresses' | translate }}" matTooltipPosition="right">
      <mat-icon style="margin-top:-5px">
        swap_horiz
      </mat-icon>
    </button>
    
    <!-- Consignee card -->
    <div class="construct-shipment__card" [formGroup]="parentForm">
      <mat-card>
        <!-- Card title -->
        <mat-card-title>
          {{ "app.shopifyOrders.details.consignee" | translate }}
        </mat-card-title>

        <!-- Content that display a consignee form  -->
        <mat-card-content>
          <div class="form" formGroupName="consigneeForm">
            <!-- Compagny name input line -->
            <mat-form-field appearance="outline" *ngIf="!isQuickQuote">
              <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.companyName' | translate}}</mat-label>
              <input matInput required maxlength="100" [matAutocomplete]="autoConsigneeCompanyName"
                placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.companyName' | translate}}"
                formControlName="companyName" (ngModelChange)="onConsigneeValueChanged()" />
              <mat-autocomplete #autoConsigneeCompanyName="matAutocomplete" [displayWith]="GetDisplay(false)"
                (optionSelected)="SelectedAddress(false, $event)">
                <mat-option style="line-height: normal;" *ngFor="let option of destinationAddresses | async"
                  [value]="option.Row">
                  <div style="font-size: 14px;font-weight: bold;">{{ option.AddressTitle }}</div>
                  <div style="font-size: 12px;color: gray">{{ option.AddressDetail }}</div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <!-- Person name input line -->
            <mat-form-field appearance="outline" *ngIf="!isQuickQuote">
              <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.personName' | translate}}</mat-label>
              <input matInput required autocomplete="consignee" minlength="3" maxlength="100"
                placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.personName' | translate}}"
                formControlName="personName" (ngModelChange)="onConsigneeValueChanged()" />
            </mat-form-field>

            <!-- Country input line with selector-->
            <mat-form-field appearance="outline">
              <mat-label>
                {{'app.shopifyOrders.details.shipperConsigneeDetails.countryName' | translate}}
              </mat-label>
              <mat-select required formControlName="countryID" (selectionChange)="changeCountryEvent(false)"
                (ngModelChange)="onConsigneeValueChanged()" [typeaheadDebounceInterval]="500">
                <mat-option [value]="country.CON_ID" *ngFor="let country of countriesList">
                  {{country.CON_Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Phone number and email input line REF: https://www.npmjs.com/package/ngx-mask -->
            <div class="form__row" *ngIf="!isQuickQuote">
              <!-- Phone number -->
              <mat-form-field appearance="outline">
                <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.phone' | translate}}</mat-label>
                <!-- <input required matInput type="text" prefix="+1 " mask="(000) 000-0000" autocomplete="consignee" -->
                <input required matInput type="text" mask="{{phoneMaskDesctination}}" autocomplete="consignee"
                  placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.phone' | translate}}"
                  formControlName="phone" />
              </mat-form-field>

              <!-- Phone extension -->
              <mat-form-field appearance="outline">
                <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.phone' | translate}} Ext.</mat-label>
                <input matInput type="text" autocomplete="consignee" maxlength="50"
                  placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.phone' | translate}} Ext."
                  formControlName="extension" />
              </mat-form-field>
            </div>

            <div class="form__row" *ngIf="!isQuickQuote">
              <!-- Email -->
              <mat-form-field appearance="outline">
                <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.email' | translate}}</mat-label>
                <input matInput required autocomplete="consignee" maxlength="200"
                  placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.email' | translate}}"
                  formControlName="email" />
              </mat-form-field>

              <!-- Checkbox to indicate if it is a residential address    -->
              <mat-form-field appearance="none" style="text-align: center;">
                <textarea matInput hidden></textarea>
                <mat-checkbox formControlName="isResidential">
                  {{'app.shopifyOrders.details.isResidential' | translate}}</mat-checkbox>
              </mat-form-field>
            </div>

            <!-- Google search box -->
            <mat-form-field class="full-width" [appearance]="appearance" *ngIf="!isQuickQuote">
              <mat-label style="color: rgb(175, 149, 0);">
                {{'app.misc.searchAddress' | translate}}
              </mat-label>
              <!------------------------------------------------------------------------------------------------------------------------
              -- Pour désactiver le autofill du browser quand un input est géré par
              -- Google Places AutoComplete faut ajouter onfocus comme vous le voyez ici-bas
              -- https://stackoverflow.com/questions/29931712/chrome-autofill-covers-autocomplete-for-google-maps-api-v3/56632769#56632769
              ------------------------------------------------------------------------------------------------------------------------->
              <input
                ngx-google-places-autocomplete
                matInput
                placeholder="{{'app.misc.searchAddress' | translate}}"
                class="form-control"
                autocomplete="consignee"
                (focus)="changeCountryEvent(false);"
                onfocus="this.setAttribute('autocomplete', 'consignee');"
                #consigneeSearchAddress
              /> 
            </mat-form-field>

            <!-- Street No, Street Name and Unit -->
            <div class="form__row" *ngIf="!isQuickQuote">
              <!-- Street name input line -->
              <mat-form-field appearance="outline">
                <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.streetName' | translate}}</mat-label>
                <input matInput required autocomplete="consignee" maxlength="100"
                  placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.streetName' | translate}}"
                  formControlName="streetName" (ngModelChange)="onConsigneeValueChanged()" />
              </mat-form-field>

              <!-- Unit -->
              <mat-form-field appearance="outline">
                <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.unit' | translate}}</mat-label>
                <input matInput maxlength="100"
                  placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.unit' | translate}}"
                  autocomplete="consignee" formControlName="unit" #consigneeUnitElement
                  (ngModelChange)="onConsigneeValueChanged()" />
              </mat-form-field>
            </div>

            <!-- City input line -->
            <mat-form-field appearance="outline">
              <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.cityName' | translate}}</mat-label>
              <input matInput [required]="!isQuickQuote" autocomplete="consignee" maxlength="100"
                placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.cityName' | translate}}"
                formControlName="cityName" (ngModelChange)="onConsigneeValueChanged()" />
            </mat-form-field>

            <!-- Postal code and province input line -->
            <div class="form__row">
              <!-- Postal Code -->
              <div style="width:50%">
                <mat-form-field appearance="outline">
                  <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.zipCode' | translate}}</mat-label>
                  <input matInput required autocomplete="consignee" maxlength="50"
                    placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.zipCode' | translate}}"
                    formControlName="zipCode" (ngModelChange)="onConsigneeValueChanged()" />
                </mat-form-field>
              </div>

              <!-- List of states / provinces -->
              <div style="width:49%" *ngIf="!isQuickQuote">
                <mat-form-field appearance="outline">
                  <mat-label>
                    {{'app.misc.state' | translate}}
                  </mat-label>
                  <mat-select matNativeControl formControlName="stateID" required
                    (ngModelChange)="onConsigneeValueChanged()">
                    <mat-option [value]="state.PRV_ID" *ngFor="let state of consigneeFilteredStateProvinces">
                      {{state.PRV_Name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <!-- Special instructions -->
            <mat-form-field appearance="outline" *ngIf="!isQuickQuote">
              <mat-label>{{'app.shopifyOrders.details.specialInstruction' | translate}} (1000c)</mat-label>
              <textarea matInput maxlength="1000"
                placeholder="{{ 'app.shopifyOrders.details.specialInstruction' | translate }}"
                formControlName="specialInstruction">
                  </textarea>
            </mat-form-field>
          </div>

          <!-- Button to save the address -->
          <button *ngIf="!isQuickQuote" [disabled]="disableConsigneeSaveAddress || !consignee().valid" type="button" mat-raised-button
            color="primary" (click)="saveCurrentAddress(false)">
            {{'app.addressEditor.saveAddress' | translate}}
            <mat-icon class="material-icons">
              save
            </mat-icon>
          </button>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- Shipment Details Task#4918 -->
  <div class="construct-shipment__row " [formGroup]="parentForm" *ngIf="productFormVisible && !isQuickQuote">
    <div class="construct-shipment__card">
      <mat-card>
        <mat-card-title> {{ "app.misc.shipmentDetailsSection" | translate }} </mat-card-title>
        <mat-card-content>
          <div formGroupName="optionForm">
            <div class="form__row">
              <!-- Ajout d'un composant div pour limiter l'espace occupé par le checkbox -->
              <div>
                <mat-form-field appearance="none">
                  <textarea matInput hidden></textarea>
                  <mat-checkbox formControlName="IsDocumentOnly" (change)="changeDocumentOnlyStatus($event.checked)">
                    {{'app.shopifyOrders.details.packageDetails.isDocumentOnly' | translate}}
                  </mat-checkbox>
                </mat-form-field>
              </div>
            </div>

            <div class="form__row">
              <!-- Content Description -->
              <mat-form-field appearance="outline">
                <mat-label>{{'app.misc.contentDescriptionLabel' | translate}} (90c)</mat-label>
                <textarea matInput maxlength="90" rows="1"
                  placeholder="{{ 'app.misc.contentDescriptionPlaceHolder' | translate }}"
                  formControlName="ContentDescription">
                </textarea>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- Package Detail Form -->
  <div class="construct-shipment__row " [formGroup]="parentForm">
    <div class="construct-shipment__card">
      <mat-card>
        <!-- Card title -->
        <mat-card-title> {{ "app.shopifyOrders.details.packageDetailsTitle" | translate }} </mat-card-title>
        <!-- Card content -->
        <br>
        <mat-card-content>
          <div class="form__row" formGroupName="optionForm">
            <!-- Unit of measure-->
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <mat-form-field appearance="outline" style="width:10vw; align-content: end;margin-right:20px">
                <mat-label> {{'app.misc.unitOfMeasure' | translate}}*</mat-label>
                <mat-select formControlName="UnitOfMeasure" (selectionChange)="changeMeasurementDisplay($event.value)">
                  <mat-option [value]="measure.code" *ngFor="let measure of measures">{{measure.name | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

                <!-- Dry Ice -->
              <mat-form-field appearance="none" *ngIf="!documentOnlyChecked">
                <textarea matInput hidden></textarea>
                <mat-checkbox formControlName="IsDryIce" (change)="dryIceChanged($event)" #dryIce>
                  {{'app.shopifyOrders.details.options.isDryIce' | translate}}
                </mat-checkbox>
              </mat-form-field>
            </div>
          </div>

          <!-- Packages details-->
          <!-- Cette ligne a été modifié pour rendre le card responsive en mettant les différents mat-form-field dans un div flex | zidane-->
          <!-- <div class="form" formArrayName="packageForm"></div> -->

          <div formArrayName="packageForm">
            <ng-container *ngFor="let package of packages().controls; let packageIndex = index; let count = count" class="row">
              <div class="row">
              <ng-container [formGroupName]="packageIndex">
                <!-- Cette ligne a été enlevé. il contenait les différents champs jusqu'aux boutons pour dupliquer et supprimer -->
                <!-- <div class="form__row"></div> -->
                  <!-- Custom Packages.  Upon selection we fill the fields automatically -->
                  <mat-form-field appearance="outline" style="width:10vw" *ngIf="!isGuest">
                    <mat-label> {{'app.misc.predefined' | translate}}</mat-label>
                    <mat-select (selectionChange)="customPackagesChanged($event.value, packageIndex)">
                      <mat-option [value]="customPackage.PAC_ID" *ngFor="let customPackage of customPackages">
                        {{customPackage.PAC_Name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- Package type without documents only, all packages type are available -->
                  <mat-form-field appearance="outline" style="width:10vw">
                    <mat-label> {{'app.shopifyOrders.details.packageDetails.packageTypeLabel' | translate}}*
                    </mat-label>
                    <mat-select formControlName="packageType">
                      <mat-option [value]="type.code" *ngFor="let type of packageTypes">{{type.name | translate}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- Quantity -->
                  <mat-form-field appearance="outline" style="width:7vw">
                    <mat-label>{{'app.shopifyOrders.details.packageDetails.quantity' | translate}}</mat-label>
                    <input matInput class="text-right-align" type="text" pattern="[0-9]*" required
                      placeholder="{{'app.shopifyOrders.details.packageDetails.quantity' | translate}}"
                      formControlName="quantity" />
                  </mat-form-field>
                  <!-- Depth -->
                  <mat-form-field appearance="outline" style="width:7vw">
                    <mat-label>{{'app.shopifyOrders.details.packageDetails.depth' | translate}}</mat-label>
                    <span *ngIf="metricMeasureDisplayed" matSuffix>&nbsp;cm</span>
                    <span *ngIf="!metricMeasureDisplayed" matSuffix>&nbsp;{{'app.misc.inch' | translate}}</span>
                    <input matInput class="text-right-align" type="text" pattern="[0-9.]*" required
                      placeholder="{{'app.shopifyOrders.details.packageDetails.depth' | translate}}"
                      formControlName="depth" />
                  </mat-form-field>
                  <!-- Width -->
                  <mat-form-field appearance="outline" style="width:7vw">
                    <mat-label>{{'app.shopifyOrders.details.packageDetails.width' | translate}}</mat-label>
                    <span *ngIf="metricMeasureDisplayed" matSuffix>&nbsp;cm</span>
                    <span *ngIf="!metricMeasureDisplayed" matSuffix>&nbsp;{{'app.misc.inch' | translate}}</span>
                    <input matInput class="text-right-align" type="text" pattern="[0-9.]*" required
                      placeholder="{{'app.shopifyOrders.details.packageDetails.width' | translate}}"
                      formControlName="width" />
                  </mat-form-field>
                  <!-- Height -->
                  <mat-form-field appearance="outline" style="width:7vw">
                    <mat-label>{{'app.shopifyOrders.details.packageDetails.height' | translate}}</mat-label>
                    <span *ngIf="metricMeasureDisplayed" matSuffix>&nbsp;cm</span>
                    <span *ngIf="!metricMeasureDisplayed" matSuffix>&nbsp;{{'app.misc.inch' | translate}}</span>
                    <input matInput class="text-right-align" type="text" pattern="[0-9.]*" required
                      placeholder="{{'app.shopifyOrders.details.packageDetails.height' | translate}}"
                      formControlName="height" />
                  </mat-form-field>
                  <!-- Weight -->
                  <mat-form-field appearance="outline" style="width:7vw">
                    <mat-label>{{'app.shopifyOrders.details.packageDetails.weight' | translate}}</mat-label>
                    <span *ngIf="metricMeasureDisplayed" matSuffix>&nbsp; kg</span>
                    <span *ngIf="!metricMeasureDisplayed" matSuffix>&nbsp; lb</span>
                    <input matInput class="text-right-align" type="text" pattern="[0-9.]*" required
                      placeholder="{{'app.shopifyOrders.details.packageDetails.weight' | translate}}"
                      formControlName="weight" (change)="validateDryIceWeight(package)" />
                  </mat-form-field>

                  <!-- Dry Ice Weight -->
                  <div *ngIf="isDryIce && !documentOnlyChecked">
                    <mat-form-field appearance="outline" style="width:7vw" *ngIf="isDryIce">
                      <mat-label>{{'app.shopifyOrders.details.packageDetails.dryIceWeight' | translate}}</mat-label>
                      <span *ngIf="metricMeasureDisplayed" matSuffix>&nbsp; kg</span>
                      <span *ngIf="!metricMeasureDisplayed" matSuffix>&nbsp; lb</span>
                      <input matInput class="text-right-align" type="text" pattern="[0-9.]*" required
                        placeholder="{{'app.shopifyOrders.details.packageDetails.dryIceWeight' | translate}}"
                        formControlName="dryIceWeight" (change)="validateDryIceWeight(package)" />
                    </mat-form-field>
                    </div>
                  <div *ngIf="!isDryIce && !documentOnlyChecked">
                    <mat-form-field appearance="outline" style="width:7vw" *ngIf="isDryIce">
                      <mat-label>{{'app.shopifyOrders.details.packageDetails.dryIceWeight' | translate}}</mat-label>
                      <span *ngIf="metricMeasureDisplayed" matSuffix>&nbsp; kg</span>
                      <span *ngIf="!metricMeasureDisplayed" matSuffix>&nbsp; lb</span>
                      <input matInput class="text-right-align" type="text" pattern="[0-9.]*" 
                        placeholder="{{'app.shopifyOrders.details.packageDetails.dryIceWeight' | translate}}"
                        formControlName="dryIceWeight" />
                      </mat-form-field>
                  </div>

                  <!-- Ce div a été ajouté pour regrouper les 2 boutons pour ajouter et enlever une nouvelle ligne pour les détails du produit -->
                  <div style="display: flex; flex-direction: row; align-items: center; margin-top: -20px;">
                    <!-- Button to duplicate a package -->
                    <div style="display: flex; flex-direction: column; align-items: center;margin-right:20px">
                      <span style="font-size: 9px;margin-top: -8px;padding-bottom: 4px;">{{'app.quotes.duplicate' | translate}}</span>
                      <button type="button" mat-icon-button color="warn" (click)="onDuplicatePackage(packageIndex)" style="padding: 0;margin-top:-14px">
                        <mat-icon class="material-icons">
                          file_copy
                        </mat-icon>
                      </button>
                    </div>
                    
                    <!-- Button to remove a package -->
                    <div style="display: flex; flex-direction: column; align-items: center;">
                      <span style="font-size: 9px;margin-top: -8px;padding-bottom: 4px;">{{'app.quotes.delete' | translate}}</span>
                      <button [disabled]="count === 1" type="button" mat-icon-button color="warn" (click)="onRemovePackage(packageIndex)" style="padding: 0;margin-top:-14px">
                        <mat-icon class="material-icons" [ngClass]="{'orangered-icon': count > 1}">
                          backspace
                        </mat-icon>
                      </button>
                    </div>
                  </div>
                  
                  
                  
              </ng-container>
            </div>
            </ng-container>
          </div>

          <!-- Add package button -->
          <!-- Ce div a été modifié pour séparer les champs du bouton ADD PACKAGE -->
          <!-- <div class="form__row form__row--end"> -->
          <div class="form__row form__row" formArrayName="packageForm">
            <button mat-raised-button style="max-height: 35px; width: 200px;" color="primary" (click)="onAddPackage()">
              {{'app.shopifyOrders.details.addPackage' | translate}}
              <mat-icon class="material-icons">
                add_circle
              </mat-icon>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- Options -->
  <div class="construct-shipment__row " [formGroup]="parentForm" *ngIf="!isQuickQuote">
    <div class="construct-shipment__card">
      <mat-card>
        <mat-card-title> {{ "app.shopifyOrders.details.options.optionsTitle" | translate }} </mat-card-title>
        <mat-card-content>
          <div formGroupName="optionForm">
            <div class="form__row">

              <!-- Signature required -->
              <mat-form-field appearance="none">
                <textarea matInput hidden></textarea>
                <mat-checkbox formControlName="IsSignatureRequired">
                  {{'app.shopifyOrders.details.options.signatureRequired' | translate}}
                </mat-checkbox>
              </mat-form-field>

              <!-- Saturday delivery -->
              <mat-form-field appearance="none">
                <textarea matInput hidden></textarea>
                <mat-checkbox formControlName="IsSaturdayDelivery">
                  {{'app.shopifyOrders.details.options.isSaturdayDelivery' | translate}}
                </mat-checkbox>
              </mat-form-field>

              <!-- Special Handling -->
              <mat-form-field appearance="none">
                <textarea matInput hidden></textarea>
                <mat-checkbox formControlName="IsSpecialHandling">
                  {{'app.shopifyOrders.details.options.isSpecialHandling' | translate}}
                </mat-checkbox>
              </mat-form-field>
            </div>

            <!-- Instruction and reference section -->
            <div class="form__row">
              <!-- Instructions -->
              <mat-form-field appearance="outline" style="margin-right: 10px !important;">
                <mat-label>{{'app.shopifyOrders.details.options.instructions' | translate}}</mat-label>
                <input matInput type="text" maxlength="90"
                  placeholder="{{'app.shopifyOrders.details.options.instructions' | translate}}"
                  formControlName="Instructions" />
              </mat-form-field>

              <!-- Reference -->
              <mat-form-field appearance="outline" style="margin-right: 10px !important;">
                <mat-label>{{'app.shopifyOrders.details.options.reference' | translate}}</mat-label>
                <input matInput maxlength="90" type="text"
                  placeholder="{{'app.shopifyOrders.details.options.reference' | translate}}"
                  formControlName="Reference" />
              </mat-form-field>
            </div>


            <!-- Global currency and insurance.  appears only if the document only is not checked -->
            <div class="form__row" style="width:20vh">

              <!-- Shipment delivery date selection -->
              <mat-form-field appearance="outline">
                <mat-label> {{ 'app.shopifyOrders.details.options.shipmentDate' | translate }}</mat-label>
                <input matInput [readonly]="true" formControlName="ShipmentDate" 
                [matDatepicker]="picker" [min]="minDate" [max]="maxDate" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label> {{'app.shopifyOrders.details.dutiable.currency' | translate}}</mat-label>
                <mat-select placeholder=" {{'app.shopifyOrders.details.dutiable.currency' | translate}}"
                  formControlName="GlobalCurrency" (selectionChange)="globalCurrencyChanged($event.value)">
                  <mat-option [value]="type.code" *ngFor="let type of currency">
                    {{type.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- This section appears only if the document only is not checked -->
              <div class="form__row" style="width:20vh">
                <!-- Insurance required -->
                <mat-form-field appearance="outline">
                  <mat-label> {{'app.shopifyOrders.details.options.isInsurance' | translate}}</mat-label>
                  <mat-select style="margin-right: 12px;"
                    placeholder="{{'app.shopifyOrders.details.options.isInsurance' | translate}}"
                    formControlName="IsInsurance" (selectionChange)="changeInsuranceRequiredStatus($event.value)">
                    <mat-option [value]="type.code" *ngFor="let type of yesNo">
                      {{type.name | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- This section appears only if the insurance required id checked -->
                <div class="form__row" *ngIf="insuranceRequiredSelected">

                  <!-- Insurance value -->
                  <mat-form-field appearance="outline" style="margin-right: 10px !important;">
                    <mat-label>{{'app.shopifyOrders.details.options.insuranceValue' | translate}}</mat-label>
                    <input #ins matInput type="text" inputmode="numeric"
                      placeholder="{{'app.shopifyOrders.details.options.insuranceValue' | translate}}"
                      class="text-right-align" formControlName="InsuranceValue" />
                    <span matPrefix>$&nbsp;</span>
                  </mat-form-field>

                  <!-- Insurance currency -->
                  <mat-form-field appearance="outline">
                    <mat-label> {{'app.shopifyOrders.details.options.insuranceCurrency' | translate}}</mat-label>
                    <mat-select style="margin-right: 12px;"
                      placeholder=" {{'app.shopifyOrders.details.options.insuranceCurrency' | translate}}"
                      formControlName="InsuranceCurrency" [disabled]="true">
                      <mat-option [value]="type.code" *ngFor="let type of currency">
                        {{type.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="form__row">
              <!-- Vehicle Type -->
              <mat-form-field appearance="outline" style="width:340px" *ngIf="!isQuickQuote">
                <mat-label> {{'app.misc.vehicle' | translate}}
                </mat-label>
                <mat-select formControlName="VehicleTypes">
                  <mat-option [value]="type.code" *ngFor="let type of vehicleTypes">{{type.name | translate}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- Product Detail Form -->
  <div class="construct-shipment__row " [formGroup]="parentForm" *ngIf="!isQuickQuote">
    <div class="construct-shipment__card" *ngIf="productFormVisible && !documentOnlyChecked">
      <mat-card>
        <!-- Header -->
        <mat-card-title>
          {{ "app.shopifyOrders.details.productsDetailsTitle" | translate }}
        </mat-card-title>
        
        <!-- Importer Of Record -->
        <br>
        <input type="checkbox" id="toggle" [checked]="isExpanded" (change)="isExpanded = !isExpanded">
        <label for="toggle" class="toggle-button">
          <span class="toggle-icon" *ngIf="!isExpanded">▼</span>
          <span class="toggle-icon" *ngIf="isExpanded">▲</span>
          <span class="toggle-text" [hidden]="isExpanded">{{ "app.misc.importerOfRecord" | translate }} {{ "app.misc.clickToExpand" | translate }}</span>
          <span class="toggle-text" [hidden]="!isExpanded">{{ "app.misc.importerOfRecord" | translate }} {{ "app.misc.clickToCollapse" | translate }}</span>
        </label>
    
        <div class="expandableContent">
          <div class="expandable-content">
            <br>
            <div class="construct-shipment__card" [formGroup]="parentForm">
              <mat-card>
                <!-- <mat-card-title>
                  {{ "app.shopifyOrders.details.shipper" | translate }}
                </mat-card-title> -->

                <mat-card-content>
                  <div class="form" formGroupName="iorForm">
                    <mat-form-field appearance="outline" *ngIf="!isQuickQuote">
                      <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.companyName' | translate}}</mat-label>
                      <input matInput maxlength="100" [matAutocomplete]="autoShipperCompanyName"
                        placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.companyName' | translate}}"
                        formControlName="companyName" id="companyNameText" (ngModelChange)="onIorValueChanged()" />
                      <mat-autocomplete #autoShipperCompanyName="matAutocomplete" [displayWith]="GetDisplay(true)"
                        (optionSelected)="SelectedAddress(null, $event)">
                        <mat-option style="line-height: normal;" *ngFor="let option of iorAddresses | async"
                          [value]="option.Row">
                          <div style="font-size: 14px;font-weight: bold;">{{ option.AddressTitle }}</div>
                          <div style="font-size: 12px;color: gray">{{ option.AddressDetail }}</div>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="!isQuickQuote">
                      <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.personName' | translate}}</mat-label>
                      <input matInput autocomplete="shipper" minlength="3" maxlength="100"
                        placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.personName' | translate}}"
                        formControlName="personName" (ngModelChange)="onIorValueChanged()" />
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                      <mat-label>
                        {{'app.shopifyOrders.details.shipperConsigneeDetails.countryName' | translate}}
                      </mat-label>
                      <mat-select formControlName="countryID" (selectionChange)="changeIorCountryEvent()"
                        (ngModelChange)="onIorValueChanged()" [typeaheadDebounceInterval]="500">
                        <mat-option [value]="country.CON_ID" *ngFor="let country of countriesList">
                          {{country.CON_Name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <div class="form__row" *ngIf="!isQuickQuote">
                      <mat-form-field appearance="outline">
                        <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.phone' | translate}}</mat-label>
                        <input matInput type="text" mask="{{phoneMaskIOR}}" autocomplete="shipper"
                          placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.phone' | translate}}"
                          formControlName="phone" />
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.phone' | translate}} Ext.</mat-label>
                        <input matInput type="text" autocomplete="shipper" maxlength="50"
                          placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.phone' | translate}} Ext."
                          formControlName="extension" />
                      </mat-form-field>
                    </div>

                    <div class="form__row" *ngIf="!isQuickQuote">
                      <mat-form-field appearance="outline">
                        <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.email' | translate}}</mat-label>
                        <input matInput autocomplete="shipper" maxlength="200"
                          placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.email' | translate}}"
                          formControlName="email" />
                      </mat-form-field>
                    </div>

                    <mat-form-field class="full-width" [appearance]="appearance" *ngIf="!isQuickQuote">
                      <mat-label style="color: rgb(175, 149, 0);">
                        {{'app.misc.searchAddress' | translate}}
                      </mat-label>
                      <input
                        ngx-google-places-autocomplete
                        matInput
                        placeholder="{{'app.misc.searchAddress' | translate}}"
                        class="form-control"
                        autocomplete="shipperGoogle"
                        (focus)="changeIorCountryEvent(true);"
                        onfocus="this.setAttribute('autocomplete', 'shipperGoogle')"
                        #iorSearchAddress
                      /> 
                    </mat-form-field>

                    <div class="form__row" *ngIf="!isQuickQuote">
                      <mat-form-field appearance="outline">
                        <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.streetName' | translate}}</mat-label>
                        <input matInput autocomplete="shipper" maxlength="100"
                          placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.streetName' | translate}}"
                          formControlName="streetName" (ngModelChange)="onIorValueChanged()" />
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.unit' | translate}}</mat-label>
                        <input matInput maxlength="100"
                          placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.unit' | translate}}"
                          autocomplete="shipper" formControlName="unit" #iorUnitElement
                          (ngModelChange)="onIorValueChanged()" />
                      </mat-form-field>
                    </div>

                    <mat-form-field appearance="outline" *ngIf="!isQuickQuote">
                      <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.cityName' | translate}}</mat-label>
                      <input matInput autocomplete="shipper" maxlength="100"
                        placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.cityName' | translate}}"
                        formControlName="cityName" (ngModelChange)="onIorValueChanged()" />
                    </mat-form-field>

                    <div class="form__row">
                      <div style="width:50%">
                        <mat-form-field appearance="outline">
                          <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.zipCode' | translate}}</mat-label>
                          <input matInput autocomplete="shipper" maxlength="50"
                            placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.zipCode' | translate}}"
                            formControlName="zipCode" (ngModelChange)="onIorValueChanged()" />
                        </mat-form-field>
                      </div>

                      <div style="width:49%" *ngIf="!isQuickQuote">
                        <mat-form-field appearance="outline">
                          <mat-label>
                            {{'app.misc.state' | translate}}
                          </mat-label>
                          <mat-select matNativeControl formControlName="stateID"
                            (ngModelChange)="onIorValueChanged()">
                            <mat-option [value]="state.PRV_ID" *ngFor="let state of shipperFilteredStateProvinces">
                              {{state.PRV_Name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <mat-form-field appearance="outline" *ngIf="!isQuickQuote">
                      <mat-label>Notes (1000c)</mat-label>
                      <textarea matInput maxlength="1000" autocomplete="shipper"
                        placeholder="{{ 'app.shopifyOrders.details.specialInstruction' | translate }}"
                        formControlName="specialInstruction">
                        </textarea>
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="!isQuickQuote">
                      <mat-label>{{'app.misc.taxID' | translate}}</mat-label>
                      <input matInput autocomplete="shipper" minlength="3" maxlength="100"
                        placeholder="{{'app.misc.taxID' | translate}}"
                        formControlName="taxID" (ngModelChange)="onIorValueChanged()" />
                    </mat-form-field>

                  </div>
                  <button *ngIf="!isQuickQuote" [disabled]="disableIorSaveAddress || !shipper().valid" type="button" mat-raised-button
                    color="primary" (click)="saveCurrentIorAddress()">
                    {{'app.addressEditor.saveAddress' | translate}}
                    <mat-icon class="material-icons">
                      save
                    </mat-icon>
                  </button>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
        <br>
        
        <!-- Content-->
        <mat-card-content>
          <!-- Dutiable options -->
          <div formGroupName="dutiableForm">
            <div class="">
              <!-- Broker -->
              <mat-form-field appearance="outline" style="width:15vw">
                <mat-label>{{'app.shopifyOrders.details.dutiable.broker' | translate}}</mat-label>
                <input matInput maxlength="100"
                  placeholder=" {{'app.shopifyOrders.details.dutiable.broker' | translate}}" formControlName="Broker" />
              </mat-form-field>

              <!-- Taxe Id -->
              <mat-form-field appearance="outline" style="width:15vw">
                <mat-label>{{'app.shopifyOrders.details.dutiable.taxId' | translate}}</mat-label>
                <input matInput maxlength="100"
                  placeholder=" {{'app.shopifyOrders.details.dutiable.taxId' | translate}}" formControlName="TaxID" />
              </mat-form-field>

              <!-- Currency -->
              <mat-form-field appearance="outline" style="width:15vw;pointer-events: none;user-select: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;">
                <mat-label> {{'app.shopifyOrders.details.dutiable.currency' | translate}}</mat-label>
                <mat-select style="margin-right: 12px"
                  placeholder=" {{'app.shopifyOrders.details.dutiable.currency' | translate}}"
                  formControlName="Currency" >
                  <mat-option [value]="type.code" *ngFor="let type of currency">
                    {{type.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <!-- Duties and taxes paid by -->
          <div formGroupName="dutiableForm" style="width:50vw;max-width: 200px" class="form__row">
            <mat-form-field appearance="outline">
              <mat-label> {{'app.shopifyOrders.details.options.paidByShipper' | translate}}</mat-label>
              <mat-select style="margin-right: 12px;"
                placeholder="{{'app.shopifyOrders.details.options.paidByShipper' | translate}}"
                formControlName="DutiesTaxesPaidBy"
              >
                <mat-option 
                  [value]="type.code"
                  *ngFor="let type of debitor"
                >
                  {{type.name | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Invoice Number -->
            <mat-form-field appearance="outline" style="width:10.5vw">
              <mat-label>{{'app.shopifyOrders.details.dutiable.invoiceNumber' | translate}}</mat-label>
              <input matInput required maxlength="35"
                placeholder=" {{'app.shopifyOrders.details.dutiable.invoiceNumber' | translate}}"
                formControlName="InvoiceNumber" />
            </mat-form-field>

            <!-- Invoice Date -->
            <mat-form-field appearance="outline" style="max-width: 200px">
              <mat-label> {{ 'app.shopifyOrders.details.dutiable.invoiceDate' | translate }}</mat-label>
              <input matInput [readonly]="true" formControlName="InvoiceDate" required
              [matDatepicker]="picker" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <!-- Export Reason -->
          <div formGroupName="dutiableForm" style="width:50vw;max-width: 200px" class="form__row">
              <mat-form-field appearance="outline" style="width:15vw;">
                <mat-label> {{'app.shopifyOrders.details.dutiable.exportReason' | translate}}</mat-label>
                <mat-select style="margin-right: 12px;"
                  placeholder=" {{'app.shopifyOrders.details.dutiable.exportReason' | translate}}"
                  formControlName="ExportReason">
                  <mat-option [value]="type.code" *ngFor="let type of exportReason">
                    {{type.name | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- Export Reason Detail -->
              <mat-form-field appearance="outline" style="min-width: calc(50vw - 30%);">
                <mat-label>{{'app.shopifyOrders.details.dutiable.exportReasonDetail' | translate}} (300c)</mat-label>
                <textarea class="exportReasonDetailText" matInput rows="1" maxlength="300" autocomplete="exportreasondetail"
                  placeholder="{{ 'app.shopifyOrders.details.dutiable.exportReasonDetail' | translate }} (300c)"
                  formControlName="ExportReasonDetail">
                </textarea>
              </mat-form-field>
          </div>

          <!-- Products list -->
          <div class="form" formGroupName="productForm">
            <mat-label>{{'app.shopifyOrders.details.productsDetails.productsDetailsListTitle' | translate}}</mat-label>
            <br>
            <ng-container *ngFor="let product of products().controls; let productIndex=index">
              <ng-container [formGroupName]="productIndex">
                <div class="row">

                  <!-- Custom Products.  Upon selection we fill the fields automatically -->
                  <mat-form-field appearance="outline" style="width:10.5vw">
                    <mat-label> {{'app.misc.predefined' | translate}}</mat-label>
                    <mat-select (selectionChange)="customProductsChanged($event.value, productIndex)">
                      <mat-option [value]="product.Pro_Id" *ngFor="let product of customProducts">
                        {{product.Pro_Name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- Description -->
                  <mat-form-field appearance="outline" style="width:10.5vw">
                    <mat-label>{{'app.shopifyOrders.details.productsDetails.description' | translate}}</mat-label>
                    <input matInput required autocomplete="products" maxlength="50"
                      placeholder=" {{'app.shopifyOrders.details.productsDetails.description' | translate}}"
                      formControlName="description" />
                  </mat-form-field>

                  <!-- Country input line with selector-->
                  <!-- Task #4488 point #4 -->
                  <mat-form-field appearance="outline" style="width:10.5vw">
                    <mat-label>
                      {{'app.shopifyOrders.details.productsDetails.countryOfOrigin' | translate}}
                    </mat-label>
                    <mat-select required formControlName="countryOfOrigin">
                      <mat-option [value]="country.CON_Code" *ngFor="let country of countriesList">
                        {{country.CON_Name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- Country Origin -->
                  <!-- <mat-form-field appearance="outline">
                    <mat-label>{{'app.shopifyOrders.details.productsDetails.countryOfOrigin' | translate}}</mat-label>
                    <input matInput maxlength="100" class="text-right-align" required autocomplete="products"
                      placeholder="{{'app.shopifyOrders.details.productsDetails.countryOfOrigin' | translate}}"
                      formControlName="countryOfOrigin" />
                  </mat-form-field> -->

                  <!-- HS code-->
                  <mat-form-field appearance="outline" style="width:10.5vw">
                    <mat-label>{{'app.shopifyOrders.details.productsDetails.hsCode' | translate}}</mat-label>
                    <input matInput maxlength="100" class="text-right-align" autocomplete="products"
                      placeholder="{{'app.shopifyOrders.details.productsDetails.hsCode' | translate}}"
                      formControlName="hsCode" />
                  </mat-form-field>

                  <!-- Quantity -->
                  <mat-form-field appearance="outline" style="width:10.5vw">
                    <mat-label>{{'app.shopifyOrders.details.productsDetails.qty' | translate}}</mat-label>
                    <input matInput class="text-right-align" type="text" pattern="[0-9]*" required
                      autocomplete="products"
                      placeholder="{{'app.shopifyOrders.details.productsDetails.qty' | translate}}"
                      formControlName="qty" />
                  </mat-form-field>

                  <!-- Unit Weight -->
                  <mat-form-field appearance="outline" style="width:7vw">
                    <mat-label>{{'app.shopifyOrders.details.packageDetails.weight' | translate}}</mat-label>
                    <span *ngIf="metricMeasureDisplayed" matSuffix>&nbsp; kg</span>
                    <span *ngIf="!metricMeasureDisplayed" matSuffix>&nbsp; lb</span>
                    <input matInput class="text-right-align" type="text" pattern="[0-9.]*" required
                      autocomplete="products"
                      placeholder="{{'app.shopifyOrders.details.packageDetails.weight' | translate}}"
                      formControlName="weight" />
                  </mat-form-field>

                  <!-- Unit Price -->
                  <mat-form-field appearance="outline" style="width:10.5vw">
                    <mat-label>{{'app.shopifyOrders.details.productsDetails.unitPrice' | translate}}</mat-label>
                    <span matSuffix> &nbsp;$ </span>
                    <input matInput class="text-right-align" type="text" pattern="[0-9.]*" required
                      autocomplete="products"
                      placeholder="{{'app.shopifyOrders.details.productsDetails.unitPrice' | translate}}"
                      formControlName="unitPrice" />
                  </mat-form-field>

                  <!-- Button to remove a product -->
                  <div style="margin-top: 8px">
                    <button type="button" mat-icon-button color="warn" (click)="onRemoveProduct(productIndex)">
                      <mat-icon class="material-icons">
                        disabled_by_default
                      </mat-icon>
                    </button>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <!-- Buttons GET ALL PRODUCTS ON ORDERS and ADD NEW PRODUCT -->
            <div class="form__row ">
              <!-- <button mat-raised-button *ngIf="order.Dutiable.ShipmentDutiableRows.length > 0" color="primary"
                (click)="onGetExistingProducts()" type="button">
                {{'app.shopifyOrders.details.getAllOrderProducts' | translate}}
              </button> -->
              <div>
                <button mat-raised-button color="primary" (click)="onAddProduct()" type="button">
                  {{'app.shopifyOrders.details.addNewProduct' | translate}}
                  <mat-icon class="material-icons">
                    add_circle
                  </mat-icon>
                </button>
                <button mat-raised-button color="primary" style="margin-left:8px" (click)="showProforma()" type="button">
                  {{'app.shopifyOrders.details.previewInvoice' | translate}}
                  <mat-icon class="material-icons">
                    visibility
                  </mat-icon>
                </button>
              </div>
            </div>
          </div>
          <hr style="margin-top:20px">

          <!----------------------------------------------------------------------------------------------------->
          <!-- If Paperless is NO, there's no reason to ask the Invoice Type: 2023-5-19 (Patrick)              -->
          <!-- if the customer requests to be personalized for the Invoice Type, CSAPI doesn't need to know it -->
          <!----------------------------------------------------------------------------------------------------->

          <!-- Task #4551: PaperLess and InvoiceType options -->
          <div formGroupName="dutiableForm" style="width:150px;margin-top:20px" class="form__row" *ngIf="isPaperlessAvailable">
            <!-- Paperless -->
            <mat-form-field appearance="outline">
              <mat-label> {{'app.shopifyOrders.details.dutiable.paperLess' | translate}}</mat-label>
              <mat-select style="margin-right: 12px;"
                placeholder="{{'app.shopifyOrders.details.dutiable.paperLess' | translate}}"
                formControlName="PaperLess" (selectionChange)="changePaperLessOption($event.value)">
                <mat-option [value]="type.code" *ngFor="let type of yesNo">
                  {{type.name | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Invoice Type-->
            <div *ngIf="isPaperLess === true">
              <mat-form-field appearance="outline" style="width:10vw; align-content: end;">
                <mat-label> {{'app.shopifyOrders.details.dutiable.invoiceType' | translate}}*</mat-label>
                <mat-select formControlName="InvoiceType" (selectionChange)="changeInvoiceType($event.value)">
                  <mat-option [value]="invoiceType.code" *ngFor="let invoiceType of invoiceTypes">{{invoiceType.name | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <!-- Task #4551: documents upload  -->
          <div class="form" formGroupName="documentForm" *ngIf="isPaperLess === true">
            <ng-container *ngFor="let document of documents().controls; let documentIndex=index">
              <ng-container [formGroupName]="documentIndex">
                <div class="documentRow">
                  <!-- Document Type -->
                  <div
                    [style.pointer-events] = "DisableDocumentType(documentIndex) ? 'none' : 'auto'"
                    [style.opacity] = "DisableDocumentType(documentIndex) ? .5 : 1"
                  >
                    <mat-form-field appearance="outline" style="width:10vw; align-content: end;">
                      <mat-label> {{'app.shopifyOrders.details.dutiable.documentType' | translate}}*</mat-label>
                      <mat-select
                        formControlName="documentType"
                        (selectionChange)="changeDocumentType($event.value, documentIndex)"
                      >
                        <mat-option [value]="documentType.code" *ngFor="let documentType of documentTypes">{{documentType.name | translate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <!-- FileName -->
                  <mat-form-field appearance="outline" style="width:20vw">
                    <mat-label>{{'app.shopifyOrders.details.dutiable.documentFileName' | translate}}</mat-label>
                    <input
                      matInput
                      [required]="isFileNameRequired(documentIndex)"
                      maxlength="500"
                      [readonly]="true"
                      placeholder=" {{'app.shopifyOrders.details.dutiable.documentFileName' | translate}}"
                      formControlName="fileName" />
                  </mat-form-field>

                  <!-- Button to upload -->
                  <div class="documentButton">
                    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
                    <button
                      mat-raised-button
                      color="primary"
                      type="button"
                      (click)="onUploadDocument(documentIndex)"
                    >
                      {{'app.shopifyOrders.details.dutiable.uploadDocument' | translate}}
                      <mat-icon class="material-icons">
                        file_upload
                      </mat-icon>
                    </button>
                  </div>

                  <!-- Button to see the document -->
                  <div class="documentButton">
                    <button
                      mat-raised-button
                      color="primary"
                      type="button"
                      (click)="onViewDocument(documentIndex)"
                      [disabled]="onDocumentNotUploaded(documentIndex) == true"
                    >
                      {{'app.shopifyOrders.details.dutiable.viewDocument' | translate}}
                      <mat-icon class="material-icons">
                        visibility
                      </mat-icon>
                    </button>
                  </div>

                  <!-- Button to delete a document -->
                  <div class="documentButton">
                    <button
                      mat-raised-button
                      type="button"
                      color="warn"
                      (click)="onDeleteDocument(documentIndex)"
                    >
                      {{'app.shopifyOrders.details.dutiable.deleteDocument' | translate}}
                      <mat-icon class="material-icons">
                        delete
                      </mat-icon>
                    </button>
                  </div>

                  <!-- Button to add a new document -->
                  <!-- The ngIf is to have the button only on the last row -->
                  <!-- The style.visibility is to hide the button if there are 5 rows -->
                  <div class="documentButton" *ngIf="documentIndex == documents().length-1">
                    <button 
                      [style.visibility]="IsAddUnAvailable(documentIndex) ? 'hidden' : 'visible'"
                      mat-raised-button 
                      color="primary" 
                      type="button" 
                      (click)="onAddDocument(documentIndex)"
                    >
                      {{'app.shopifyOrders.details.dutiable.addDocument' | translate}}
                      <mat-icon class="material-icons">
                        add_circle
                      </mat-icon>
                    </button>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<!-- Overlay displayed while the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>