import { Contact } from "./Contact";
import { ShortAddress } from "./ShortAddress";

export class ShipmentAddressModel extends ShortAddress {
    StreetName: string = "";
    CountryCode: string = "CA";
    CountryName: string = "CANADA";
    StateCode: string = "QC";
    StateName: string = "QUEBEC";
    CityName: string = "";
    ZipCode: string = "";
    Valid: boolean = true;
    Corrected: boolean = false;
    IsEnglish: boolean = true;
    IsResidential: boolean = true;
    Name: string = "";
    Unit: string = "";
    SpecialInstructions: string = "";
    TaxID: string = "";
    AddressContacts: Contact[] = [];
}