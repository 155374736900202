import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { spCSDAManageUserGetListResult } from "@app/shared/CSDAModels/ManageClientModels/User/spCSDAManageUserGetListResult";
import { GetManageUserRightsResult } from "@app/shared/CSDAModels/ManageClientModels/UserRights/GetManageUserRightsResult";
import { spCSDAManageUserRightsResult } from "@app/shared/CSDAModels/ManageClientModels/UserRights/spCSDAManageUserRightsResult";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { ManageClientsService } from "../_Services/manage-clients.service";

@Component({
  selector: 'UserRights',
  templateUrl: './User-Rights.component.html',
  styleUrls: ['./User-Rights.component.scss']
})

export class UserRightsComponent implements OnInit {
  userRights: spCSDAManageUserRightsResult[];
  usersList: spCSDAManageUserGetListResult[];
  isProcessing = false;
  selectedUser: -1;
  displayedColumns = ['name', 'description', 'hasRight'];
  dataSource: spCSDAManageUserRightsResult[];

  constructor(
    private translateService: TranslateService,
    private ManageClientsService: ManageClientsService
  ) { }

  ngOnInit(): void {
  }

  getUsers() {
    if (this.ManageClientsService.users)
      this.usersList = this.ManageClientsService.users.UsersList;
  }
  
  getUserRights(user: spCSDAManageUserGetListResult) {
    this.isProcessing = true;

    this.ManageClientsService.getUserRights(user.USR_ID).subscribe(Result => {
      this.userRights = Result.UserRights;
      this.dataSource = this.userRights;
      this.isProcessing = false;
    });
  }

  updateUserRight(UserRight: spCSDAManageUserRightsResult) {
    //this.isProcessing = true;

    this.ManageClientsService.updateUserRight(UserRight).subscribe((Result: GetManageUserRightsResult) => {

      const index = this.userRights.findIndex(object => {
        return object.RightId === Result.UserRight.RightId;
      });

      this.userRights[index] = Result.UserRight;
      //this.isProcessing = false;
    });
  }

  highlight(row) {
    this.selectedUser = row.USR_ID;
  }
}
