import { spCSDAGetOrdersListResult } from "@app/shared/CSDAModels/spCSDAGetOrdersListResult";

export class ActiveOrdersTableModel {

    /** Data table properties */
    public orderNumber: number;
    public thirdPartyName: string;
    public thirdPartyOrderNumber: string;
    public thirdPartyId: number;
    public reference: string;

    public pickupConfirmationNumber: string;
    public pickupDate: string;
    public readyByTime: string;

    public originName: string;
    public originCityName: string;
    public originStateCode: string;
    public originCountryCode: string;

    public destinationName: string;
    public destinationCityName: string;
    public destinationStateCode: string;
    public destinationCountryCode: string;

    public carrier: string;
    public serviceTypeDescription: string;
    public deliveryDate: string;
    public shipmentDate: string;

    public trackingNumber: string;
    public lastTrackingDate: string;
    public lastTrackingStatusCode: string;
    public lastTrackingStatusDesc: string;

    public statusFr: string;
    public statusEn: string;

    public totalWeight: number;
    public totalPieces: number;
    public unitOfMeasure: string;

    public completOrderResponse: spCSDAGetOrdersListResult;

    /** Constructor of the ActiveOrdersTableModel class */
    constructor(response: spCSDAGetOrdersListResult) {

        /** Order */
        this.orderNumber = response.OrderNumber;
        this.reference = response.Reference;
        this.thirdPartyName = response.ThirdPartyName;
        this.thirdPartyOrderNumber = response.ThirdPartyOrderNumber;
        this.thirdPartyId = response.ThirdPartyId;
        this.unitOfMeasure = response.UnitOfMeasure;
        
        /** Pickup */
        this.pickupConfirmationNumber = response.PickupConfirmationNumber;
        this.pickupDate = response.PickupDate;
        
        /** Adjust the value to display only 5 characters HH:mm */
        if (response.ReadyByTime != undefined && response.ReadyByTime != null && response.ReadyByTime != "") {
            if (response.ReadyByTime.length > 5) {
                this.readyByTime = response.ReadyByTime.substr(0, 5);
            }
            else {
                this.readyByTime = response.ReadyByTime;
            }
        }

        /** Origin */
        this.originName = response.OriginName;
        this.originCityName = response.OriginCityName;
        this.originStateCode = response.OriginStateCode;
        this.originCountryCode = response.OriginCountryCode;

        /** Destination */
        this.destinationName = response.DestinationName;
        this.destinationCityName = response.DestinationCityName;
        this.destinationStateCode = response.DestinationStateCode;
        this.destinationCountryCode = response.DestinationCountryCode;

        /** Carrier */
        this.carrier = response.Carrier;
        this.serviceTypeDescription = response.ServiceTypeDescription;
        this.deliveryDate = response.DeliveryDate.toString();
        this.shipmentDate = response.ShipmentDate.toString();
        
        /** Tracking */
        this.trackingNumber = response.TrackingNumber;
        this.lastTrackingDate = response.LastTrackingDate;
        this.lastTrackingStatusCode = response.LastTrackingStatusCode;
        this.lastTrackingStatusDesc = response.LastTrackingStatusDesc;

        /** Status */
        this.statusFr = response.StatusFr;
        this.statusEn = response.StatusEn;

        /** Weight and pieces */
        this.totalWeight = response.TotalWeight;
        this.totalPieces = response.TotalPieces;

        /** Keep The original shipment response received from the API */
        this.completOrderResponse = response;
    }
}