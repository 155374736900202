//----------------------------------------------------------------------------------------------------------------
// Anytime you ship something internationally (with the exception of documents)
// a commercial invoice is required.  There are many reasons this document is required
// but mainly, it is so that custom's officials can determine if the items you are
// shipping can be imported within the country (are they allowed, restricted, or prohibited),
// but also to calculate the amount of tax to impose on the shipment (if any).
// 
// The 'Reason for the Export' is an important element on the commercial invoice and
// selecting the right option can affect how your shipment is cleared and how much tax is imposed on your shipment
// https://support.secureship.ca/hc/en-us/articles/227404867-Reasons-for-Export-Purpose-of-Shipment
//----------------------------------------------------------------------------------------------------------------
export enum ReasonForExport {
    NONE = 0,
    PermanentSold = 1,
    Temporary = 2,
    Gift = 3,
    RepairWarranty = 4,
    CommercialSample = 5,
    PersonalEffects = 6,
    ReturnShipment = 7,
    Other = 8
}