<!-- Template for the Shipper and Consigne cards -->
<div [formGroup]="parentForm"
  class="common-form">
 
    <div
      class="form"
      [formGroupName]="groupName">

      <!-- Compagny name input line -->
      <mat-form-field appearance="outline" >
        <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.companyName' | translate}}</mat-label>
        <input
          matInput
          placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.companyName' | translate}}"
          formControlName="companyName" />
      </mat-form-field>

      <!-- Person name input line -->
      <mat-form-field appearance="outline">
        <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.personName' | translate}}</mat-label>
        <input
          matInput
          placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.personName' | translate}}"
          formControlName="personName" />
      </mat-form-field>
            <!-- Phone number and email input line -->
            <div
            class="form__row">
            <!-- Phone number -->
            <mat-form-field appearance="outline">
              <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.phone' | translate}}</mat-label>
              <input
                required
                pattern="[0-9 () -]*"
                matInput type="tel"
                placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.phone' | translate}}"
                formControlName="phone" />
            </mat-form-field>
    
            <!-- Email -->
            <mat-form-field appearance="outline">
              <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.email' | translate}}</mat-label>
              <input
                matInput
                placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.email' | translate}}"
                formControlName="email" />
            </mat-form-field>
    
            <!-- Checkbox to indicate if it is a residential address    --> 
            <mat-form-field appearance="none" style="text-align: center;">
              <textarea matInput hidden></textarea>
              <mat-checkbox formControlName="isResidential">{{'app.shopifyOrders.details.isResidential' | translate}}</mat-checkbox>
            </mat-form-field>
          </div>

      <!-- Country input line with selector-->
      <mat-form-field appearance="outline">
        <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.countryName' | translate}}</mat-label>
        <mat-select
          formControlName="countryCode"
          (selectionChange)="changeCountryEvent($event)">
          <mat-option
            [value]="country.code"
            *ngFor="let country of countries">
            {{country.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Postal code and province input line -->
      <div class="form__row">
        <!-- Postal Code -->
        <mat-form-field appearance="outline">
          <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.zipCode' | translate}}</mat-label>
          <input
            matInput
            placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.zipCode' | translate}}"
            formControlName="zipCode" />
        </mat-form-field>

        <!-- Province -->
        <mat-form-field appearance="outline">
          <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.stateName' | translate}}</mat-label>
          <input
            matInput
            placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.stateName' | translate}}"
            formControlName="stateCode" />
        </mat-form-field>
      </div>

      <!-- City input line -->
      <mat-form-field appearance="outline">
        <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.cityName' | translate}}</mat-label>
        <input
          matInput
          placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.cityName' | translate}}"
          formControlName="cityName" />
      </mat-form-field>

      <!-- Unit and street number input line -->
      <div class="form__row">
        <!-- Unit -->
        <mat-form-field appearance="outline">
          <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.unit' | translate}}</mat-label>
          <input
            matInput
            placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.unit' | translate}}"
            formControlName="unit" />
        </mat-form-field>
      </div>

      <!-- Street name input line -->
      <mat-form-field appearance="outline">
        <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.streetName' | translate}}</mat-label>
        <input
          matInput
          placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.streetName' | translate}}"
          formControlName="streetName" />
      </mat-form-field>
     
      <!-- Special instructions -->
      <mat-form-field appearance="outline">
      <mat-label>{{'app.shopifyOrders.details.specialInstruction' | translate}}</mat-label>
        <textarea
          matInput
          maxlength="90"
          placeholder="{{ 'app.shopifyOrders.details.specialInstruction' | translate }}"
          formControlName="specialInstruction">
        </textarea>
      </mat-form-field>
    </div>
  
</div>
