import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomProductsComponent } from "../custom-products.component";

const routes: Routes = [{ path: "", redirectTo: "custom-products", pathMatch: 'full' }, { path: "custom-products", component: CustomProductsComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomProductsRoutingModule { }
