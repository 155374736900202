import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "@app/shared/shared.module";
import { ImportRoutingModule } from "./import-routing.module";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    ImportRoutingModule,
  ]
})
export class ImportModule { }
