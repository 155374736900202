import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";

import { Observable } from "rxjs/Observable";
import { catchError, map } from "rxjs/internal/operators";
import { throwError } from "rxjs";

import { StorageService } from "../services/storage.service";
import { Router, RoutesRecognized } from "@angular/router";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
	constructor(private router: Router, public storageService: StorageService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Useful to get URL parameters
		// var sub = this.router.events.subscribe(val => {
		// 	if (val instanceof RoutesRecognized) {
		// 	  console.log(val.state.root.queryParams["t"]);
		// 	}
		// });

		const token: string = this.storageService.getAuthenticationToken();
		if (token === null) {
			return next.handle(request);
		}

		if (token) {
			request = request.clone({
				setHeaders: { Authorization: `Bearer ${token}` }
			});
		}

		if (!request.headers.has('Content-Type')) {
			request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
		}

		request = request.clone({ headers: request.headers.set('Accept', 'text/plain') });

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					
					if (event.status === 401) {
						void this.router.navigate(["/account/login"]);
					}
				}
				return event;
			}),
			catchError((err) => {
				if (err instanceof HttpErrorResponse) {
					if (err.status === 401) {
						void this.router.navigate(["/account/login"]);
						return throwError('oops! token problem');
					}
				}
			})
		);
	}
}
