import { Injectable } from "@angular/core";
import { AddressContainer } from "@app/shared/CSDAModels/AddressContainer";

@Injectable({
	providedIn: "root"
})
export class StorageService {
	constructor() { }

	// Token
	public setAuthenticationToken(val: string): void {
		sessionStorage.setItem("auth_tkn", val);
	}

	public getAuthenticationToken(): string {
		return sessionStorage.getItem("auth_tkn");
	}

	public removeAuthenticationToken(): void {
		sessionStorage.removeItem("auth_tkn");
	}

	// Token
	public setAuthenticationMetaToken(val: string): void {
		localStorage.setItem("auth_meta", val);
	}

	public getAuthenticationMetaToken(): string {
		return localStorage.getItem("auth_meta");
	}

	public removeAuthenticationMetaToken(): void {
		localStorage.removeItem("auth_meta");
	}

	// Language
	public setLanguage(val: string): void {
		localStorage.setItem("language", val);
	}

	public getLanguage(): string {
		return localStorage.getItem("language");
	}

	public removeLanguage(): void {
		localStorage.removeItem("language");
	}

	// User
	public setUser(val: string): void {
		localStorage.setItem("user", val);
	}

	public getUser(): string {
		return localStorage.getItem("user");
	}

	public removeUser(): void {
		localStorage.removeItem("user");
	}

	// Store the origin of a created shipment
	public setShipmentOrigin(val: string): void {
		localStorage.setItem("shipmentOrigin", val);
	}

	public getShipmentOrigin(): string {
		return localStorage.getItem("shipmentOrigin");
	}

	public removeShipmentOrigin(): void {
		localStorage.removeItem("shipmentOrigin");
	}

	// Store the order Id
	public setOrderId(val: string): void {
		localStorage.setItem("orderId", val);
	}

	public getOrderId(): string {
		return localStorage.getItem("orderId");
	}

	public removeOrderId(): void {
		localStorage.removeItem("orderId");
	}

	//--------------------------------------------------------------------
	// Store true or false to know if we go directly to step2 (Rates list)
	// via a button on the Quotes list
	//--------------------------------------------------------------------
	public setGoToStepTwo(val: string): void {
		localStorage.setItem("gotosteptwo", val);
	}

	public getGoToStepTwo(): boolean {
		return localStorage.getItem("gotosteptwo") == "true" ? true : false;
	}

	//--------------------------------------------------------------------
	// Store true or false to know if the step 1 has finished loading
	//--------------------------------------------------------------------
	public setStep1Completed(val: string): void {
		localStorage.setItem("step1completed", val);
	}

	public getStep1Completed(): boolean {
		return localStorage.getItem("step1completed") == "true" ? true : false;
	}

	public removeStep1Completed(): void {
		localStorage.removeItem("step1completed");
	}

	//--------------------------------------------------------
	// Store an address.  If val is null we set a new instance
	// otherwise we set the provided one
	//--------------------------------------------------------
	public setAddress(val: AddressContainer): void {
		var Address:AddressContainer;

		//-------------------------------------------
		// If we have an existing address we parse it
		// otherwise we return a new instance
		//-------------------------------------------
		if(!val) {
			val = {
				Address: {
				ADD_ID: 0,
				ADD_Company: "",
				ADD_StreetName: "",
				ADD_Unit: "",
				ADD_City: "",
				ADD_PRV_Ref: null,
				ADD_Zip: "",
				ADD_CON_Ref: null,
				ADD_IsResidential: null,
				ADD_SpecialInstructions: "",
				ADD_Div_Ref: null,
				ADD_CloseTime: "",
				ADD_TaxID: "",
				},
				Contacts: [{
					ADC_ID: 0,
					ADC_ADD_Ref: null,
					ADC_Name: "",
					ADC_Email: "",
					ADC_EmailNotification: null,
					ADC_Phone: "",
					ADC_PhoneExt: "",
					ADC_Fax: "",
					ADC_Pager: "",
					ADC_IsDefault: false,
					ADC_Typ_Ref: 26 // Not Assigned
				}]
			};
		}
		
		sessionStorage.setItem("addressContainer", JSON.stringify(val));
	}

	public getAddress(): AddressContainer {
		//----------------------------------------------------------------------------
		// This is sure to return a json of an AddressContainer since in the set above
		// we make sure to instantiate a new one or use an existing one
		//----------------------------------------------------------------------------
		return JSON.parse(sessionStorage.getItem("addressContainer"));
	}
}
