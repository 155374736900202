import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class LoaderService {
	//Loader in main toolbar
	public isLoading = new BehaviorSubject(false);
	private processesLoading = 0;

	// Loader in whole screen
	public loadingScreen = new BehaviorSubject(false);
	public processesloadingScreen = 0;

	constructor() { }

	incrementProcessesLoading(incrementValue: number): void {
		this.processesLoading += incrementValue;
		this.isLoading.next(this.processesLoading > 0);
	}

	decrementProcessesLoading(decrementValue: number): void {
		this.processesLoading -= decrementValue;
		this.isLoading.next(this.processesLoading > 0);
	}

	incrementProcessesScreenLoading(incrementValue: number): void {
		this.processesloadingScreen += incrementValue;
		this.loadingScreen.next(this.processesloadingScreen > 0);
	}

	decrementProcessesScreenLoading(decrementValue: number): void {
		this.processesloadingScreen -= decrementValue;
		this.loadingScreen.next(this.processesloadingScreen > 0);
	}
}
