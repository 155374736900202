import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { map } from "rxjs/internal/operators";
import { Observable } from "rxjs/Observable";

import { StorageService } from "./storage.service";
import * as moment from 'moment';
import { BehaviorSubject } from "rxjs";
import { environment } from "@env";
import { UserCredentialsResult } from "@app/shared/CSDAModels/UserCredentialsResult";
import { ValidationService } from "./validation.service";
import Swal from "sweetalert2";
import { LanguageService } from "./language.service";
import { UserCredentialsDetail } from "@app/shared/CSDAModels/UserCredentialsDetail";

const jwt = new JwtHelperService();
class DecodedToken {
	exp: number;
	username: string;
}

@Injectable({
	providedIn: "root"
})
export class AuthenticationService {
	private decodedToken;

	private currentTokenSubject: BehaviorSubject<string>;
	public currentToken: Observable<string>;
	
	constructor(
		private http: HttpClient,
		private storageService: StorageService,
		public validationService: ValidationService,
		private languageService: LanguageService,
	) {
		this.decodedToken = JSON.parse(this.storageService.getAuthenticationMetaToken()) || new DecodedToken();
		this.currentTokenSubject = new BehaviorSubject<string>(this.storageService.getAuthenticationToken());
		this.currentToken = this.currentTokenSubject.asObservable();
	}

	public get currentTokenValue(): string {
		return this.currentTokenSubject.value;
	}

	public login(username: string, password: string): Observable<any> {
		this.storageService.setGoToStepTwo("false");
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});

		const options = { headers: headers };
		const URI = environment.CSDAEndPoint + '/CSDA/Authenticate';
		var Detail: UserCredentialsDetail = {
			UserName: username,
			Password: password,
			Language: this.languageService.GetCurrentLanguage()
		}
		return this.http.post(URI, Detail, options).pipe(map((Result: UserCredentialsResult) => {
			var validation = this.validationService.validate(Result, false);

			if (validation.isValid === false) {
				Swal.fire('', validation.message, "error").then(Result => {
					return null;
				});
			}

			if (validation.message !== "") {
				Swal.fire('', validation.message, "info").then(Result => {
					return null;
				});
			}

			sessionStorage.setItem("AddressesList", JSON.stringify(Result.AddressesList));
			sessionStorage.setItem("Countries", JSON.stringify(Result.Countries));
			sessionStorage.setItem("Provinces", JSON.stringify(Result.Provinces));
			sessionStorage.setItem("Currencies", JSON.stringify(Result.Currencies));
			sessionStorage.setItem("Carriers", JSON.stringify(Result.Carriers));
			sessionStorage.setItem("InvoiceSystems", JSON.stringify(Result.InvoiceSystems));
			sessionStorage.setItem("ServiceDescriptions", JSON.stringify(Result.ServiceDescriptions));
			sessionStorage.setItem("ForceReference", JSON.stringify(Result.ForceReference));
			sessionStorage.setItem("PaymentMethod", Result.PaymentMethod);

			//-----------------------------------------------------------------------------------------------------------------
			// Task #4605 : Client maintenance.  This will be used to handle menu option as well as calls to the manage actions
			//-----------------------------------------------------------------------------------------------------------------
			sessionStorage.setItem("CurrentUserRights", JSON.stringify(Result.UserRights));

			return this.saveToken(Result);
		}));
	}

	private saveToken(token: any): any {
		this.storageService.setAuthenticationToken(token.Token)
		this.currentTokenSubject.next(token.Token);

		return token;
	}

	public logout(): void {
		this.storageService.removeAuthenticationToken();
	}

	public isAuthenticated(): boolean {
		return moment().isBefore(moment.unix(this.decodedToken.exp));
	}
}
