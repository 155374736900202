<div style="text-align:center;background-color:rgb(99, 99, 99);color:white;border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;box-shadow: 2px 3px 2px darkgray;">
  <h3>CLIENTS</h3>
</div>

<div style="margin-left:8px;margin-right:8px;overflow: auto;">
  <div class="table-header" style="margin-bottom:8px;float: right">
    <!-- <button mat-raised-button color="warn" mat-button (click)="removeSelectedRows()" style="margin-right:5px">
      {{ 'app.manageClients.removerows' | translate }}
      <mat-icon class="material-icons">
        delete
      </mat-icon>
    </button> -->
    <div class="table-header arrow-text" style="margin-bottom:12px;text-align: center;font-size: .9em;font-weight: bold;color:rgb(30, 10, 214)">
      <span>{{ 'app.manageClients.infoClientsGrid' | translate }}</span>
    </div>
      <button mat-raised-button color="primary" mat-button (click)="addRow()" [disabled]="editing">
      {{ 'app.manageClients.addrow' | translate }}
      <mat-icon class="material-icons">
        add
      </mat-icon>
    </button>
  </div>
  <mat-form-field appearance="standard">
    <mat-label>{{ 'app.misc.search' | translate }}</mat-label>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'app.misc.search' | translate }}" #input>
  </mat-form-field>
  <table mat-table matSort matSortActive="CLT_Name" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource" style="width:100%;">
    <ng-container [matColumnDef]="col.key" *ngFor="let col of COLUMNS_SCHEMA">
      <th mat-header-cell *matHeaderCellDef mat-sort-header  [disabled]="col.key == 'isEdit'">
        <div *ngIf="translateService.currentLang == 'fr'">{{ col.label }}</div>
        <div *ngIf="translateService.currentLang != 'fr'">{{ col.labelEn }}</div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
          <ng-container *ngSwitchCase="'isSelected'">
            <mat-checkbox
              (change)="element.isSelected = $event.checked"
            ></mat-checkbox>
          </ng-container>
            <div class="btn-edit" *ngSwitchCase="'isEdit'" style="white-space: nowrap;">
              <div *ngIf="!editing">
                <button mat-button class="button-edit" (click)="editClient(element)">
                <mat-icon class="material-icons" matTooltip="{{ 'app.importOrders.ttEdit' | translate }}" matTooltipPosition="above">
                  edit
                </mat-icon>
                </button>
                <button mat-button class="button-remove" (click)="removeRow(element)">
                  <mat-icon class="material-icons" matTooltip="{{ 'app.misc.deactivate' | translate }}" matTooltipPosition="above">
                    toggle_off
                  </mat-icon>
                </button>
            </div>
          </div>
          <span *ngSwitchCase="'date'">
            {{ element[col.key] | date: 'mediumDate' }}
          </span>
          <span *ngSwitchDefault>
            {{ element[col.key] }}
          </span>
        </div>
        <div [ngSwitch]="col.type" *ngIf="element.isEdit">
          <div *ngSwitchCase="'isSelected'"></div>
          <div class="btn-edit" *ngSwitchCase="'isEdit'" style="white-space: nowrap;">
            <button mat-button (click)="cancelEdit(element)">
              <mat-icon class="material-icons" matTooltip="{{ 'app.importOrders.ttCancel' | translate }}" matTooltipPosition="above">
                cancel
              </mat-icon>
            </button>
            <button mat-button (click)="element.isEdit = saveChanges(element)">
              <mat-icon class="material-icons" matTooltip="{{ 'app.importOrders.ttSave' | translate }}" matTooltipPosition="above">
                done
              </mat-icon>
            </button>
          </div>
          <!-- I keep it in case somewhere I would need it -->
          <!-- <mat-form-field class="form-input" *ngSwitchCase="'date'" appearance="fill">
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="element[col.key]"/>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field> -->
          <mat-form-field class="form-input" *ngSwitchDefault>
            <input [type]="col.type" matInput [(ngModel)]="element[col.key]" />
          </mat-form-field>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="highlight(row)" [ngClass]="{'highlightTableColor': selectedClientId == row.CLT_Id}"></tr>
  </table>
</div>

<!-- Overlay displayed while the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>
