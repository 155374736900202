import { HttpClient } from "@angular/common/http";
import { ValidationService } from "@app/core/services/validation.service";
import { Injectable } from '@angular/core';
import { Language } from "@app/core/enums/Language";
import { AddModifyAddressDetail } from "@app/shared/CSDAModels/AddModifyAddressDetail";
import { AddModifyAddressResult } from "@app/shared/CSDAModels/AddModifyAddressResult";
import { DeleteAddressDetail } from "@app/shared/CSDAModels/DeleteAddressDetail";
import { DeleteAddressResult } from "@app/shared/CSDAModels/DeleteAddressResult";
import { GetAddressBookDetail } from "@app/shared/CSDAModels/GetAddressBookDetail";
import { GetAddressBookResult } from "@app/shared/CSDAModels/GetAddressBookResult";
import { environment } from "@env";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import Swal from "sweetalert2";
import { ImportAddressBookDetail } from "@app/shared/CSDAModels/ImportAddressBookDetail";
import { AddressContainerResult } from "@app/shared/CSDAModels/AddressContainerResult";
import { GetImportTemplateDetail } from "@app/shared/CSDAModels/GetImportTemplateDetail";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class AddressBookService {

    /** Constructor of the QuotesService class */
    constructor(
      private httpClient: HttpClient,
      private validationService: ValidationService,
      private translateService: TranslateService,
      )
    {}

    //--------------------------
    // Get the list of addresses
    //--------------------------
    public getAddresses(): Observable<GetAddressBookResult> {

        const getAddressBookDetail: GetAddressBookDetail = {
            Language: Language.English,
            IsDevelopmentEnvironment: false
        };

        return this.httpClient.post<GetAddressBookResult>(environment.CSDAEndPoint + "/CSDA/GetAddressBook", getAddressBookDetail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message, "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }

    public deleteAddress(deleteAddressDetail: DeleteAddressDetail): Observable<DeleteAddressResult> {

        return this.httpClient.post<DeleteAddressResult>(environment.CSDAEndPoint + "/CSDA/DeleteAddress", deleteAddressDetail)
            .pipe(
                map((data) => {

                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message, "info");
                    }

                    return data !== undefined ? data : null;
                })
        );
    }

    public saveAddress(Detail: AddModifyAddressDetail): Observable<AddModifyAddressResult> {

        return this.httpClient.post<AddModifyAddressResult>(environment.CSDAEndPoint + "/CSDA/AddModifyAddress", Detail)
            .pipe(
                map((data) => {

                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message, "info");
                    }

                    return data !== undefined ? data : null;
                })
        );
    }
    //--------------------------
    // Get the list of addresses
    //--------------------------
    public import(DPA_URL_Parameter: string): Observable<AddressContainerResult> {

        const Detail: ImportAddressBookDetail = {
            Language: Language.English,
            IsDevelopmentEnvironment: false,
            ImportType: "External",
            Content: DPA_URL_Parameter
        };

        return this.httpClient.post<AddressContainerResult>(environment.CSDAEndPoint + "/CSDA/ImportAddressBook", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data, false);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message, "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }
  //------------------------------------------
  // Get the template used to upload addresses
  //------------------------------------------
  public getExcelAddressTemplate(): any {

    const Detail: GetImportTemplateDetail = {
      Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
      IsDevelopmentEnvironment: false
    };

    return this.httpClient.post(environment.CSDAEndPoint + "/CSDA/GetImportAddressTemplate", Detail, { observe: 'body', responseType: 'blob'})
        .pipe(
            map(data => {
                var validation = this.validationService.validate(data);

                if (validation.isValid === false) {
                    Swal.fire('', validation.message, "error");
                    return null;
                }

                if (validation.message !== "") {
                    Swal.fire('', validation.message, "info");
                }

                return data;
            })          
        );
  }    
}
