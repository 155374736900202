import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class MediaqueryService {
	public TabletAndDesktop = "(min-width: 768px)";
	public MobileAndTablet = "(max-width: 767px)";
	public Desktop = "(min-width: 960px)";
	public Tablet = "(min-width: 768px) and (max-width: 959px)";
	public Mobile = "(max-width: 480px)";
	public MobileLarge = "(max-width: 640px)";
	public MobileSmall = "(max-width: 320px)";

	constructor() {}
}
