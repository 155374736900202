<style>
 .content-wrapper {
    width: 100%;
    max-height: calc(90vh - 64px);
    overflow-y: auto;
  } 
</style>
<mat-dialog-content [formGroup]="ccForm" (ngSubmit)="continue($event)" autocomplete="off" class="content-wrapper">
    <section class="cc-wrapper">
      <div class="cc-box" style="margin-top:100px;width:98%">
        <div #ccBoxFlip class="cc-box--flip">
          <div class="cc-box__front">
            <div class="cc-box__logo">
              <p>{{getCardType(ccNumber.value) | uppercase}}</p>
            </div>
            <div class="cc-box__element">
              <label class="cc-form__previewlabel" for="cc-card-number-display">{{ "app.payment.creditCardNumber" | translate }}</label>
              <input class="cc-form__input cc-form__input--transparent cc-form__input--embosed" id="cc-card-number-display" aria-label="Payment card number" disabled="disabled" [value]="ccForm.get('cardNumber').value | paymentCardNumber">
            </div>
            <div class="cc-box__element">
              <label class="cc-form__previewlabel" for="cc-holder-display">{{ "app.payment.creditCardHolder" | translate }}</label>
              <input class="cc-form__input cc-form__input--transparent cc-form__input--embosed" id="cc-holder-display" aria-label="Card holder" disabled="disabled" [value]="ccForm.get('cardHolder').value | uppercase">
            </div>
            <div class="cc-box__element">
              <label class="cc-form__previewlabel" for="cc-valid-date-display">{{ "app.payment.creditCardExpirationDate" | translate }}</label>
              <input class="cc-form__input cc-form__input--left-align cc-form__input--transparent cc-form__input--embosed" id="cc-valid-date-display" aria-label="Card holder" disabled="disabled" [value]="ccForm.get('expirationMonth').value + '/' + ccForm.get('expirationYear').value | validThru">
            </div>
            <div class="cc-box__chip"></div>
          </div>
          <div class="cc-box__back">
            <div class="cc-box__strip">&nbsp;</div>
            <div class="cc-box__element">
              <input class="cc-form__input cc-form__input--cursive cc-form__input--right-align" id="cc-ccv-display" aria-label="CVV" disabled="disabled" [value]="'CVV: ' + ccForm.get('cvv').value" title="CVV">
            </div>
          </div>
        </div>
      </div>
      <div class="cc-form__wrapper--long">
        <label for="cc-number" class="cc-form__label cc-form__label--first">{{ "app.payment.creditCardNumber" | translate }}</label>
        <input #ccNumber class="cc-form__input" id="cc-number" aria-label="Card number" type='text' title='{{ "app.payment.creditCardNumber" | translate }}' maxlength="19" formControlName="cardNumber" (focus)="ccBoxFlip.classList.remove('hover')" (keydown)="validateInput($event)" autocomplete="off">
        <div class="cc-form__error" *ngIf="validateCCNum && ccForm.get('cardNumber').hasError('required')">
          {{ccNumMissing}}
        </div>
        <div class="cc-form__error" *ngIf="validateCCNum && ccForm.get('cardNumber').hasError('minlength')">
          {{ccNumTooShort}}
        </div>
        <div class="cc-form__error" *ngIf="validateCCNum && ccForm.get('cardNumber').hasError('maxlength')">
          {{ccNumTooLong}}
        </div>
        <div class="cc-form__error" *ngIf="validateCCNum && ccForm.get('cardNumber').hasError('checksum')">
          {{ccNumChecksumInvalid}}
        </div>
      </div>
      <div class="cc-form__wrapper--long">
        <label for="cc-holder-name" class="cc-form__label">{{ "app.payment.creditCardHolder" | translate }}</label>
        <input class="cc-form__input" id="cc-holder-name" aria-label="Card holder name" type="text" title="{{ 'app.payment.creditCardHolder' | translate }}" maxlength="22" formControlName="cardHolder" (focus)="ccBoxFlip.classList.remove('hover')" autocomplete="off">
        <div class="cc-form__error" *ngIf="validateCardHolder && ccForm.get('cardHolder').hasError('required')">
          {{cardHolderMissing}}
        </div>
        <div class="cc-form__error" *ngIf="validateCardHolder && ccForm.get('cardHolder').hasError('maxlength')">
          {{cardHolderTooLong}}
        </div>
      </div>
      <table style="width:100%;margin-top:8px">
        <tr>
          <td>
            <div class="">
              <label for="cc-expiration-month" class="cc-form__label">{{ "app.payment.creditCardExpirationMonth" | translate }}</label>
              <select id="cc-expiration-month" class="cc-form__select" aria-label="Expiration month" formControlName="expirationMonth">
                <option *ngFor="let month of months" value="{{month}}" (click)="ccBoxFlip.classList.remove('hover')">
                  {{month}}
                </option>
              </select>
            </div>
          </td>
          <td>
            <div class="">
              <label for="cc-expiration-year" class="cc-form__label">{{ "app.payment.creditCardExpirationYear" | translate }}</label>
              <select id="cc-expiration-year" class="cc-form__select" aria-label="Expiration year" formControlName="expirationYear">
                <option *ngFor="let year of years" value="{{year}}" (click)="ccBoxFlip.classList.remove('hover')">
                  {{year}}
                </option>
              </select>
            </div>
          </td>
          <td>
            <div style="width:120px">
              <label for="cc-ccv" class="cc-form__label">cvv</label>
              <input class="cc-form__input cc-form__input--short" id="cc-ccv" aria-label="CVV" type="text" title="CVV" minlength="3" maxlength="4" formControlName="cvv" (focus)="ccBoxFlip.classList.add('hover')" (keydown)="validateInput($event)" autocomplete="off">
            </div>
          </td>
        </tr>
      </table>
      <div class="cc-form__error" *ngIf="validateExpirationMonth && ccForm.get('expirationMonth').hasError('required')">
        {{expirationMonthMissing}}
      </div>
      <div class="cc-form__error" *ngIf="validateExpirationYear && ccForm.get('expirationYear').hasError('required')">
        {{expirationYearMissing}}
      </div>
      <div class="cc-form__error" *ngIf="validateCardExpiration && ccForm.hasError('expiration')">
        {{cardExpired}}
      </div>
      <div class="cc-form__error" *ngIf="validateCVV && ccForm.get('cvv').hasError('required')">
        {{cvvMissing}}
      </div>
      <div class="cc-form__error" *ngIf="validateCVV && ccForm.get('cvv').hasError('minlength')">
        {{cvvNumTooShort}}
      </div>
      <div class="cc-form__error" *ngIf="validateCVV && ccForm.get('cvv').hasError('maxlength')">
        {{cvvNumTooLong}}
      </div>
      <div style="display: inline-flex; width: 100%;margin-top:25px">
        <button type="submit" style="height: 40px; background-color: #5252ff; width: 48%; margin-right: 4%;" class="cc-form__button cc-form__button--ripple" aria-label="submit" (click)="continue($event)">{{ "app.payment.Submit" | translate }}</button>
        <button stopFormSubmission type="button" style="height: 40px; background-color: #e84c38; width: 48%;" class="cc-form__button cc-form__button--ripple" aria-label="cancel" (click)="onCancelClick($event)" >{{ "app.payment.Cancel" | translate }}</button>
      </div>
    </section>
</mat-dialog-content>