import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { DOCUMENT_TYPE } from "../enums/DOCUMENT_TYPE";
import { DutiesAndTaxesPaidBy } from "../enums/DutiesAndTaxesPaidBy";
import { INVOICE_TYPE } from "../enums/INVOICE_TYPE";
import { PackageType } from "../enums/PackageType";
import { ReasonForExport } from "../enums/ReasonForExport";
import { TypeOfCurrency } from "../enums/type-of-currency";
import { UnitOfMeasure } from "../enums/unit-of-measure";
import { VehicleTypes } from "../enums/Vehicles";

@Injectable({
    providedIn: 'root'
})
export class CustomDictionariesService {

    /** Constructor of the CustomDictionaries class */
    constructor(private translateService: TranslateService) { }

    /** Countries */
    countries = [
        {
            name: 'Canada',
            code: 'CA'
        },
        {
            name: 'USA',
            code: 'US'
        }
    ]

    /** States */
    states = [
        {
            "name": "Alabama",
            "code": "AL"
        },
        {
            "name": "Alaska",
            "code": "AK"
        },
        {
            "name": "American Samoa",
            "code": "AS"
        },
        {
            "name": "Arizona",
            "code": "AZ"
        },
        {
            "name": "Arkansas",
            "code": "AR"
        },
        {
            "name": "California",
            "code": "CA"
        },
        {
            "name": "Colorado",
            "code": "CO"
        },
        {
            "name": "Connecticut",
            "code": "CT"
        },
        {
            "name": "Delaware",
            "code": "DE"
        },
        {
            "name": "District Of Columbia",
            "code": "DC"
        },
        {
            "name": "Federated States Of Micronesia",
            "code": "FM"
        },
        {
            "name": "Florida",
            "code": "FL"
        },
        {
            "name": "Georgia",
            "code": "GA"
        },
        {
            "name": "Guam",
            "code": "GU"
        },
        {
            "name": "Hawaii",
            "code": "HI"
        },
        {
            "name": "Idaho",
            "code": "ID"
        },
        {
            "name": "Illinois",
            "code": "IL"
        },
        {
            "name": "Indiana",
            "code": "IN"
        },
        {
            "name": "Iowa",
            "code": "IA"
        },
        {
            "name": "Kansas",
            "code": "KS"
        },
        {
            "name": "Kentucky",
            "code": "KY"
        },
        {
            "name": "Louisiana",
            "code": "LA"
        },
        {
            "name": "Maine",
            "code": "ME"
        },
        {
            "name": "Marshall Islands",
            "code": "MH"
        },
        {
            "name": "Maryland",
            "code": "MD"
        },
        {
            "name": "Massachusetts",
            "code": "MA"
        },
        {
            "name": "Michigan",
            "code": "MI"
        },
        {
            "name": "Minnesota",
            "code": "MN"
        },
        {
            "name": "Mississippi",
            "code": "MS"
        },
        {
            "name": "Missouri",
            "code": "MO"
        },
        {
            "name": "Montana",
            "code": "MT"
        },
        {
            "name": "Nebraska",
            "code": "NE"
        },
        {
            "name": "Nevada",
            "code": "NV"
        },
        {
            "name": "New Hampshire",
            "code": "NH"
        },
        {
            "name": "New Jersey",
            "code": "NJ"
        },
        {
            "name": "New Mexico",
            "code": "NM"
        },
        {
            "name": "New York",
            "code": "NY"
        },
        {
            "name": "North Carolina",
            "code": "NC"
        },
        {
            "name": "North Dakota",
            "code": "ND"
        },
        {
            "name": "Northern Mariana Islands",
            "code": "MP"
        },
        {
            "name": "Ohio",
            "code": "OH"
        },
        {
            "name": "Oklahoma",
            "code": "OK"
        },
        {
            "name": "Oregon",
            "code": "OR"
        },
        {
            "name": "Palau",
            "code": "PW"
        },
        {
            "name": "Pennsylvania",
            "code": "PA"
        },
        {
            "name": "Puerto Rico",
            "code": "PR"
        },
        {
            "name": "Rhode Island",
            "code": "RI"
        },
        {
            "name": "South Carolina",
            "code": "SC"
        },
        {
            "name": "South Dakota",
            "code": "SD"
        },
        {
            "name": "Tennessee",
            "code": "TN"
        },
        {
            "name": "Texas",
            "code": "TX"
        },
        {
            "name": "Utah",
            "code": "UT"
        },
        {
            "name": "Vermont",
            "code": "VT"
        },
        {
            "name": "Virgin Islands",
            "code": "VI"
        },
        {
            "name": "Virginia",
            "code": "VA"
        },
        {
            "name": "Washington",
            "code": "WA"
        },
        {
            "name": "West Virginia",
            "code": "WV"
        },
        {
            "name": "Wisconsin",
            "code": "WI"
        },
        {
            "name": "Wyoming",
            "code": "WY"
        }
    ]

    /** Canadian provinces */
    provinces = [
        {
            name: 'Alberta',
            code: 'AB'
        },
        {
            name: 'British Columbia',
            code: 'BC'
        },
        {
            name: 'Manitoba',
            code: 'MB'
        },
        {
            name: 'New Brunswick',
            code: 'NB'
        },
        {
            name: 'Newfoundland',
            code: 'NL'
        },
        {
            name: 'Northwest Territories',
            code: 'NT'
        },
        {
            name: 'Nova Scotia',
            code: 'NS'
        },
        {
            name: 'Nunavut',
            code: 'NU'
        },
        {
            name: 'Ontario',
            code: 'ON'
        },
        {
            name: 'Prince Edward Island',
            code: 'PE'
        },
        {
            name: 'Quebec',
            code: 'QC'
        },
        {
            name: 'Saskatchewan',
            code: 'SK'
        },
        {
            name: 'Yukon',
            code: 'YT'
        }
    ]

    /** Currency types */
    currency = [
        {
            name: 'CAD',
            code: TypeOfCurrency.CAD
        },
        {
            name: 'USD',
            code: TypeOfCurrency.USD
        },
        {
            name: 'GBP',
            code: TypeOfCurrency.GBP
        },
        {
            name: 'EUR',
            code: TypeOfCurrency.EUR
        },
        {
            name: 'KWD',
            code: TypeOfCurrency.KWD
        },
        {
            name: 'BHD',
            code: TypeOfCurrency.BHD
        },
        {
            name: 'OMR',
            code: TypeOfCurrency.OMR
        },
        {
            name: 'JOD',
            code: TypeOfCurrency.JOD
        },
        {
            name: 'KYD',
            code: TypeOfCurrency.KYD
        },
        {
            name: 'CHF',
            code: TypeOfCurrency.CHF
        }
    ]

    /** Export raisons */
    exportReason = [
        {
            name: "app.shopifyOrders.details.dutiable.exportRaison.none",
            code: ReasonForExport.NONE
        },
        {
            name: "app.shopifyOrders.details.dutiable.exportRaison.permanentSold",
            code: ReasonForExport.PermanentSold
        },
        {
            name: "app.shopifyOrders.details.dutiable.exportRaison.temporary",
            code: ReasonForExport.Temporary
        },
        {
            name: "app.shopifyOrders.details.dutiable.exportRaison.gift",
            code: ReasonForExport.Gift
        },
        {
            name: "app.shopifyOrders.details.dutiable.exportRaison.repairwaranty",
            code: ReasonForExport.RepairWarranty
        },
        {
            name: "app.shopifyOrders.details.dutiable.exportRaison.commercialSample",
            code: ReasonForExport.CommercialSample
        },
        {
            name: "app.shopifyOrders.details.dutiable.exportRaison.personalEffects",
            code: ReasonForExport.PersonalEffects
        },
        {
            name: "app.shopifyOrders.details.dutiable.exportRaison.returnShipment",
            code: ReasonForExport.ReturnShipment
        },
        {
            name: "app.shopifyOrders.details.dutiable.exportRaison.other",
            code: ReasonForExport.Other
        },
    ]

    /** Debitors */
    debitor = [
        {
            name: "app.shopifyOrders.details.dutiable.receiver",
            code: DutiesAndTaxesPaidBy.RECEIVER
        },
        {
            name: "app.shopifyOrders.details.dutiable.shipper",
            code: DutiesAndTaxesPaidBy.SENDER
        }
    ]

    /** YES NO answer */
    yesNo = [
        {
            name: "app.misc.YES",
            code: true
        },
        {
            name: "app.misc.NO",
            code: false
        }
    ]

    /** Package types */
    packageTypes = [
        {
            name: "app.shopifyOrders.details.packageDetails.packageType.enveloppe",
            code: PackageType.ENVELOPPE
        },
        {
            name: "app.shopifyOrders.details.packageDetails.packageType.box",
            code: PackageType.BOX
        },
        {
            name: "app.shopifyOrders.details.packageDetails.packageType.palette",
            code: PackageType.PALETTE
        },
        {
            name: "app.shopifyOrders.details.packageDetails.packageType.carrierPack",
            code: PackageType.CARRIER_PACK
        },
        {
            name: "app.shopifyOrders.details.packageDetails.packageType.custom",
            code: PackageType.CUSTOM
        },
        {
            name: "app.shopifyOrders.details.packageDetails.packageType.bundle",
            code: PackageType.BUNDLE
        },
        {
            name: "app.shopifyOrders.details.packageDetails.packageType.woodencrate",
            code: PackageType.WOODEN_CRATE
        },
        {
            name: "app.shopifyOrders.details.packageDetails.packageType.specimen",
            code: PackageType.SPECIMEN
        },
        {
            name: "app.shopifyOrders.details.packageDetails.packageType.cylinder",
            code: PackageType.CYLINDER
        },
        {
            name: "app.shopifyOrders.details.packageDetails.packageType.cooler",
            code: PackageType.COOLER
        }
    ]

    /** Units of mesure */
    measures = [
        {
            name: "app.misc.metricMesure",
            code: UnitOfMeasure.Metric
        },
        {
            name: "app.misc.imperialMeasure",
            code: UnitOfMeasure.Imperial
        }
    ]

    /** Invoice Types */
    invoiceTypes = [
        {
            name: "app.misc.invoiceTypeSystem",
            code: INVOICE_TYPE.SYSTEM
        },
        {
            name: "app.misc.invoiceTypePersonalized",
            code: INVOICE_TYPE.PERSONALIZED
        }
    ]

    /** Document Types */
    documentTypes = [
        {
            name: "app.misc.documentTypesCI",
            code: DOCUMENT_TYPE.COMMERCIAL_INVOICE
        },
        {
            name: "app.misc.documentTypesO",
            code: DOCUMENT_TYPE.OTHER
        }
    ]

    /** Vehicle Types */
    vehicleTypes = [
        {
            name: "app.misc.car",
            code: VehicleTypes.AUTO_CAR
        },
        {
            name: "app.misc.van",
            code: VehicleTypes.CAMION_VAN
        },
        {
            name: "app.misc.biker",
            code: VehicleTypes.CYCLISTE_BIKER
        },
        {
            name: "app.misc.cube",
            code: VehicleTypes.CUBE_CUBE
        }
    ]
}