import { Inject } from "@angular/core";
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MAT_DATE_FORMATS } from '@angular/material/core';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};


@Component({
  selector: 'app-create-pickup-dialog',
  templateUrl: './create-pickup-dialog.component.html',
  styleUrls: ['./create-pickup-dialog.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class CreatePickupDialogComponent implements OnInit {

  /** Variable for the form group */
  form: FormGroup;

  /** Variables with default values for the creation of the pickup */
  businessHoursFrom: string = "09:00";
  businessHoursTo: string = "17:00";
  customerReference: string = "";
  instructions: string = "";
  readyByTime: string = "09:00";
  isResidential: string = "false";

  /** Variables for the date picker */
  pickupDate: Date = new Date(new Date().getTime());
  minDate: Date = new Date(new Date().getTime());

  /** Constructor of the CreatePickupDialogComponent class */
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CreatePickupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) {
  }

  /** On init we specify the forms in the form group */
  ngOnInit(): void {
    this.form = this.fb.group({
      businessHoursFrom: [this.businessHoursFrom, [Validators.required]],
      businessHoursTo: [this.businessHoursTo, [Validators.required]],
      readyByTime: [this.readyByTime, [Validators.required]],
      pickupDate: [this.pickupDate, [Validators.required]],
      customerReference: [this.data, []],
      instructions: [this.instructions, []],
      isResidential: [this.isResidential, []],
    });
  }
  convertToTimeFormat(input: string): string {
    if (input.includes(':')) {
        return input;
    } else {
        return input.slice(0, 2) + ':' + input.slice(2);
    }
  }
  /** Send the dialog box entry to the caller and close the dialog box */
  save() {
    if (this.form.valid) {
      // Get the current values
      let readyByTime = this.form.get('readyByTime').value;
      let businessHoursFrom = this.form.get('businessHoursFrom').value;
      let businessHoursTo = this.form.get('businessHoursTo').value;

      // Convert the values to the desired format
      let formattedReadyByTime = this.convertToTimeFormat(readyByTime);
      let formattedBusinessHoursFrom = this.convertToTimeFormat(businessHoursFrom);
      let formattedBusinessHoursTo = this.convertToTimeFormat(businessHoursTo);

      // Update the values in the form
      this.form.patchValue({readyByTime: formattedReadyByTime});
      this.form.patchValue({businessHoursFrom: formattedBusinessHoursFrom});
      this.form.patchValue({businessHoursTo: formattedBusinessHoursTo});

      this.dialogRef.close(this.form.value);
    }
  }

  /** Close the dialog box */
  close() {
    // return false when the user close the dialog box without saving
    this.dialogRef.close(false);
  }
}
