import { map } from "rxjs/operators";
import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "@env";
import { TranslateService } from "@ngx-translate/core";
import { ValidationService } from "@app/core/services/validation.service";
import { Language } from "@app/core/enums/Language";
import { ImportDetail } from "@app/shared/CSDAModels/ImportDetail";
import { ImportResult } from "@app/shared/CSDAModels/ImportResult";
import { Observable, ReplaySubject } from "rxjs";
import Swal from "sweetalert2";
import { LanguageService } from "@app/core/services/language.service";

@Component({
  selector: 'app-upload-orders',
  templateUrl: './upload-orders.component.html',
  styleUrls: ['./upload-orders.component.scss']
})

export class UploadOrdersComponent {
  importOrdersDetail: ImportDetail;
  importOrdersResult: ImportResult;
  uploadProgress = 0;
  fileName = undefined;
  isProcessing: boolean = false;

  constructor(
		private httpClient: HttpClient,
    private translateService: TranslateService,
		private validationService: ValidationService,
    private languageService: LanguageService,

  ) {  }

  //--------------------------
  // Selected file by the user
  //--------------------------
  selectFile(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.fileName = event.target.files[0].name;
      this.convertFile(event.target.files[0]).subscribe(base64 => {
        this.importOrdersDetail = {
          IsDevelopmentEnvironment: false,
          Language: this.languageService.GetCurrentLanguage(),
          File: base64
        };

        //------------------------------------------------------------------------
        // Solution for the positionning in the didOpen event was given by ChatGPT
        // because the normal solution wasn't working.
        //------------------------------------------------------------------------
        Swal.fire({
          width:200,
          toast: true,
          icon: 'success',
          title: this.translateService.instant("app.importOrders.readyToSend"),
          showConfirmButton: true,
          target: '#uploadToastTarget',
          customClass: {
            container: 'position-absolute'
          },
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            const toastContainer = document.querySelector(".swal2-container") as HTMLElement;;
            if(toastContainer){
              const target = document.getElementById("uploadToastTarget");
              if(target) {
                const targetRect = target.getBoundingClientRect();
                toastContainer.style.top = `${targetRect.top}px`;
                toastContainer.style.left = `${targetRect.left}px`;
              }
            }            
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        });      
      })
    } else {
      this.uploadProgress = 0;
      this.fileName = undefined;
    }

    event.target.value = "";
  }

  //-------------------------------------------------------------------
  // https://upmostly.com/angular/converting-files-to-base64-in-angular
  //-------------------------------------------------------------------
  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }
  
  //----------------------------------------------------------
  // Action of uploading the file to the server for processing
  //----------------------------------------------------------
  upload() {
    this.uploadProgress = 0;
    this.isProcessing = true;
    this.httpClient.post<ImportResult>(environment.CSDAEndPoint + "/CSDA/UploadOrders", this.importOrdersDetail)
			.pipe(
				map(data => {
					var validation = this.validationService.validate(data);

					if (validation.isValid === false) {
						Swal.fire('', validation.message, "error");
						return null;
					}

					if (validation.message !== "") {
						Swal.fire('', validation.message, "info");
					}
          else {
            Swal.fire('', this.translateService.instant("app.importOrders.fileUploaded"), "info");
            this.uploadProgress = 0;
            this.fileName = undefined;
          }

          this.isProcessing = false;
				})).subscribe((event: any) => {
            this.isProcessing = false;
            this.fileName = undefined;
        });
  }
}
