import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { spCSDAManageDivisionGetListResult } from "@app/shared/CSDAModels/ManageClientModels/Division/spCSDAManageDivisionGetListResult"
import { ManageClientsService } from "../_Services/manage-clients.service";
import Swal from "sweetalert2";
import { Countries } from "@app/shared/CSDAModels/Countries";
import { Currencies } from "@app/shared/CSDAModels/Currencies";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

const matTooltipDefaultOptions: MatTooltipDefaultOptions = {
  disableTooltipInteractivity: true,
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0
};

@Component({
  selector: 'Divisions',
  templateUrl: './Divisions.component.html',
  styleUrls: ['./Divisions.component.scss'],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipDefaultOptions }]
})

export class DivisionsComponent implements OnInit, AfterViewInit  {
  private subscription: Subscription;
  //---------------------------------------------------
  // Define the fields of the model which are displayed
  //---------------------------------------------------
  COLUMNS_SCHEMA = this.getSchema();
  displayedColumns: string[] = this.COLUMNS_SCHEMA.map((col) => col.key);
  dataSource = new MatTableDataSource([]);
  isProcessing = false;
  selectedDivisionId = -1;
  CLT_Id = -1;
  originalDivision: spCSDAManageDivisionGetListResult;
  editing = false;
  countriesList: Countries[];
  currenciesList: Currencies[];

  constructor(
    private translateService: TranslateService,
    private ManageClientsService: ManageClientsService,
    private router: Router
  ) { }
  
  @Output('selectedDivision') selectedDivision: EventEmitter<number> = new  EventEmitter<number>();
  @ViewChild(MatSort) sort: MatSort;
  
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.countriesList = JSON.parse(sessionStorage.getItem("Countries")) as Countries[];
    this.currenciesList = JSON.parse(sessionStorage.getItem("Currencies")) as Currencies[];
   
    this.subscription = this.ManageClientsService.clientDeactivated.subscribe(() => {
      this.getDivisions(null);
      this.ManageClientsService.deactivateDivision();
    });
  }

  getSchema() {
    return [
      // {
      //   key: 'isSelected',
      //   type: 'isSelected',
      //   label: '',
      //   visible: true
      // },
      {
        key: 'Div_Broker',
        type: 'text',
        label: 'Courtier',
        labelEn: 'Broker'
      },
      {
        key: 'Div_TaxID',
        type: 'text',
        label: 'Taxe ID',
        labelEn: 'Tax ID'
      },
      {
        key: 'Div_Currency',
        type: 'listCurrencies',
        label: 'Devise',
        labelEn: 'Currency'
      },
      {
        key: 'Div_ExportReason',
        type: 'text',
        label: 'Raison',
        labelEn: 'Reason'
      },
      {
        key: 'Div_OriginCountryCode',
        type: 'listCountries',
        label: 'Pays',
        labelEn: 'Country'
      },
      {
        key: 'Div_Description',
        type: 'text',
        label: 'Description',
        labelEn: 'Description'
      },
      {
        key: 'Div_AllowProdEnv',
        type: 'checkboxProd',
        label: 'Production',
        labelEn: 'Production'
      },
      {
        key: 'Div_IsTemplate',
        type: 'checkboxTemplate',
        label: 'Modèle',
        labelEn: 'Template'
      },
      {
        key: 'Div_Modificator',
        type: 'textModificator',
        label: 'Modificateur',
        labelEn: 'Modificator'
      },
      {
        key: 'isEdit',
        type: 'isEdit',
        label: ''
      }
    ];
  }

  getDivisions(id: any) {
    this.selectedDivisionId = -1;
    const ClientId = id ? id : this.CLT_Id;
    this.CLT_Id = ClientId;
    this.isProcessing = true;
    this.editing = false;
    this.selectedDivisionId = -1;

    this.ManageClientsService.getDivisions(ClientId).subscribe(Result => {
      this.dataSource = new MatTableDataSource(Result.DivisionsList);
      this.dataSource.sort = this.sort;
      this.isProcessing = false;
    })

  }

  addRow() {
    const newRow = {
      Div_Id: -1,
      Div_CLT_Ref: this.CLT_Id,
      Div_CSAPI_Token: '',
      Div_Broker: '',
      Div_TaxID: '',
      Div_Currency: 'CAD',
      Div_ExportReason: '',
      Div_OriginCountryCode: 'CA',
      Div_Description: '',
      Div_AllowProdEnv: false,
      Div_Modificator: 'ModificatorGlobexAddRate',      
      isEdit: true,
    };

    this.editing = true;
    this.dataSource.data = [newRow, ...this.dataSource.data];
  }

  removeRow(element) {
    Swal.fire({
      title: 'Confirmation',
      icon: "question",
      showDenyButton: true,
      denyButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes"),

    }).then((result) => {
      if (result.isConfirmed) {
        this.isProcessing = true;

        this.ManageClientsService.deleteDivision(element).subscribe(Result => {
          this.isProcessing = false;
          this.dataSource.data = this.dataSource.data.filter((u) => u.Div_Id != element.Div_Id);
          this.ManageClientsService.deactivateDivision();
        });
      }
    });    
  }

  removeSelectedRows() {
    Swal.fire({
      title: 'Confirmation',
      icon: "question",
      showDenyButton: true,
      denyButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes"),

    }).then((result) => {
      if (result.isConfirmed) {
        this.dataSource.data = this.dataSource.data.filter((u: any) => !u.isSelected);
      }
    });    
  }

  saveChanges(element: any): boolean {
    this.isProcessing = true;

    const index = this.dataSource.data.findIndex(object => {
      return object.Div_Id === element.Div_Id;
    });

    this.ManageClientsService.saveDivision(element).subscribe(Result => {
      this.dataSource.data[index].Div_Id = Result.Div_Id;
      this.isProcessing = false;
    });
    
    this.editing = false;
    return false;
  }

  editDivision(element: any) {
    this.editing = true;
    element.isEdit = true;
    this.originalDivision = JSON.parse(JSON.stringify(element));
  }
  
  cancelEdit(element: any) {
    if(element.Div_Id == -1)
      this.dataSource.data = this.dataSource.data.filter((u) => u.Div_Id != element.Div_Id);
    else {
      element.Div_Broker              = this.originalDivision.Div_Broker;
      element.Div_TaxID               = this.originalDivision.Div_TaxID;
      element.Div_Currency            = this.originalDivision.Div_Currency;
      element.Div_ExportReason        = this.originalDivision.Div_ExportReason;
      element.Div_OriginCountryCode   = this.originalDivision.Div_OriginCountryCode;
      element.Div_CLT_Ref             = this.originalDivision.Div_CLT_Ref;
      element.Div_CSAPI_Token         = this.originalDivision.Div_CSAPI_Token;
      element.Div_Description         = this.originalDivision.Div_Description;
      element.Div_AllowProdEnv        = this.originalDivision.Div_AllowProdEnv;
      element.Div_Modificator         = this.originalDivision.Div_Modificator; 
    }

    this.editing = false;
    element.isEdit = false;
  }


  highlight(row: { Div_Id: number; }){

    if(!this.editing) {
      if(this.selectedDivisionId != row.Div_Id)
        this.selectedDivision.emit(row.Div_Id);
        
      this.selectedDivisionId = row.Div_Id;
    }
  }  
}
