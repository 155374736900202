import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Language } from "../enums/Language";

@Injectable({
	providedIn: 'root'
})
export class LanguageService {

	/** Constructor of the LanguageService class */
	constructor(private translateService: TranslateService) {}

	/** Get the current language
	  * @returns The enum of the current language
	  */
	public GetCurrentLanguage(): Language {
		return this.translateService.currentLang === "fr" ? Language.French : Language.English;
	}
}
