<div class="loading-screen" *ngIf="loading">
  <div class="loading-screen__content">

    <div class="loading-screen__content__content">
      <span class="material-icons">
        local_shipping
      </span>
    </div>
    <div class="loading-screen__content__text">
      <div class="loading-screen__content__text__content">Loading</div>
      <div class="loading-screen__content__text__icon">
        <mat-progress-bar mode="buffer"></mat-progress-bar>
      </div>
    </div>
  </div>
</div>