import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-processing-overlay',
  templateUrl: './processing-overlay.component.html',
  styleUrls: ['./processing-overlay.component.scss'],
})
export class ProcessingOverlayComponent {

  @Input() message = "";

  constructor() { }
}
