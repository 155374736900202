import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { EditProductListComponent } from "./pages/edit-product-list/edit-product-list.component";
import { ProductRoutingModule } from "./product.routing";
import { SharedModule } from "@app/shared/shared.module";

@NgModule({
	imports: [ ProductRoutingModule, CommonModule, SharedModule ],
	declarations: [ EditProductListComponent]
})
export class ProductModule {}
