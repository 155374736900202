import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShopifyOrdersComponent } from './pages/shopify-orders.component';
import { CreateShipmentStepperComponent } from "../create-new-shipment/pages/create-shipment-stepper/create-shipment-stepper.component";

const routes: Routes = [
  { path: "", component: ShopifyOrdersComponent },
  { path: "orders", component: ShopifyOrdersComponent },
  { path: ":orderNumber", component: CreateShipmentStepperComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShopifyOrdersRoutingModule { }
