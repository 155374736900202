<div class="content-card" style="max-width: 96.5%;" *ngIf="Div_Id != -1">
    <mat-card>
        <mat-card-content>
            <div class="form">
                <mat-form-field appearance="outline">
                    <mat-label class="customLabel">{{ 'app.manageClients.accountNumber' | translate}}</mat-label>
                    <input matInput type="number" [value]="model.DPA_AccountNo" (blur)="accountNumberChanged($event.target.value)">
                </mat-form-field>
                <span style="margin-left: 5px;color:red" *ngIf="noIntegrationContact">{{
                    'app.manageClients.contactIDPlaceHolder' | translate}}</span>
                <div class="form__row">
                    <mat-form-field appearance="outline">
                        <mat-label class="customLabel">{{ 'app.manageClients.contactID' | translate}}</mat-label>
                        <mat-select [(ngModel)]="model.IDV_ContactID" >
                            <mat-option *ngFor="let contact of clientContacts" [value]="contact.ContactId">{{
                                contact.Name + ' (' + contact.ContactId + ')' }}</mat-option>
                        </mat-select>
                        <!-- <input matInput [value]="model?.IDV_ContactID || ''" placeholder="{{'app.manageClients.contactIDPlaceHolder' | translate}}" (input)="model = model || {}; model.IDV_ContactID = $event.target.value"> -->
                    </mat-form-field>
                    <button mat-raised-button color="primary" mat-button style="max-height: 53px;margin-left:5px"
                        (click)="refrechContacts()">
                        {{ 'app.misc.refresh' | translate}}
                        <mat-icon class="material-icons">
                            refresh
                        </mat-icon>
                    </button>
                </div>
                <mat-form-field appearance="outline">
                    <mat-label class="customLabel">{{ 'app.manageClients.documentReturnType' | translate}}</mat-label>
                    <mat-select [(ngModel)]="model.DPA_DocumentReturnType" [value]="model.DPA_DocumentReturnType">
                        <mat-option *ngFor="let type of documentReturnTypes"
                            [value]="type.name">{{type.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label class="customLabel">{{ 'app.manageClients.paymentMethod' | translate}}</mat-label>
                    <mat-select [(ngModel)]="model.DPA_PaymentMethod">
                        <mat-option *ngFor="let type of paymentMethods"
                            [value]="type.value">{{type.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                
                <div style="display: flex; align-items: center;">
                    <mat-form-field appearance="outline" style="flex: 1;">
                        <mat-label class="customLabel">{{ 'app.manageClients.extImportURL' | translate}}</mat-label>
                        <input matInput [disabled]="true" [(ngModel)]="model.DPA_Ext_Import_URL"  [value]="model.DPA_Ext_Import_URL">
                    </mat-form-field>
                    <button mat-raised-button color="primary" class="icon-button" style="height:53px;margin-left: 12px;margin-top:-20px" (click)="importClientAddresses()">
                        <div class="aligned-with-icon">
                            <span>{{ "app.addressBook.import" | translate }}</span>
                            <img src="assets//images/Import.png" width="24" height="24" style="margin-left:10px" />
                        </div>            
                    </button>
                </div>
                                                                                                                                                                                        
                <mat-checkbox style="margin-left: 5px;" [(ngModel)]="model.MDP_AllowGlobexPickup" [checked]="model.MDP_AllowGlobexPickup">
                    <mat-label>{{ 'app.manageClients.allowGlobexPickup' | translate}}</mat-label>
                </mat-checkbox>
                <mat-checkbox style="margin-left: 5px;" [(ngModel)]="model.MDP_AllowGlobexSubCarrier" [checked]="model.MDP_AllowGlobexSubCarrier">
                    <mat-label>{{ 'app.manageClients.allowGlobexSubCarrier' | translate}}</mat-label>
                </mat-checkbox>
                <mat-checkbox style="margin-left: 5px;" [(ngModel)]="model.DPA_ForceReference" [checked]="model.DPA_ForceReference">
                    <mat-label>{{ 'app.manageClients.forceReference' | translate}}</mat-label>
                </mat-checkbox>
                <mat-checkbox style="margin-left: 5px;" [(ngModel)]="model.DPA_UseVolumeWeight" [checked]="model.UseVolumeWeight">
                    <mat-label>{{ 'app.manageClients.useVolumeWeight' | translate}}</mat-label>
                </mat-checkbox>
                <div class="row">
                    <div style="margin-top:10px">
                        <button mat-raised-button color="primary" mat-button (click)="saveChanges(model)"
                            style="margin-right:8px">
                            {{ 'app.misc.save' | translate}}
                            <mat-icon class="material-icons">
                                save
                            </mat-icon>
                        </button>
                        <button mat-raised-button color="warn" mat-button (click)="cancelChanges(model)">
                            {{ 'app.misc.cancel' | translate}}
                            <mat-icon class="material-icons">
                                cancel
                            </mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<!-- Overlay displayed while the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>