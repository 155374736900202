import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "./core/services/storage.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent {
	title = "cubiShippingDashboard";
	constructor(public translate: TranslateService,
		public storageService: StorageService) {
		translate.addLangs(["en", "fr"]);
		const lang = this.storageService.getLanguage()
		lang ? this.translate.use(lang) : translate.setDefaultLang("en");
	}
}
