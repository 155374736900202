import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomPackagesRoutingModule } from './custom-packages-routing.module';
import { SharedModule } from "@app/shared/shared.module";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    CustomPackagesRoutingModule
  ]
})
export class CustomPackagesModule { }
