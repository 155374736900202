import { Injectable } from '@angular/core';
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class ActionService {

    /** Constructor of the LanguageService class */
    constructor(
        private translateService: TranslateService,
    ) { }

    /** See shipment waybill in a new Tab 
    * @param serviceLabel - The service label name
    */
    seeWaybill(serviceLabel: string): void {
        if (serviceLabel) {
            const modal = document.createElement('div');
            modal.style.position = 'fixed';
            modal.style.top = '0';
            modal.style.left = '0';
            modal.style.width = '100%';
            modal.style.height = '100%';
            modal.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
            modal.style.display = 'flex';
            modal.style.justifyContent = 'center';
            modal.style.alignItems = 'center';
            modal.style.zIndex = '1000';
    
            const contentContainer = document.createElement('div');
            contentContainer.style.position = 'relative';
            contentContainer.style.width = '80%';
            contentContainer.style.height = '80%';
            contentContainer.style.backgroundColor = 'white';
            contentContainer.style.display = 'flex';
            contentContainer.style.flexDirection = 'column';
    
            const iframe = document.createElement('iframe');
            iframe.style.flex = '1';
            iframe.src = 'data:application/pdf;base64,' + serviceLabel;
    
            const closeButton = document.createElement('button');
            closeButton.innerText = 'Close';
            closeButton.style.alignSelf = 'flex-end';
            closeButton.style.margin = '10px';
            closeButton.style.padding = '10px 20px';
            closeButton.style.backgroundColor = '#f44336';
            closeButton.style.color = 'white';
            closeButton.style.border = 'none';
            closeButton.style.borderRadius = '5px';
            closeButton.style.cursor = 'pointer';
    
            closeButton.addEventListener('click', () => {
                document.body.removeChild(modal);
            });
    
            contentContainer.appendChild(iframe);
            contentContainer.appendChild(closeButton);
            modal.appendChild(contentContainer);
            document.body.appendChild(modal);
    
            modal.addEventListener('click', (event) => {
                if (event.target === modal) {
                    document.body.removeChild(modal);
                }
            });
        } else {
            let message = "";
    
            if (this.translateService.currentLang === "fr") {
                message = "Une cueillette doit être demandée pour obtenir l'étiquette d'expédition";
            } else {
                message = "A pickup must be requested to obtain a waybill";
            }
    
            Swal.fire('', message, "info");
        }
    }
}    