import { Component } from "@angular/core";
import { BaseComponentDirective } from "@app/core/base.component";
import { LoaderService } from "@app/core/services/loader.service";
import { delay } from "rxjs/internal/operators";

@Component({
	selector: "app-loading-screen",
	templateUrl: "./loading-screen.component.html",
	styleUrls: [ "./loading-screen.component.scss" ]
})
export class LoadingScreenComponent extends BaseComponentDirective {
	loading = false;

	constructor(private loadingScreenService: LoaderService) {
		super();
	}

	onInit(): void {
		super.subscribe(
			this.loadingScreenService.loadingScreen.pipe(delay(0)).subscribe((state) => {
				this.loading = state;
			})
		);
	}

	onDestroy(): void {}
}
