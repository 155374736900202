export enum TypeOfCurrency {
    NONE = 0,
    USD = 1,
    CAD = 2,
    KWD = 3,
    BHD = 4,
    OMR = 5,
    JOD = 6,
    GBP = 7,
    KYD = 8,
    EUR = 9,
    CHF = 10
}