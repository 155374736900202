import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DocumentReturnType } from "@app/core/enums/DocumentReturnType";
import { ClientContacts } from "@app/shared/CSDAModels/ClientContacts";
import { spCSDAManageParametersGetResult } from "@app/shared/CSDAModels/spCSDAManageParametersGetResult";
import { TranslateService } from "@ngx-translate/core";
import { ManageClientsService } from "../_Services/manage-clients.service";
import { AddressContainerResult } from "@app/shared/CSDAModels/AddressContainerResult";
import { AddressBookService } from "../../address-book/services/address-book.service";
import Swal from "sweetalert2";

@Component({
  selector: 'Parameters',
  templateUrl: './Parameters.component.html',
  styleUrls: ['./Parameters.component.scss']
})

export class ParametersComponent implements OnInit {
  selectedDocumentReturnType = DocumentReturnType.PDF;
  documentReturnTypes = [
    { value: DocumentReturnType.PDF, name: 'PDF' },
    { value: DocumentReturnType.PDF_LETTER, name: 'PDF_LETTER' },
    { value: DocumentReturnType.PDF_4X6, name: 'PDF_4X6' },
    { value: DocumentReturnType.PDF_4X8, name: 'PDF_4X8' }
  ];

  isProcessing = false;
  Div_Id = -1;
  originalParameters: spCSDAManageParametersGetResult;
  model: spCSDAManageParametersGetResult;
  noIntegrationContact: boolean = false;
  clientContacts: ClientContacts[];
  paymentMethods: { value: string, name: string }[];

  constructor(
    private translateService: TranslateService,
    private ManageClientsService: ManageClientsService,
    private addressBookService: AddressBookService,
  ) { }

  ngOnInit(): void {
    if(this.translateService.currentLang === "fr") {
      this.paymentMethods = [
        { value: "CreditCard", name: "Carte de crédit"},
        { value: "OpenAccount", name: "Compte ouvert"}
      ]
    }
    else {
      this.paymentMethods = [
        { value: "CreditCard", name: "Credit card"},
        { value: "OpenAccount", name: "Open account"}
      ]    }
  }

  getParameters(id: any) {
    if (id != -1) {
      const DivId = id != -1 ? id : this.Div_Id;
      this.Div_Id = DivId;
      this.isProcessing = true;

      this.ManageClientsService.getParameters(DivId).subscribe(Result => {
        this.originalParameters = JSON.parse(JSON.stringify(Result.Parameters));
        this.model = Result.Parameters;

        if (Result.ClientContacts) {
          this.clientContacts = Result.ClientContacts;
          this.noIntegrationContact = this.clientContacts.find(cc => "INTEGRATION CUBI, CUBI USER, CUBI API".includes(cc.Name.toUpperCase())) == null
        } else {
          this.noIntegrationContact = true;
        }

        this.isProcessing = false;
      });
    }
    else {
      this.Div_Id = -1;
      this.clientContacts = null;
      this.model = <spCSDAManageParametersGetResult>{};
      this.isProcessing = false;
    }
  }

  saveChanges(element: any): boolean {
    this.isProcessing = true;

    this.ManageClientsService.saveParameters(this.Div_Id, element).subscribe(Result => {
      this.originalParameters = JSON.parse(JSON.stringify(this.model));
      this.isProcessing = false;
    });

    return false;
  }

  cancelChanges(element: any) {
    this.model = JSON.parse(JSON.stringify(this.originalParameters));
    this.refrechContacts();
    element.isEdit = false;
  }

  refrechContacts() {
    if (this.model.DPA_AccountNo) {
      this.isProcessing = true;
      this.ManageClientsService.getClientContacts(this.model.DPA_AccountNo).subscribe(Result => {
        this.clientContacts = Result.ClientContacts;
        this.noIntegrationContact = this.clientContacts.find(cc => "INTEGRATION CUBI, CUBI USER, CUBI API".includes(cc.Name.toUpperCase())) == null
        this.isProcessing = false;
      });
    } else {
      this.clientContacts = null;
      this.noIntegrationContact = true;
      this.isProcessing = false;
    }
  }

  accountNumberChanged(AccountNumber: number) {
    if (this.model.DPA_AccountNo != AccountNumber) {
      this.model.DPA_AccountNo = AccountNumber;
      this.refrechContacts();
    }
  }
	
  importClientAddresses() {
    Swal.fire({
      title: this.translateService.instant("app.misc.areYouSure"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes")
    }).then((result) => {
      if (result.isConfirmed) {
        this.isProcessing = true;

        this.addressBookService.import('{ "AccountNo": "' + this.model.DPA_AccountNo + '" }').subscribe((data: AddressContainerResult) => {
          this.isProcessing = false;
        });	
      }
    })    
	
	}  
}
