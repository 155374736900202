import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";

/*Class used to return the result of the validation */
class ValidationResult {
    isValid : boolean;
    message : string;
}

@Injectable({
    providedIn: 'root'
})
export class ValidationService {

    /** Constructor of the ValidationService class */
    constructor(private router: Router, private translateService: TranslateService) { }

    /** Validate data
     * @param data - The dada to validate
     * @return ValidationResult - boolean that indicate if the validation is a success and a message to display if necessary
     */
    public validate(data: any, AddCode:boolean = true): ValidationResult {
        
        /** If the data is valid and there is no message */
        var result : ValidationResult = {
            isValid: true,
            message: ""
        }

        /** Construct the message to return if necessary and set the validity of the data */
        if (data.IsValid && (data.Messages !== null && data.Messages !== undefined)) {

            /** The data is valid but there is a message to return that must be displayed to the user */
            result.isValid = true;

            data.Messages.forEach((element: { Code: string; Description: string; }) => {
                if(AddCode)
                    result.message += element.Code + (element.Code == "" ? "" : " - ") + element.Description + " <br> ";
                else
                    result.message += element.Description + " <br> ";
            });
        }
        else if (!data.IsValid && (data.Exceptions !== null && data.Exceptions !== undefined)) {
            /** The data is invalid and there is an error, so we return our custom message */
            result.isValid = false;

            //result.message = this.translateService.instant("app.misc.exceptionMessage");
            data.Exceptions.forEach((element: { Description: string; }) => {
                if(element.Description[0].startsWith("You're not authenticated")) {
                    this.router.navigate(["/account/login"]);
                    //throw new Error('Navigation stopped.'); Si Pat ne veut pas de message, activer cette ligne.
                }

                result.message += element.Description + " <br> ";
            });
        }

        return  result;
    }
}
