<!-- Shipment details -->
<div class="shipment-details" *ngIf="$shipment | async as shipment">

  <!-- <div *ngIf="isVoided | async">
    <app-box-message [alertType]="alertTypeEnum.info">
      {{ "app.shopifyOrders.shipmentDetails.shipmentDetailsCard.shipmentVoided" | translate }}
    </app-box-message>
  </div> -->

  <div>
    <app-box-message [alertType]="alertTypeEnum.Info">
      {{ "app.shopifyOrders.shipmentDetails.shipmentDetailsCard.waybillSuccesfullyCreated" | translate }}
    </app-box-message>
    <!-- Shipment details -->
    <div class="shipment-details__row">
      <mat-card>
        <!-- Card title -->
        <mat-card-header>
          <mat-icon mat-card-avatar>
            local_shipping
          </mat-icon>
          <mat-card-title>
            {{ "app.shopifyOrders.shipmentDetails.shipmentDetailsCard.title" | translate }}
          </mat-card-title>
        </mat-card-header>
        <!-- Card content -->
        <mat-card-content class="card-content">
          <!-- Carrier -->
          <div class="card-content__row">
            <img *ngIf="shipment.Order.ORD_Carrier.toUpperCase() === 'DHL'" class="dhl-icon"
              src="../../../../../assets/images/dhl.png" alt="DHL" />
            <img *ngIf="shipment.Order.ORD_Carrier.toUpperCase() === 'FEDEX'" class="fedex-icon"
              src="../../../../../assets/images/fedex.png" alt="FedEx" />
            <img *ngIf="shipment.Order.ORD_Carrier.toUpperCase() === 'PUROLATOR'" class="purolator-icon"
              src="../../../../../assets/images/purolator.png" alt="Purolator" />
            <img *ngIf="shipment.Order.ORD_Carrier.toUpperCase() === 'GLOBEX'" class="globex-icon"
              src="../../../../../assets/images/globex-logo.png" alt="Purolator" />
            <img *ngIf="shipment.Order.ORD_Carrier.toUpperCase() === 'LOOMIS'" class="loomis-icon"
              src="../../../../../assets/images/loomis-logo.png" alt="Loomis" />
          </div>
          <!-- Service Type -->
          <div class="card-content__row">
            <div class="card-content__row--title">
              {{ "app.shopifyOrders.shipmentDetails.shipmentDetailsCard.service" | translate }}
            </div>
            <div class="card-content__row--value">
              {{shipment.Order.ORD_ServiceTypeDescription}}
            </div>
          </div>
          <!-- Tracking number -->
          <div class="card-content__row">
            <div class="card-content__row--title">
              {{ "app.shopifyOrders.shipmentDetails.shipmentDetailsCard.trackingNumber" | translate }}
            </div>
            <div class="card-content__row--value">
              {{shipment.Order.ORD_TrackingNumber}}
            </div>
          </div>
          <!-- Shipment date -->
          <div class="card-content__row">
            <div class="card-content__row--title">
              {{ "app.shopifyOrders.shipmentDetails.shipmentDetailsCard.shipmentDate" | translate }}
            </div>
            <div class="card-content__row--value">
              {{shipment.Order.ORD_ShipmentDate | date: 'yyyy-MM-dd'}}
            </div>
          </div>
          <!-- User ID -->
          <div class="card-content__row">
            <div class="card-content__row--title">
              {{ "app.shopifyOrders.shipmentDetails.shipmentDetailsCard.user" | translate }}
            </div>
            <div class="card-content__row--value">
              {{shipment.CreatedBy}}
            </div>
          </div>
          <!-- Button to see complet shipment details -->
          <div class="card-content__row">
            <button mat-raised-button color="primary" style="margin-top: 10px; width: 280px;"
              (click)="goToShipmentSummary()">
              {{ "app.shopifyOrders.shipmentDetails.shipmentDetailsCard.completDetail" | translate }}
              &nbsp;
              <mat-icon>
                send
              </mat-icon>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- Waybills and documents -->
    <div class="shipment-details__row">
      <mat-card>
        <!-- Card title -->
        <mat-card-header>
          <mat-icon mat-card-avatar>
            print
          </mat-icon>
          <mat-card-title>
            {{ "app.shopifyOrders.shipmentDetails.printWaybills.title" | translate }}
          </mat-card-title>
        </mat-card-header>
        <!-- Card content -->
        <mat-card-content>
          <!-- Button to download all documents and waybills -->
          <div class="card-content__row">
            <button mat-raised-button color="primary" style=" width: 280px;" (click)="seeWaybill()">
              {{ "app.shopifyOrders.shipmentDetails.printWaybills.printWaybill" | translate }}
              &nbsp;
              <mat-icon>
                send
              </mat-icon>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- Get notifications -->
    <div class="shipment-details__row">
      <mat-card>
        <!-- Card title -->
        <mat-card-header>
          <mat-icon mat-card-avatar>notifications</mat-icon>
          <mat-card-title>Notifications</mat-card-title>
        </mat-card-header>

        <!-- Card content -->
        <mat-card-content>
          <!-- Button to get notifications -->
          <div class="card-content__row">
            <button mat-raised-button color="primary" style=" width: 280px;" (click)="getNotified()">
              {{ "app.activeOrders.GETNOTIFICATIONS" | translate }}&nbsp;
              <mat-icon>send</mat-icon>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- Pickup booked : REMOVED WHILE THE CODE BEHIND HIS NOT IMPLEMENTED ... TODO SOON-->
    <div class="shipment-details__row">
      <mat-card>

        <mat-card-header>
          <mat-icon mat-card-avatar>
            shopping_cart
          </mat-icon>
          <mat-card-title>
            {{ "app.shopifyOrders.shipmentDetails.pickupBooked.title" | translate }}
          </mat-card-title>
        </mat-card-header>

        <mat-card-content class="card-content">
          <div *ngIf="$pickupExist | async">
            <div class="card-content__row">
              <div class="card-content__row--title">
                {{ "app.shopifyOrders.shipmentDetails.pickupBooked.confirmation" | translate }}
              </div>
              <div class="card-content__row--value">
                {{shipment.Pickup.PKU_ResultConfirmationNumber}}
              </div>
            </div>
            <div class="card-content__row">
              <div class="card-content__row--title">
                {{ "app.shopifyOrders.shipmentDetails.pickupBooked.pickupRequestText" | translate }}
              </div>
              <div class="card-content__row--value">
                {{ shipment.Pickup.PKU_PickupDate  | date: 'yyyy-MM-dd'}},&nbsp;&nbsp;{{ shipment.Pickup.PKU_ReadyByTime }}
              </div>
            </div>
          </div>
          <div class="card-content__row">
            <button mat-raised-button color="primary" style="margin-top: 10px; width: 280px;"
              [disabled]="$pickupExist | async" (click)="createPickup()">
              {{ 'app.shopifyOrders.details.createPickupButton' | translate }}
              &nbsp;
              <mat-icon>
                send
              </mat-icon>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- Cancel shipment : REMOVED WHILE THE CODE BEHIND HIS NOT IMPLEMENTED ... TODO SOON-->
    <div class="shipment-details__row">
      <mat-card>
        <mat-card-header>
          <mat-icon mat-card-avatar>
            cancel
          </mat-icon>
          <mat-card-title>
            {{ "app.shopifyOrders.shipmentDetails.cancelShipment.title" | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="card-content__row">
            <button mat-raised-button color="primary" style=" width: 280px;" (click)="voidShipment()">
              {{ "app.shopifyOrders.details.voidShipmentButton" | translate }}&nbsp;
              <mat-icon>
                send
              </mat-icon>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<!-- The overlay to display when the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>
