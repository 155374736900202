<!---------------------------------------------------------------->
<!-- Installation required                                      -->
<!---------------------------------------------------------------->
<!-- npm i tslib --location=global                              -->
<!-- npm i angular-split --location=global                      -->
<!---------------------------------------------------------------->

<!-- Button to execute the task  -->
<!-- <button
  type="button"
  mat-raised-button
  color="primary"
  (click)="execute()">
  {{'app.administration.execute' | translate}}
  <mat-icon class="material-icons">
    play_arrow
  </mat-icon>
</button> -->

<style>
  .container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);
    overflow: hidden;
  }
</style>

<div style="background-color: rgb(223, 223, 223);border-radius: 14px;" class="container">
  <as-split direction="horizontal" style="border-radius: 14px">
    <as-split-area [size]="100">
      <mat-tab-group #TabGroup [@.disabled]="true" [disableRipple]="true" mat-stretch-tabs
        (selectedTabChange)="tabClick($event)">
        <mat-tab label="{{ 'app.administration.logsTabTitle' | translate }}">
          <!-- Search bar -->
          <div style="margin-left: 10px;margin-top:10px">
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="searchInput" (keyup)="applySearch($event.target.value)"
                placeholder="{{ 'app.administration.search' | translate }}" />
              <button mat-button *ngIf="searchInput" matSuffix mat-icon-button aria-label="Clear" (click)="applySearch('')">
                <mat-icon>
                  search
                </mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field style="margin-left: 100px" matTooltip="{{'app.administration.weekCountTooltip' | translate}}">
              <input matInput type="text" [(ngModel)]="weekCount"
                placeholder="{{ 'app.administration.weekCount' | translate }}" />
              <button mat-button *ngIf="weekCount" matSuffix mat-icon-button aria-label="Clear">
              </button>
            </mat-form-field>      
            <button style="margin-left: 20px" type="button" mat-raised-button color="primary" (click)="execute(false)">
              {{'app.administration.execute' | translate}}
              <mat-icon class="material-icons">
                play_arrow
              </mat-icon>
            </button>
            <button style="margin-left: 20px" type="button" mat-raised-button color="primary" (click)="execute(true)">
              {{'app.administration.empty' | translate}}
              <mat-icon class="material-icons">
                delete
              </mat-icon>
            </button>
          </div>

          <!-- Manage Logs -->
          <div class="my-table mat-elevation-z8">
            <mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="LOG_LogDatetime">
                <mat-header-cell style="max-width: 180px;" *matHeaderCellDef mat-sort-header>
                  {{ "app.administration.logDateTimeColumnHeader" | translate }}
                </mat-header-cell>
                <mat-cell style="max-width: 180px;" *matCellDef="let row"
                  [attr.data-label]="'app.administration.logDateTimeColumnHeader' | translate">
                  {{ formatDate(row.LOG_LogDatetime) }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="LOG_LogSource">
                <mat-header-cell style="max-width: 150px;" *matHeaderCellDef mat-sort-header>
                  {{ "app.administration.logSourceColumnHeader" | translate }}
                </mat-header-cell>
                <mat-cell style="max-width: 150px;" *matCellDef="let row"
                  [attr.data-label]="'app.administration.logSourceColumnHeader' | translate">
                  {{ row.LOG_LogSource }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="LOG_LogLevel">
                <mat-header-cell style="max-width: 150px;" *matHeaderCellDef mat-sort-header>
                  {{ "app.administration.logLevelColumnHeader" | translate }}
                </mat-header-cell>
                <mat-cell style="max-width: 150px;" *matCellDef="let row"
                  [attr.data-label]="'app.administration.logLevelColumnHeader' | translate">
                  {{ row.LOG_LogLevel }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="LOG_LogMessage">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ "app.administration.logMessageColumnHeader" | translate }}
                </mat-header-cell>
                <mat-cell class="clickable-cell" *matCellDef="let row"
                  [attr.data-label]="'app.administration.logMessageColumnHeader' | translate">
                  <div class="no-wrap" (click)="openDialog(row.LOG_LogMessage)">{{ row.LOG_LogMessage }}</div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true">
              </mat-header-row>

              <mat-row *matRowDef="let row; columns: displayedColumns">
              </mat-row>
            </mat-table>

            <!-- <mat-paginator [pageSizeOptions]="[10, 25, 50 , 100]" showFirstLastButtons="true">
            </mat-paginator> -->
          </div>
        </mat-tab>
        <mat-tab label="{{ 'app.administration.rightsTabTitle' | translate }}">
          <!-- Manage Rights -->
        </mat-tab>
      </mat-tab-group>
    </as-split-area>
  </as-split>
</div>

<!-- The overlay to display when the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate "></app-processing-overlay>