import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EditProductListComponent } from "./pages/edit-product-list/edit-product-list.component";

const routes: Routes = [
	{ path: "", component: EditProductListComponent },
	{ path: "products", component: EditProductListComponent }
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ]
})
export class ProductRoutingModule {}
