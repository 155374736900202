import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {
  public _isDrawerOpen = new BehaviorSubject<boolean>(true);
  isDrawerOpen$ = this._isDrawerOpen.asObservable();

  toggleDrawer(value: boolean = true): void {
      this._isDrawerOpen.next(!this._isDrawerOpen.value);
  }
}