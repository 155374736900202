<div style="margin:10px">
    <mat-label style="font-size:large;margin-left:5px">Users</mat-label>
    <br>
    <mat-form-field appearance="outline" style="width:10vw;margin-top:5px">
        <mat-select (selectionChange)="getUserRights($event.value)">
            <mat-option *ngFor="let user of usersList" [value]="user">
                <!-- <table>
                    <tr>
                        <td style="min-width:200px">{{ user.USR_FirstName }}</td>
                        <td style="min-width:200px">{{ user.USR_LastName }}</td>
                    </tr>
                </table> -->
                {{ user.USR_FirstName + " " + user.USR_LastName }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <br>
    <!-- <div class="user-rights-container mat-elevation-z8"> -->
        <mat-table [dataSource]="dataSource" >
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef style="max-width: 200px;">Name</mat-header-cell>
                <mat-cell *matCellDef="let element" style="max-width: 200px;">{{element.RightName}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef style="max-width: 400px;">Description</mat-header-cell>
                <mat-cell *matCellDef="let element" style="max-width: 400px;"> {{element.RightDescription}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="hasRight">
                <mat-header-cell *matHeaderCellDef style="max-width: 60px;">Has Right</mat-header-cell>
                <mat-checkbox *matCellDef="let element" [(ngModel)]="element.HasRight" style="max-width: 60px;" (change)="updateUserRight(element)"></mat-checkbox>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    <!-- </div> -->
</div>

<!-- Overlay displayed while the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>