<div style="margin-left:8px;margin-right:8px;overflow: auto;">
  <div class="table-header" style="margin-top:8px;margin-bottom:8px;float: right">
    <!-- <button mat-raised-button color="warn" mat-button (click)="removeSelectedRows()" style="margin-right:5px">
      {{ 'app.manageClients.removerows' | translate }}
      <mat-icon class="material-icons">
        delete
      </mat-icon>
    </button> -->
    <button mat-raised-button color="primary" mat-button (click)="addRow()" [disabled]="editing">
      {{ 'app.manageClients.addrow' | translate }}
      <mat-icon class="material-icons">
        add
      </mat-icon>
    </button>
  </div>
  <table mat-table matSort matSortActive="MGA_CarrierName" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource" style="width:100%;font-size:6px !important">
    <ng-container [matColumnDef]="col.key" *ngFor="let col of COLUMNS_SCHEMA">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="col.key == 'isEdit'" [ngStyle]="{'text-align': col.type == 'checkbox' ? 'center' : 'normal'}">
        <div *ngIf="translateService.currentLang == 'fr'">{{ col.label }}</div>
        <div *ngIf="translateService.currentLang != 'fr'">{{ col.labelEn }}</div>
      </th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'text-align': col.type == 'checkbox' ? 'center' : 'normal'}">
        <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
          <ng-container *ngSwitchCase="'isSelected'">
            <mat-checkbox (change)="element.isSelected = $event.checked"></mat-checkbox>
          </ng-container>
          <div class="btn-edit" *ngSwitchCase="'isEdit'">
            <div *ngIf="!editing">
              <button mat-button class="button-edit" (click)="editRateInsurances(element)">
                <mat-icon class="material-icons" matTooltip="{{ 'app.importOrders.ttEdit' | translate }}" matTooltipPosition="above">
                  edit
                </mat-icon>
              </button>
              <button mat-button class="button-remove" (click)="removeRow(element)">
                <mat-icon class="material-icons" matTooltip="{{ 'app.importOrders.ttDelete' | translate }}" matTooltipPosition="above">
                  delete
                </mat-icon>
              </button>
            </div>
          </div>
          <span *ngSwitchCase="'date'">
            {{ element[col.key] | date: 'mediumDate' }}
          </span>
          <span *ngSwitchCase="'checkbox'">
            <div *ngIf="element[col.key]" style="margin-top:8px">
              <mat-icon class="material-icons">
                check_box
              </mat-icon>
            </div>
            <div *ngIf="!element[col.key]" style="margin-top:8px">
              <mat-icon class="material-icons">
                check_box_outline_blank
              </mat-icon>
            </div>
          </span>
          <span *ngSwitchDefault>
            {{ element[col.key] }}
          </span>
        </div>
        <div [ngSwitch]="col.type" *ngIf="element.isEdit">
          <!-- <div *ngSwitchCase="'isSelected'"></div> -->
          <div class="btn-edit" *ngSwitchCase="'isEdit'" style="white-space: nowrap;">
            <button mat-button (click)="cancelEdit(element)">
              <mat-icon class="material-icons" matTooltip="{{ 'app.importOrders.ttCancel' | translate }}" matTooltipPosition="above">
                cancel
              </mat-icon>
            </button>
            <button mat-button (click)="element.isEdit = saveChanges(element)">
              <mat-icon class="material-icons" matTooltip="{{ 'app.importOrders.ttSave' | translate }}" matTooltipPosition="above">
                done
              </mat-icon>
            </button>
          </div>
          <mat-checkbox *ngSwitchCase="'checkbox'" [(ngModel)]="element[col.key]" [checked]="element[col.key].MGI_UseGlobex"></mat-checkbox>
          <mat-form-field class="form-input" *ngSwitchDefault>
            <input [type]="col.type" matInput [(ngModel)]="element[col.key]" />
          </mat-form-field>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="highlight(row)" [ngClass]="{'highlightTableColor': selectedRateInsuranceId == row.MGI_ID}"></tr>
  </table>
</div>
  
<!-- Overlay displayed while the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>
  