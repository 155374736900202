<!-- Main container -->
<div id="printable">
  <table width="100%">
    <tbody>
      <!-- The header of the summary page -->
      <tr width="100%">
        <!-- title -->
        <td width="49%">
          <div id="title"> {{"app.shipmentSummary.summaryTitle" | translate }} </div>
        </td>
        <td width="2%"></td>
        <!-- Status and last tracking date -->
        <td width="49%" align="right">
          <!-- Status and last tracking date  -->
          <div id="status">
            <!-- French status display -->
            <div *ngIf="currentLanguage === 'fr'">
              <!--Manifest-->
              <mat-chip *ngIf="data.orderSummary.StatusColor === 2" class="status color-purple">
                {{  data.orderSummary.StatusFr }}
              </mat-chip>
              <!--Transit-->
              <mat-chip *ngIf="data.orderSummary.StatusColor === 3" class="status color-yellow">
                {{  data.orderSummary.StatusFr }}
              </mat-chip>
              <!-- Execption -->
              <mat-chip *ngIf="data.orderSummary.StatusColor === 4" class="status color-blue">
                {{  data.orderSummary.StatusFr }}
              </mat-chip>
              <!--Shipped-->
              <mat-chip *ngIf="data.orderSummary.StatusColor === 5" class="status color-green">
                {{  data.orderSummary.StatusFr }}
              </mat-chip>
              <!--Cancelled-->
              <mat-chip *ngIf="data.orderSummary.StatusColor === 6" class="status color-red">
                {{  data.orderSummary.StatusFr }}
              </mat-chip>
            </div>

            <!-- English status display -->
            <div *ngIf="currentLanguage === 'en'">
              <!--Manifest-->
              <mat-chip *ngIf="data.orderSummary.StatusColor === 2" class="status color-purple">
                {{  data.orderSummary.StatusEn }}
              </mat-chip>
              <!--Transit-->
              <mat-chip *ngIf=" data.orderSummary.StatusColor === 3" class="status color-yellow">
                {{  data.orderSummary.StatusEn }}
              </mat-chip>
              <!-- Exception -->
              <mat-chip *ngIf=" data.orderSummary.StatusColor === 4" class="status color-blue">
                {{  data.orderSummary.StatusEn }}
              </mat-chip>
              <!--Shipped-->
              <mat-chip *ngIf=" data.orderSummary.StatusColor === 5" class="status color-green">
                {{  data.orderSummary.StatusEn }}
              </mat-chip>
              <!--Cancelled-->
              <mat-chip *ngIf=" data.orderSummary.StatusColor === 6" class="status color-red">
                {{  data.orderSummary.StatusEn }}
              </mat-chip>
            </div>

            <!-- Last tracking date-->
            <div class="delivery-date">
              {{"app.shipmentSummary.lastTrackingDate" | translate }}{{ data.orderSummary.Order.ORD_LastTrackingDate | date: 'yyyy-MM-dd'}}
            </div>
          </div>
        </td>
      </tr>

      <!-- Pickup and shipment service informations -->
      <tr width="100%">
        <!-- Pickup service information -->
        <td width="49%">
          <div id="pickupInfo">
            <div class="pickup-info-title"> {{"app.shipmentSummary.pickupInfoTitle" | translate }} </div>
            <strong> {{"app.shipmentSummary.confirmationNumber" | translate }} </strong>
            {{ data.orderSummary.Pickup != null ? data.orderSummary.Pickup.PKU_ResultConfirmationNumber : "" }}
            <br />
            <strong> {{"app.shipmentSummary.orderNumber" | translate }} </strong> {{ data.orderSummary.Order.ORD_ID }}
            <br>
            <strong> {{"app.shipmentSummary.pickupDate" | translate }} </strong>
            {{ data.orderSummary.Pickup != null ? (data.orderSummary.Pickup.PKU_ResultPickupDate | date: 'yyyy-MM-dd') : "" }}
            <br />
            <strong> {{"app.shipmentSummary.pickupTime" | translate }} </strong>
            {{ data.orderSummary.Pickup != null ? data.orderSummary.Pickup.PKU_ResultReadyByTime : "" }}
            <div *ngIf="data.orderSummary.Quote.OQU_PaidByShipper === true">
              <strong> {{"app.shipmentSummary.paidByShipper" | translate }} </strong> {{ 'app.misc.yes' | translate }}
            </div>
            <div *ngIf="data.orderSummary.Quote.OQU_PaidByShipper === false">
              <strong> {{"app.shipmentSummary.paidByShipper" | translate }} </strong> {{ 'app.misc.no' | translate }}
            </div>
            <div *ngIf="IsDutiable()"> 
              <strong> {{"app.shipmentSummary.DitiesAndTaxesPaidBy" | translate }} </strong> {{ GetDitiesAndTaxesPaidBy(data.orderSummary.Dutiable.ODU_DutiesAndTaxesPaidBy) }}
            </div>
          </div>
        </td>
        <td width="2%"></td>
        <!-- Shipment service information -->
        <td width="49%">
          <div id="shipmentInfo">
            <!-- Section Title -->
            <div class="shipment-info-title"> {{"app.shipmentSummary.shippingInfo" | translate }} </div>

            <!-- Carrier logo -->
            <strong> {{"app.shipmentSummary.carrier" | translate }} </strong>{{ data.orderSummary.Order.ORD_Carrier}}
            <br>
            <strong> {{"app.shipmentSummary.service" | translate }}
            </strong>{{ data.orderSummary.Order.ORD_ServiceTypeDescription}}
            <br>
            <strong> {{"app.shipmentSummary.trackingNumber" | translate }}
            </strong>{{ data.orderSummary.Order.ORD_TrackingNumber }}
            <br>
            <strong> {{"app.shipmentSummary.shipmentDate" | translate }}
            </strong>{{ data.orderSummary.Order.ORD_ShipmentDate | date: 'yyyy-MM-dd' }}
            <br />
            <strong> {{"app.shipmentSummary.deliveryDate" | translate }}
            </strong>{{ data.orderSummary.Order.ORD_DeliveryDate | date: 'yyyy-MM-dd' }}
            <br />
            <strong> {{"app.shipmentSummary.userReference" | translate }} </strong>{{ data.orderSummary.CreatedBy }}
            <br>
            <strong> {{"app.shipmentSummary.shipmentReference" | translate }}
            </strong>{{ data.orderSummary.Order.ORD_Reference }}
          </div>
        </td>
      </tr>

      <!-- Adresses for pickup and delivery -->
      <tr width="100%">
        <td width="49%">
          <!-- Pickup address -->
          <div id="pickupAddress">
            <div class="pickup-address-title"> {{"app.shipmentSummary.pickupAddressTitle" | translate }} </div>
            <strong>{{"app.shipmentSummary.address" | translate }}</strong>
            <span
              *ngIf="data.orderSummary.OriginAddress.OAD_IsResidential === true">{{"app.shipmentSummary.residential" | translate }}</span>
            <br>
            &nbsp;{{ data.orderSummary.OriginAddress.OAD_Company }}
            <br>
            &nbsp;{{ data.orderSummary.OriginAddress.OAD_StreetName}}
            &nbsp;{{ data.orderSummary.OriginAddress.OAD_Unit}}
            <br />
            &nbsp;{{ data.orderSummary.OriginAddress.OAD_City }},
            &nbsp;{{ data.orderSummary.OriginAddress.OAD_State }}
            <br>
            &nbsp;{{ data.orderSummary.OriginAddress.OAD_CountryName }},
            &nbsp;{{ data.orderSummary.OriginAddress.OAD_Zip}}
            <br>
            <strong>{{"app.shipmentSummary.contact" | translate }}</strong>
            <br>
            &nbsp;{{ data.orderSummary.OriginContact.OAC_Name }}
            <br>
            &nbsp;{{ data.orderSummary.OriginContact.OAC_Phone }}&nbsp;&nbsp;{{ data.orderSummary.OriginContact.OAC_PhoneExt }}
            <br>
            &nbsp;{{ data.orderSummary.OriginContact.OAC_Email }}
            <div *ngIf="data.orderSummary.OriginAddress.OAD_SpecialInstructions">
              <strong>{{"app.shipmentSummary.specialInstruction" | translate }}</strong>
              <br>
              &nbsp;{{ data.orderSummary.OriginAddress.OAD_SpecialInstructions }}
            </div>
          </div>
        </td>
        <td width="2%"></td>
        <td width="49%">
          <!-- Delivery address -->
          <div id="deliveryAddress">
            <div class="delivery-address-title"> {{"app.shipmentSummary.deliveryAddressTitle" | translate }} </div>
            <strong>{{"app.shipmentSummary.address" | translate }}</strong>
            <span
              *ngIf="data.orderSummary.DestinationAddress.OAD_IsResidential === true">{{"app.shipmentSummary.residential" | translate }}</span>
            <br>
            &nbsp;{{ data.orderSummary.DestinationAddress.OAD_Company }}
            <br>
            &nbsp;{{ data.orderSummary.DestinationAddress.OAD_StreetName}}
            &nbsp;{{ data.orderSummary.DestinationAddress.OAD_Unit}}
            <br />
            &nbsp;{{ data.orderSummary.DestinationAddress.OAD_City }},
            &nbsp;{{ data.orderSummary.DestinationAddress.OAD_State }}
            <br>
            &nbsp;{{ data.orderSummary.DestinationAddress.OAD_CountryName }},
            &nbsp;{{ data.orderSummary.DestinationAddress.OAD_Zip}}
            <br>
            <strong>{{"app.shipmentSummary.contact" | translate }}</strong>
            <br>
            &nbsp;{{ data.orderSummary.DestinationContact.OAC_Name }}
            <br>
            &nbsp;{{ data.orderSummary.DestinationContact.OAC_Phone }}&nbsp;&nbsp;{{ data.orderSummary.DestinationContact.OAC_PhoneExt }}
            <br>
            &nbsp;{{ data.orderSummary.DestinationContact.OAC_Email }}
            <div *ngIf="data.orderSummary.DestinationAddress.OAD_SpecialInstructions">
              <strong>{{"app.shipmentSummary.specialInstruction" | translate }}</strong>
              <br>
              &nbsp;{{ data.orderSummary.DestinationAddress.OAD_SpecialInstructions }}
            </div>
          </div>
        </td>
      </tr>

      <!-- Pieces details-->
      <tr width="100%">
        <td width="100%">
          <!-- Pieces details -->
          <div id="piecesDetail">
            <div class="pieces-detail-title"> {{"app.shipmentSummary.piecesDetailTitle" | translate }} </div>
            <strong>{{"app.shipmentSummary.totalPieces" | translate }} : </strong> {{getTotalPieces()}}
            &nbsp;&nbsp;&nbsp;&nbsp;<strong>{{"app.shipmentSummary.totalWeight" | translate }} :
            </strong>{{data.orderSummary.TotalWeight}} {{ data.orderSummary.Order.ORD_UnitOfMeasure == "Metric" ? "Kgs" : "Lbs" }}
            &nbsp;&nbsp;&nbsp;&nbsp;<strong>{{"app.shipmentSummary.billingWeight" | translate }} :
            </strong>{{getTotalBillingWeight()}} {{ data.orderSummary.Order.ORD_UnitOfMeasure == "Metric" ? "Kgs" : "Lbs" }}

            <mat-table [dataSource]="packagesDataSource">
              <!-- Package Type -->
              <ng-container matColumnDef="PackageType">
                <mat-header-cell class="mat-table-style" *matHeaderCellDef>
                  {{ "app.shipmentSummary.type" | translate }}
                </mat-header-cell>
                <mat-cell class="mat-table-style" *matCellDef="let element">
                  {{ element.OPA_PackageType }}
                </mat-cell>
              </ng-container>

              <!-- Quantity -->
              <ng-container matColumnDef="Quantity">
                <mat-header-cell class="mat-table-style" *matHeaderCellDef>
                  {{ "app.shipmentSummary.quantity" | translate }}
                </mat-header-cell>
                <mat-cell class="mat-table-style" *matCellDef="let element">
                  {{ element.OPA_Quantity }}
                </mat-cell>
              </ng-container>


              <!-- Width -->
              <ng-container matColumnDef="Width">
                <mat-header-cell class="mat-table-style" *matHeaderCellDef>
                  {{ "app.shipmentSummary.width" | translate }}
                </mat-header-cell>
                <mat-cell class="mat-table-style" *matCellDef="let element">
                  {{ element.OPA_Width }} {{ data.orderSummary.Order.ORD_UnitOfMeasure == "Metric" ? "cm" : data.orderSummary.Order.ORD_Language == "French" ? "po" : "in" }}
                </mat-cell>
              </ng-container>

              <!-- Height -->
              <ng-container matColumnDef="Height">
                <mat-header-cell class="mat-table-style" *matHeaderCellDef>
                  {{ "app.shipmentSummary.height" | translate }}
                </mat-header-cell>
                <mat-cell class="mat-table-style" *matCellDef="let element">
                  {{ element.OPA_Height }} {{ data.orderSummary.Order.ORD_UnitOfMeasure == "Metric" ? "cm" : data.orderSummary.Order.ORD_Language == "French" ? "po" : "in" }}
                </mat-cell>
              </ng-container>

              <!-- Depth -->
              <ng-container matColumnDef="Depth">
                <mat-header-cell class="mat-table-style" *matHeaderCellDef>
                  {{ "app.shipmentSummary.depth" | translate }}
                </mat-header-cell>
                <mat-cell class="mat-table-style" *matCellDef="let element">
                  {{ element.OPA_Length }} {{ data.orderSummary.Order.ORD_UnitOfMeasure == "Metric" ? "cm" : data.orderSummary.Order.ORD_Language == "French" ? "po" : "in" }}
                </mat-cell>
              </ng-container>

              <!-- Weight -->
              <ng-container matColumnDef="Weight">
                <mat-header-cell class="mat-table-style" *matHeaderCellDef>
                  {{ "app.shipmentSummary.weight" | translate }}
                </mat-header-cell>
                <mat-cell class="mat-table-style" *matCellDef="let element">
                  {{ element.OPA_Weight }} {{ data.orderSummary.Order.ORD_UnitOfMeasure == "Metric" ? "Kgs" : "Lbs" }}
                </mat-cell>
              </ng-container>

              <!-- Volumetric Weight -->
              <ng-container matColumnDef="VolumeWeight">
                <mat-header-cell class="mat-table-style" *matHeaderCellDef>
                  {{ "app.shipmentSummary.volumeWeight" | translate }}
                </mat-header-cell>
                <mat-cell class="mat-table-style" *matCellDef="let element; let packageIndex=index">
                  {{ getVolumeWeight(packageIndex) }} {{ data.orderSummary.Order.ORD_UnitOfMeasure == "Metric" ? "Kgs" : "Lbs" }}
                </mat-cell>
              </ng-container>

              <!-- Table header definition -->
              <mat-header-row *matHeaderRowDef="displayedColumns">
              </mat-header-row>

              <!-- Table row definition -->
              <mat-row *matRowDef="let row; columns: displayedColumns">
              </mat-row>
            </mat-table>

          </div>

        </td>
      </tr>

      <!-- Options and Price estimate-->
      <tr width="100%">
        <td width="39%">
          <!-- Options -->
          <div id="options">
            <div class="options-title"> {{"app.shipmentSummary.options" | translate }} </div>

            <!-- Insurance -->
            <div *ngIf="data.orderSummary.Quote.OQU_IsInsurance === true">
              <strong>{{"app.shipmentSummary.insuranceValue" | translate }}: </strong>
              {{ data.orderSummary.Quote.OQU_InsuranceValue }} $ {{ data.orderSummary.Quote.OQU_InsuranceCurrency }}
            </div>
            <div *ngIf="data.orderSummary.Quote.OQU_IsInsurance === false">
              <strong>{{"app.shipmentSummary.insuranceTitle" | translate }}: </strong> {{ 'app.misc.no' | translate }}
            </div>

            <!-- Signature required -->
            <div *ngIf="data.orderSummary.Quote.OQU_IsSignatureRequired === true">
              <strong>{{"app.shipmentSummary.signatureRequired" | translate }}: </strong>
              {{ 'app.misc.yes' | translate }}
            </div>
            <div *ngIf="data.orderSummary.Quote.OQU_IsSignatureRequired === false">
              <strong>{{"app.shipmentSummary.signatureRequired" | translate }}: </strong>{{ 'app.misc.no' | translate }}
            </div>

            <!-- Document only -->
            <div *ngIf="data.orderSummary.Quote.OQU_IsDocumentOnly === true">
              <strong>{{"app.shipmentSummary.documentOnly" | translate }}: </strong> {{ 'app.misc.yes' | translate }}
            </div>
            <div *ngIf="data.orderSummary.Quote.OQU_IsDocumentOnly === false">
              <strong>{{"app.shipmentSummary.documentOnly" | translate }}: </strong>{{ 'app.misc.no' | translate }}
            </div>

            <!-- Saturday delivery -->
            <div *ngIf="data.orderSummary.Quote.OQU_IsSaturdayDelivery === true">
              <strong>{{"app.shipmentSummary.saturdayDelivery" | translate }}: </strong>
              {{ 'app.misc.yes' | translate }}
            </div>
            <div *ngIf="data.orderSummary.Quote.OQU_IsSaturdayDelivery === false">
              <strong>{{"app.shipmentSummary.saturdayDelivery" | translate }}: </strong>{{ 'app.misc.no' | translate }}
            </div>

            <!-- Dry ice -->
            <div *ngIf="data.orderSummary.Quote.OQU_IsDryIce === true">
              <strong>{{"app.shipmentSummary.dryIce" | translate }}: </strong> {{ 'app.misc.yes' | translate }}
            </div>
            <div *ngIf="data.orderSummary.Quote.OQU_IsDryIce === false">
              <strong>{{"app.shipmentSummary.dryIce" | translate }}: </strong>{{ 'app.misc.no' | translate }}
            </div>
          </div>
        </td>
        <td width="2%"></td>
        <td width="59%">
          <!-- Estimated price -->
          <div id="estimate">
            <div class="estimate-title"> {{ "app.shipmentSummary.priceEstimate" | translate }} </div>

            <!-- Base price -->
            <div style="text-align: right;">
              <strong>{{ "app.shipmentSummary.basePrice" | translate }}</strong>&nbsp;
              {{data.orderSummary.Quote.OQU_BasePrice | number : '.2-2'}} $
            </div>

            <div *ngIf="addedFees === false">
              <strong>{{ "app.shipmentSummary.noAdditionnalFee" | translate }}</strong>
            </div>

            <!-- Additionnal fees title-->
            <div *ngIf="addedFees === true">
              <strong>{{ "app.shipmentSummary.additionnalFees" | translate }}</strong>
            </div>

            <!-- Additionnal Fees Table-->
            <div *ngIf="addedFees === true">
              <mat-table [dataSource]="additionnalFeesDataSource">
                <ng-container matColumnDef="AdditionnalFeesType">
                  <mat-cell *matCellDef="let element" class="mat-table-style">
                    {{ element.OSU_Description }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Fees">
                  <mat-cell *matCellDef="let element" class="mat-table-style">
                    {{ element.OSU_Amount  | number : '.2-2'}} $
                  </mat-cell>
                </ng-container>

                <!-- Table row definition -->
                <mat-row *matRowDef="let row; columns: feesTableColumns"></mat-row>
              </mat-table>
            </div>

            <div *ngIf="taxable === true && addedFees=== true" style="text-align: right;">
              <strong>{{ "app.shipmentSummary.subtotal" | translate }}</strong> &nbsp;
              {{ getShipmentSubtotalPrice() | number : '.2-2'}} $
            </div>

            <div *ngIf="taxable === false">
              <strong>No taxes</strong>
            </div>

            <!-- Taxes title-->
            <div *ngIf="taxable === true">
              <strong>{{ "app.shipmentSummary.taxes" | translate }}</strong>
            </div>

            <!-- Taxes Table -->
            <div *ngIf="taxable === true">
              <mat-table [dataSource]="taxesDataSource" style="border-top: 1px solid white;">
                <ng-container matColumnDef="TaxesType">

                  <!-- Taxe type -->
                  <mat-cell *matCellDef="let element" class="mat-table-style">
                    {{ element.OTA_Type }}
                  </mat-cell>
                </ng-container>

                <!-- Taxe amount -->
                <ng-container matColumnDef="Taxe">
                  <mat-cell *matCellDef="let element" class="mat-table-style">
                    {{ element.OTA_Amount  | number : '.2-2'}} $
                  </mat-cell>
                </ng-container>

                <!-- Table row definition -->
                <mat-row *matRowDef="let row; columns: taxesTableColumns"></mat-row>
              </mat-table>
            </div>

            <!-- Total estimated price -->
            <div style="text-align: right;">
              <strong>{{ "app.shipmentSummary.total" | translate }}</strong> &nbsp;
              {{ data.orderSummary.Quote.OQU_TotalPrice | number : '.2-2'}} $
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Button for actions -->
<div id="notPrintable">
  <button mat-raised-button style="margin-right:15px" color="primary" printSectionId="printable" ngxPrint
    [useExistingCss]="true" styleSheetFile="src\app\shared\components\shipment-summary\shipment-summary.component.scss">
    {{"app.misc.print" | translate}}
  </button>
  <button mat-raised-button mat-dialog-close>{{"app.misc.close" | translate}}</button>
</div>
