import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GetShopifyProductsResult } from "@app/shared/CSDAModels/GetShopifyProductsResult";
import { environment } from "@env";
import { Observable } from "rxjs/Observable";
import { RefreshProductsResult } from "@app/shared/CSDAModels/RefreshProductsResult";
import { ValidationService } from "@app/core/services/validation.service";
import Swal from "sweetalert2";
import { map } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class ProductService {
	constructor(
		private httpClient: HttpClient,
		private validationService: ValidationService,
	) { }

	public getShopifyProducts(): Observable<GetShopifyProductsResult> {
		return this.httpClient.post(
			environment.CSDAEndPoint + "/CSDA/GetShopifyProducts", null
		).pipe(
			map((data: GetShopifyProductsResult) => {
				return data;
			})
		);
	}

	public refreshProducts(): Observable<RefreshProductsResult> {
		return this.httpClient.post<RefreshProductsResult>(environment.CSDAEndPoint + "/CSDA/RefreshProducts", { responseType: 'json' })
		.pipe(
			map(data => {
				var validation = this.validationService.validate(data);

				if (validation.isValid === false) {
					Swal.fire('', validation.message, "error");
					return null;
				}

				if (validation.message !== "") {
					Swal.fire('', validation.message, "info");
				}

				return data !== undefined ? data : null;
			})
		);	
	}
}
