import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class UtilsService {
    constructor() { }

    ConvertToMetric(IsWeight: boolean, ValueToConvert: number): number {
        let weightImperialToMetricFactor = 0.45359237;
        let dimImperialToMetricFactor = 2.54;
        let result: number;

        if (IsWeight)
            result = ValueToConvert * weightImperialToMetricFactor;
        else
            result = ValueToConvert * dimImperialToMetricFactor;

        return Math.round(result * 100) / 100;
    }

    ConvertToImperial(IsWeight: boolean, ValueToConvert: number): number {
        let weightMetricToImperialFactor = 2.20462;
        let dimMetricToImperialFactor = 0.393701;
        let result: number;

        if (IsWeight)
            result = ValueToConvert * weightMetricToImperialFactor;
        else
            result = ValueToConvert * dimMetricToImperialFactor;

        return result;
    }
}
