import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class HeaderService {
	public title = new BehaviorSubject("Title");
	public leftMenuIsOpened = true;

	constructor() {}

	setTitle(title: string): void {
		this.title.next(title);
	}
}
