import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

import { MaterialModule } from "./material/material.module";
import { LoadingScreenComponent } from "./components/loading-screen/loading-screen.component";
import { BoxMessageComponent } from './components/box-message/box-message.component';
import { ProcessingOverlayComponent } from './components/processing-overlay/processing-overlay.component';
import { CreatePickupDialogComponent } from './components/create-pickup-dialog-box/create-pickup-dialog/create-pickup-dialog.component';
import { CompletAddressFormComponent } from "./components/complet-address-form/complet-address-form.component";
import { ShipmentDetailsComponent } from './components/shipment-details/shipment-details.component';
import { ShipmentSummaryComponent } from "./components/shipment-summary/shipment-summary.component";
import { ProformatComponent } from './components/proformat/proformat/proformat.component';
import { ProformatService } from "./components/proformat/proformat/proformat.service";
import { NgxPrintModule } from "ngx-print";
import { CostBreakdownComponent } from './components/cost-breakdown/cost-breakdown.component';
import { NgxMaskModule } from 'ngx-mask';
//import { NgPaymentCardModule } from "./components/creditcard/ng-payment-card.module";
import { SpinnerComponent } from "@app/shared/components/Spinner/Spinner.component";
import { NgPaymentCardModule } from "./components/creditcard/ng-payment-card.module";

const sharedModules = [
	LoadingScreenComponent,
	BoxMessageComponent,
	ProcessingOverlayComponent,
	CreatePickupDialogComponent,
	CompletAddressFormComponent,
	ShipmentDetailsComponent,
	ShipmentSummaryComponent,
	ProformatComponent,
	SpinnerComponent
];
@NgModule({
    declarations: [sharedModules, CostBreakdownComponent],
    imports: [CommonModule, MaterialModule, ReactiveFormsModule, TranslateModule, NgxPrintModule, NgxMaskModule.forRoot(), NgPaymentCardModule],
    exports: [sharedModules, ReactiveFormsModule, FormsModule, MaterialModule, TranslateModule],
    providers: [DatePipe, ProformatService]
})
export class SharedModule { }
