import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { SharedModule } from "../shared/shared.module";
import { MainMenuComponent } from "./layout/main-menu/main-menu.component";
import { MainViewComponent } from "./layout/main-view/main-view.component";
import { AuthenticationInterceptor } from "./interceptors/authentication.interceptor";

@NgModule({
	declarations: [MainViewComponent, MainMenuComponent],
	imports: [CommonModule, RouterModule, HttpClientModule, SharedModule],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthenticationInterceptor,
			multi: true
		}
	],
	exports: []
})
export class CoreModule { }
