import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from "./admin-routing.module";
import { SharedModule } from "@app/shared/shared.module";
import { AngularSplitModule } from "angular-split";
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    AngularSplitModule,
    MatDialogModule
  ],
  declarations: [AdminComponent]
})
export class AdminModule { }
