import { Orders } from "./Orders";
import { OrdersAddContacts } from "./OrdersAddContacts";
import { OrdersAddresses } from "./OrdersAddresses";
import { OrdersDutiable } from "./OrdersDutiable";
import { OrdersPackages } from "./OrdersPackages";
import { OrdersProducts } from "./OrdersProducts";
import { OrdersQuote } from "./OrdersQuote";
import { OrdersSurcharges } from "./OrdersSurcharges";
import { OrdersTaxes } from "./OrdersTaxes";
import { Pickups } from "./Pickups";

export class OrderResult {
    Order: Orders;
    OriginAddress: OrdersAddresses;
    OriginContact: OrdersAddContacts;
    DestinationAddress: OrdersAddresses;
    DestinationContact: OrdersAddContacts;
    IorAddress: OrdersAddresses;
    IorContact: OrdersAddContacts;
    Dutiable: OrdersDutiable;
    DutiableProducts: OrdersProducts[];
    Packages: OrdersPackages[];
    Quote: OrdersQuote;
    BasePrice : number;
    Surcharges: OrdersSurcharges[];
    Taxes: OrdersTaxes[];
    Pickup: Pickups;
    CreatedBy: string;
    StatusColor: number
    StatusFr: string;
    StatusEn: string;
    TotalWeight: number;
}