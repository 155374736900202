<div style="margin-left:8px;margin-right:8px;overflow: auto;">
    <div class="table-header" style="margin-top:8px;margin-bottom:8px;float: right">
        <!-- <button mat-raised-button color="warn" mat-button (click)="removeSelectedRows()" style="margin-right:5px">
        {{ 'app.manageClients.removerows' | translate }}
        <mat-icon class="material-icons">
            delete
        </mat-icon>
        </button> -->
        <button mat-raised-button color="primary" mat-button (click)="addRow()" [disabled]="editing">
        {{ 'app.manageClients.addrow' | translate }}
        <mat-icon class="material-icons">
            add
        </mat-icon>
        </button>
    </div>
    <table mat-table matSort matSortActive="USR_FirstName" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource" style="width:100%;">
        <ng-container [matColumnDef]="col.key" *ngFor="let col of COLUMNS_SCHEMA">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="col.key == 'isEdit'">
            <div *ngIf="translateService.currentLang == 'fr'">{{ col.label }}</div>
            <div *ngIf="translateService.currentLang != 'fr'">{{ col.labelEn }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
            <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
            <ng-container *ngSwitchCase="'isSelected'">
                <mat-checkbox (change)="element.isSelected = $event.checked"></mat-checkbox>
            </ng-container>
                <div class="btn-edit" *ngSwitchCase="'isEdit'" style="white-space: nowrap;">
                <div *ngIf="!editing">
                    <button mat-button class="button-edit" (click)="editUser(element)">
                        <mat-icon class="material-icons" matTooltip="{{ 'app.importOrders.ttEdit' | translate }}" matTooltipPosition="above">
                            edit
                        </mat-icon>
                    </button>
                    <button mat-button class="button-remove" (click)="removeRow(element)">
                        <mat-icon class="material-icons" matTooltip="{{ 'app.misc.deactivate' | translate }}" matTooltipPosition="above">
                            toggle_off
                        </mat-icon>
                    </button>
                </div>
            </div>
            <span *ngSwitchCase="'checkboxAPI'">
                <div *ngIf="element[col.key]" style="margin-top:8px">
                  <mat-icon class="material-icons">
                    check_box
                  </mat-icon>
                </div>
                <div *ngIf="!element[col.key]" style="margin-top:8px">
                  <mat-icon class="material-icons">
                    check_box_outline_blank
                  </mat-icon>
                </div>
              </span>
            <span *ngSwitchDefault>
                {{ element[col.key] }}
            </span>
            </div>
            <div [ngSwitch]="col.type" *ngIf="element.isEdit">
            <div *ngSwitchCase="'isSelected'"></div>
            <mat-checkbox *ngSwitchCase="'checkboxAPI'" [(ngModel)]="element[col.key]" [checked]="element[col.key].USR_API" (change)="passwordHandler(element)"></mat-checkbox>
            <div *ngSwitchCase="'password'">
                <mat-form-field class="form-input">
                    <input type="text" matInput [(ngModel)]="element[col.key]" [value]="element[col.key].USR_Password" #password />
                </mat-form-field>
            </div>
            <div class="btn-edit" *ngSwitchCase="'isEdit'" style="white-space: nowrap;">
                <button mat-button (click)="cancelEdit(element)">
                <mat-icon class="material-icons" matTooltip="{{ 'app.importOrders.ttCancel' | translate }}" matTooltipPosition="above">
                    cancel
                </mat-icon>
                </button>
                <button mat-button (click)="element.isEdit = saveChanges(element)">
                <mat-icon class="material-icons" matTooltip="{{ 'app.importOrders.ttSave' | translate }}" matTooltipPosition="above">
                    done
                </mat-icon>
                </button>
            </div>
            <mat-form-field class="form-input" *ngSwitchDefault>
                <input [type]="col.type" matInput [(ngModel)]="element[col.key]" />
            </mat-form-field>
            </div>
        </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="highlight(row)" [ngClass]="{'highlightTableColor': selectedUserId == row.USR_ID}"></tr>
    </table>
</div>
  
<!-- Overlay displayed while the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>
  