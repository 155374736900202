
import { Component, Input } from '@angular/core';
import { BaseComponentDirective } from "@app/core/base.component";
import { AlertType } from "@app/core/enums/alert-type";
import 'sweetalert2/src/sweetalert2.scss'

@Component({
  selector: 'app-step3-shipment-detail',
  templateUrl: './step3-shipment-detail.component.html',
  styleUrls: ['./step3-shipment-detail.component.scss']
})
export class Step3ShipmentDetailComponent extends BaseComponentDirective {

  @Input() shpId: number;

  readonly alertTypeEnum = AlertType;

  constructor() {
    super();
  }

  onInit(): void { }

  onDestroy(): void { }
}

