import { ShopifyOrdersResult } from "@app/shared/CSDAModels/GetShopifyOrdersResult";

export class ShopifyOrdersTableModel {
    public orderNumber              : string;
    public trackingNumber           : string;
    public shipmentCarrier          : string;
    public shipmentService          : string;
    public shipmentPrice            : string;
    
    public destinationName          : string;
    public destinationStreetName    : string;
    public destinationCityName      : string;
    public destinationStateCode     : string;
    public destinationCountryCode   : string;
    
    public shippingCarrier          : string;
    public shippingService          : string;
    public shippingServiceName      : string;
    public shippingPrice            : string;
    public createdBy                : string;

    public searchRandomString       : string 
    
    public OriginalModel  : ShopifyOrdersResult;

    /** Constructor of the ShopifyOrdersTableModel class */
    constructor(response: ShopifyOrdersResult) {
        
        this.orderNumber = response.ShopifyOrder.OrderNumber.toString();  
        this.trackingNumber = response.CSDADetail?.TrackingNumber;
        this.shipmentCarrier = response.CSDADetail?.ShippingCarrier;
        this.shipmentService = response.CSDADetail?.ShippingServiceType;
        this.shippingServiceName = response.ShopifyOrder.ShippingServiceType.replace('_', ' ');
        if(response.CSDADetail?.ShippingPrice != undefined){
            this.shipmentPrice = response.CSDADetail?.ShippingPrice.toFixed(2).toString() + ' $';
        }
        
        this.destinationName = response.Destination.Name;
        this.destinationStreetName = response.Destination.StreetName;
        this.destinationCityName = response.Destination.CityName;
        this.destinationStateCode = response.Destination.StateCode;
        this.destinationCountryCode = response.Destination.CountryCode;

        this.shippingCarrier = response.ShopifyOrder.ShippingCarrier;
        this.shippingService = response.ShopifyOrder.ShippingServiceType;
        this.createdBy = response.CSDADetail?.CreatedBy;
        
        if(response.ShopifyOrder.ShippingPrice !== undefined){
            this.shippingPrice = response.ShopifyOrder.ShippingPrice.toFixed(2).toString() + ' $' ; // format to have 2 decimals
        }

        /** Build a string to search even if the value is hidden in the complet orders response */
        var tmp = "";
        response.Packages.forEach(function (value) {
            tmp = tmp + 
            ' ' + value.Description + 
            ' ' + value.Quantity +
            ' ' + value.Length + 
            ' ' + value.Width +
            ' ' + value.Height;
        });
        
        this.searchRandomString = tmp;

        this.OriginalModel = response;
    }

}