import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { spCSDAManageRateInsuranceGetListResult } from "@app/shared/CSDAModels/ManageClientModels/RateInsurance/spCSDAManageRateInsuranceGetListResult";
import { ManageClientsService } from "../_Services/manage-clients.service";
import Swal from "sweetalert2";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

@Component({
  selector: 'RateInsurances',
  templateUrl: './RateInsurances.component.html',
  styleUrls: ['./RateInsurances.component.scss']
})

export class RateInsurancesComponent implements OnInit, AfterViewInit  {

  //---------------------------------------------------
  // Define the fields of the model which are displayed
  //---------------------------------------------------
  COLUMNS_SCHEMA = this.getSchema();
  displayedColumns: string[] = this.COLUMNS_SCHEMA.map((col) => col.key);
  dataSource = new MatTableDataSource([]);
  isProcessing = false;
  Div_Id = -1;
  selectedRateInsuranceId = -1;
  originalRateInsurance: spCSDAManageRateInsuranceGetListResult;
  editing = false;

  constructor(
    private translateService: TranslateService,
    private ManageClientsService: ManageClientsService,
  ) { }

  @Output('selectedRateInsurance') selectedRateInsurance: EventEmitter<number> = new  EventEmitter<number>();
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
  }

  ngOnInit(): void {
  }

  getSchema() {
    return [
      // {
      //   key: 'isSelected',
      //   type: 'isSelected',
      //   label: '',
      //   visible: true
      // },
      {
        key: 'MGI_MinValue',
        type: 'number',
        label: "Valeur Minimum",
        labelEn: 'Minimum Value'
      },
      {
        key: 'MGI_MaxValue',
        type: 'number',
        label: "Valeur Maximum",
        labelEn: 'Maximum Value'
      },
      {
        key: 'MGI_UseGlobex',
        type: 'checkbox',
        label: "Utiliser Globex",
        labelEn: 'Use Globex'
      },
      {
        key: 'MGI_PercentCharge',
        type: 'number',
        label: "Charge %",
        labelEn: '% Charge'
      },
      {
        key: 'MGI_MinMoneyCharge',
        type: 'number',
        label: "Valeur Min. Chargé",
        labelEn: 'Min. Value Charge'
      },
      {
        key: 'isEdit',
        type: 'isEdit',
        label: ''
      }
    ];
  }
  
  getRateInsurances(id: any) {
    if(id != -1) {
      this.Div_Id = id;
      const DivId = id != -1 ? id : this.Div_Id;
      this.Div_Id = DivId;
      this.isProcessing = true;
      this.editing = false;
      this.selectedRateInsuranceId = -1;
      
      this.ManageClientsService.getRateInsurances(DivId).subscribe(Result => {
        this.dataSource = new MatTableDataSource(Result.RateInsurancesList);
        this.dataSource.sort = this.sort;
        this.isProcessing = false;
      })
    } else {
      this.dataSource = null;
    }
  }

  addRow() {
    const newRow = {
      MGI_ID: -1,
      MGI_Div_Ref: this.Div_Id,
      MGI_MinValue: null,
      MGI_MaxValue: null,
      MGI_UseGlobex: false,
      MGI_PercentCharge: null,
      MGI_MinMoneyCharge: null,
      isEdit: true,
    };

    this.editing = true;
    this.dataSource.data = [newRow, ...this.dataSource.data];
  }

  removeRow(element) {
    Swal.fire({
      title: 'Confirmation',
      icon: "question",
      showDenyButton: true,
      denyButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes"),

    }).then((result) => {
      if (result.isConfirmed) {
        this.isProcessing = true;

        this.ManageClientsService.deleteRateInsurance(element).subscribe(Result => {
          this.isProcessing = false;
          this.dataSource.data = this.dataSource.data.filter((u) => u.MGI_ID != element.MGI_ID);
        });
      }
    });    
  }

  removeSelectedRows() {
    Swal.fire({
      title: 'Confirmation',
      icon: "question",
      showDenyButton: true,
      denyButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes"),

    }).then((result) => {
      if (result.isConfirmed) {
        this.dataSource.data = this.dataSource.data.filter((u: any) => !u.isSelected);
      }
    });    
  }

  saveChanges(element: any): boolean {
    this.isProcessing = true;

    const index = this.dataSource.data.findIndex(object => {
      return object.MGI_ID === element.MGI_ID;
    });

    this.ManageClientsService.saveRateInsurance(element).subscribe(Result => {
      this.dataSource.data[index].MGI_ID = Result.MGI_ID;
      this.isProcessing = false;
    });
    
    this.editing = false;
    return false;
  }

  editRateInsurances(element: any) {
    this.editing = true;
    element.isEdit = true;
    this.originalRateInsurance = JSON.parse(JSON.stringify(element));
  }

  cancelEdit(element: any) {
    if(element.MGI_ID == -1)
      this.dataSource.data = this.dataSource.data.filter((u) => u.MGI_ID != element.MGI_ID);
    else {
      element.MGI_MinValue = this.originalRateInsurance.MGI_MinValue;
      element.MGI_MaxValue = this.originalRateInsurance.MGI_MaxValue;
      element.MGI_UseGlobex = this.originalRateInsurance.MGI_UseGlobex;
      element.MGI_PercentCharge = this.originalRateInsurance.MGI_PercentCharge;
      element.MGI_MinMoneyCharge = this.originalRateInsurance.MGI_MinMoneyCharge;
    }
    
    this.editing = false;
    element.isEdit = false;
  }

  highlight(row){
    if(!this.editing)
      this.selectedRateInsuranceId = row.MGI_ID;
  }

}
