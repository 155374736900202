<div style="margin-top:20px;margin-bottom:10px;display:inline-flex">
    <div class="container">
        <div class="button-wrap">
            <label class="button" for="fileUpload">{{ 'app.importOrders.selectFile' | translate }}</label>
            <input id="fileUpload" type="file" (change)="selectFile($event)" accept=".xlsx">
        </div>
    </div>
    <button
        mat-flat-button
        color="primary"
        [disabled]="!fileName"
        (click)="upload()">
            {{ 'app.importOrders.upload' | translate }}
            <mat-icon class="material-icons">
                upload
            </mat-icon>        
    </button>
    <div id="uploadToastTarget"></div>
</div>
<hr class="separator">
<span id="fileName" class="with-icon"><mat-icon *ngIf="fileName">cloud_upload</mat-icon>&nbsp;&nbsp;{{ fileName }}</span>

<div *ngIf="uploadProgress">
    <div>{{uploadProgress}}%</div>
    <mat-progress-bar [value]="uploadProgress"></mat-progress-bar>
</div>

<!-- The overlay to display when the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>
