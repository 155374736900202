import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManageClientsViewLayoutRoutingModule } from './Layout-routing.module';
import { SharedModule } from "@app/shared/shared.module";
import { ClientsModule } from "../Clients/Clients.module";
import { DivisionsModule } from "../Divisions/Divisions.module";
import { UsersModule } from "../Users/Users.module";
import { RatesModule } from "../Rates/Rates.module";
import { RateInsurancesModule } from "../RateInsurances/RateInsurances.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule, 
    SharedModule,
    ManageClientsViewLayoutRoutingModule,
    ClientsModule,
    DivisionsModule,
    UsersModule,
    RatesModule,
    RateInsurancesModule,
  ]
})
export class ManageClientsViewLayoutModule { }
