import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Component, ViewChild } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { BaseComponentDirective } from "@app/core/base.component";
import { HeaderService } from "@app/core/services/header.service";

import { delay } from "rxjs/internal/operators";

import { MediaqueryService } from "@app/core/services/mediaquery.service";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "@app/core/services/storage.service";
import { LoaderService } from "@app/core/services/loader.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "@app/core/services/authentication.service";
import { DrawerService } from '../../services/drawer.service';
import { UsersRights } from "@app/shared/CSDAModels/UserRights";

interface languages {
	value: string;
	text: string;
}

@Component({
	selector: "app-main-view",
	templateUrl: "./main-view.component.html",
	styleUrls: ["./main-view.component.scss"]
})
export class MainViewComponent extends BaseComponentDirective {
	public showIcon = true;
	isShowForMobile = false;
	public title = "";
	public loading: boolean;
	isOffline = false;
	@ViewChild("drawer", { static: false })
	drawer: MatDrawer;
	CurrentUsersRights: UsersRights[];
	displayedLanguage = "EN";
	isGuest: boolean = false;

	set language(lang: string) {
		this.translate.use(lang);
	}

	get language(): string {
		return this.translate.currentLang;
	}

	constructor(
		private drawerService: DrawerService,
		private router: Router, 
		private authenticationService: AuthenticationService,
		public headerService: HeaderService,
		private mediaqueryService: MediaqueryService,
		private breakpointObserver: BreakpointObserver,
		public translate: TranslateService,
		public storageService: StorageService,
		private loadingScreenService: LoaderService
	) {
		super();
		super.subscribe(
			this.breakpointObserver
				.observe([this.mediaqueryService.MobileLarge])
				.subscribe((state: BreakpointState) => {
					this.isShowForMobile = state.matches;
					if (!this.isShowForMobile) {
						this.showIcon = !!!this.headerService.leftMenuIsOpened;
						this.drawerService.toggleDrawer();
					}

					this.drawerService._isDrawerOpen.next(!this.isShowForMobile);
				})
		);
	}

	onInit(): void {
		this.CurrentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));
		
		if(this.CurrentUsersRights.find(c => c.Uri_Rit_Ref == 6))
			this.isGuest = true;

		this.displayedLanguage = this.storageService.getLanguage()==="en" ? "FR" : "EN";

		super.subscribe(
			this.headerService.title.pipe(delay(0)).subscribe((title) => {
				this.title = title;
			})
		);
		super.subscribe(
			this.loadingScreenService.isLoading
				.pipe(delay(0))
				.subscribe((state) => {
					this.loading = state;
				})
		);
	}

	onDestroy(): void { }

	logout(): void {
		sessionStorage.removeItem("guestQuickQuote");
		sessionStorage.removeItem("isGuest");
		this.authenticationService.logout();
		void this.router.navigate(["/account/login"]);
	}

	onDrawerClosed(): void {
		this.showIcon = true;
	}

	onDrawerChange(): void {
		this.showIcon = !this.showIcon;
		void this.drawer.toggle();
		this.drawerService.toggleDrawer();
	}

	switchLang(lang: string): void {
		this.translate.use(lang);
		this.storageService.setLanguage(lang);
	}

	switchLanguage(): void {

		if (this.displayedLanguage === "FR") {
			this.displayedLanguage = "EN";
			this.translate.use("fr");
			this.storageService.setLanguage("fr");
		}
		else {
			this.displayedLanguage = "FR";
			this.translate.use("en");
			this.storageService.setLanguage("en");
		}

	}

	getUser(): string {
		return this.storageService.getUser();
	}
}
