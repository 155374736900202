<!-- Create pickup dialog window -->
<mat-dialog-content [formGroup]="form">
  <!-- Title -->
  <h1>{{ 'app.createPickups.createPickupTitle' | translate }}</h1>

  <!-- Content -->
  <div>
    <!-- Pickup date and Ready by time -->
    <div>
      <!-- Pickup date -->
      <mat-form-field>
        <input
          matInput
          [readonly]="true"
          placeholder="{{ 'app.createPickups.pickupDate' | translate }}"
          formControlName="pickupDate"
          [matDatepicker]="picker"
          [min]="minDate">
        <mat-datepicker-toggle
          matSuffix
          [for]="picker">
        </mat-datepicker-toggle>
        <mat-datepicker
          #picker>
        </mat-datepicker>
      </mat-form-field>
      &nbsp;&nbsp;&nbsp;

      <!-- Ready by time -->
      <mat-form-field>
        <input
          matInput
          [mask]="'00:00'"
          maxlength="5"
          matTooltip="{{ 'app.createPickups.amFormatExample' | translate }}"
          required
          placeholder="{{ 'app.createPickups.readyByTime' | translate }}"
          formControlName="readyByTime">
      </mat-form-field>
    </div>

    <!-- Business hours -->
    <div>
      <!-- Business hours from -->
      <mat-form-field>
        <input
          matInput
          [mask]="'00:00'"
          maxlength="5"
          matTooltip="{{ 'app.createPickups.amFormatExample' | translate }}"
          required
          placeholder="{{ 'app.createPickups.businessHoursFrom' | translate }}"
          formControlName="businessHoursFrom">
      </mat-form-field>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

      <!-- Business hours to -->
      <mat-form-field>
        <input
          matInput
          [mask]="'00:00'"
          maxlength="5"
          matTooltip="{{ 'app.createPickups.pmFormatExample' | translate }}"
          required
          placeholder="{{ 'app.createPickups.businessHoursTo' | translate }}"
          formControlName="businessHoursTo">
      </mat-form-field>
    </div>

    <!-- Costumer reference and instructions -->
    <div
      style="display:flex; flex-direction: column;">
      <!-- Customer reference -->
      <mat-form-field>
        <textarea
          matInput
          maxlength="40"
          placeholder="{{ 'app.createPickups.customerReference' | translate }}"
          formControlName="customerReference">
        </textarea>
      </mat-form-field>

      <!-- Pickup instructions -->
      <mat-form-field>
        <textarea
          matInput
          maxlength="90"
          placeholder="{{ 'app.createPickups.instructions' | translate }}"
          formControlName="instructions">
        </textarea>
      </mat-form-field>
    </div>

    <!-- Action buttons -->
    <div 
      style="display:flex; align-content: stretch;">
      <!-- Create pickup button -->
      <button
        mat-raised-button
        color="primary"
        style="margin: 10px; width: 190px;"
        (click)="save()">
        {{ 'app.createPickups.create' | translate }}
      </button>

      <!-- Cancel button -->
      <button
        mat-raised-button
        style="margin: 10px; width: 190px;"
        (click)="close()">
        {{ 'app.misc.cancel' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>
