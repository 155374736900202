import { AfterViewInit, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HeaderService } from "@app/core/services/header.service";
import { LanguageService } from "@app/core/services/language.service";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { Observable } from "rxjs";
import { GetLogsCriteriasResult } from "@app/shared/CSDAModels/GetLogsCriteriasResult";
import { GetLogsCriteriasDetail } from "@app/shared/CSDAModels/GetLogsCriteriasDetail";
import { Language as LanguageEnum } from "@app/core/enums/Language";
import { environment } from "@env";
import { ValidationService } from "@app/core/services/validation.service";
import Swal from 'sweetalert2'
import { map } from "rxjs/operators";
import { Logs } from "@app/shared/CSDAModels/Logs";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})

export class AdminComponent implements OnInit {
  weekCount: number;
	searchInput = "";
  currentLanguage: LanguageEnum;
  isProcessing = false;
	dataSource = new MatTableDataSource([]);
	displayedColumns: string[] = [
		"LOG_LogDatetime",
		"LOG_LogSource",
		"LOG_LogLevel",
		"LOG_LogMessage",
	];
	
  selection = new SelectionModel<any>(true, []);
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('logMessageTemplate') logMessageTemplate: TemplateRef<any>;
  
  constructor(
		private headerService: HeaderService,
		private translateService: TranslateService,
		private languageService: LanguageService,
    private http: HttpClient,
		public validationService: ValidationService,
		private httpClient: HttpClient,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) { 
  }

  ngOnInit() {
    this.headerService.setTitle(this.translateService.instant("app.administration.title"));
    
    this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.headerService.setTitle(this.translateService.instant("app.administration.title"));
    });
  }

  applySearch(searchValue: string): void {

		/** If the filter value is an empty string we clear the seach bar */
		if (!searchValue) {
			this.searchInput = '';
		}

		/** Then we apply filter */
		this.dataSource.filter = searchValue.trim().toLowerCase();
	}

  openDialog(message: string): void {
    this.dialog.open(LogMessageDialogComponent, {
      width: '1024px',
      height: '768px',
      data: { message }
    });
  }

	public getLogs(isEmpty: boolean): Observable<GetLogsCriteriasResult> {
		var Detail: GetLogsCriteriasDetail = {
			Language: this.getCurrentLanguage(),
			IsDevelopmentEnvironment: false,
      IsTruncateTable: isEmpty,
      WeekCount: !this.weekCount ? 1 : this.weekCount
		};

		return this.httpClient.post<GetLogsCriteriasResult>(environment.CSDAEndPoint + "/CSDA/GetLogs", Detail)
		.pipe(
			map(data => {
        var validation = this.validationService.validate(data);

        if (validation.isValid === false) {
          Swal.fire('', validation.message, "error");
          return null;
        }

        if (validation.message !== "") {
          Swal.fire('', validation.message, "info");
        }

        return data;
			})
		);
	}

  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }

	getCurrentLanguage(): LanguageEnum {
		if (this.translateService.currentLang === "fr") {
			return this.currentLanguage = LanguageEnum.French;
		}
		else {
			return this.currentLanguage = LanguageEnum.English;
		}
	}
  execute(isEmpty: boolean) {
    if(isEmpty) {
      Swal.fire({
        title: '',
        html: '<span style="white-space: nowrap;">' + this.translateService.instant("app.misc.areYouSure") + '</span>',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.translateService.instant("app.misc.yes"),
        cancelButtonText: this.translateService.instant("app.misc.no")
      }).then((result) => {
        if (result.isConfirmed) {
          this.isProcessing = true;
          this.getLogs(true).subscribe(result => {
            this.dataSource = new MatTableDataSource(result.LogsData);
            this.isProcessing = false;
            this.dataSource.sort = this.sort;
            // this.dataSource.paginator = this.paginator;

            // if (this.paginator) {
            //   this.paginator._intl.itemsPerPageLabel = 'Items : ';
            // }    
          });
        }
      });     
    }
    else {
      this.isProcessing = true;
      this.getLogs(false).subscribe(result => {
        this.dataSource = new MatTableDataSource(result.LogsData);
        this.dataSource.sort = this.sort;
        this.isProcessing = false;
        // this.dataSource.paginator = this.paginator;

        // if (this.paginator) {
        //   this.paginator._intl.itemsPerPageLabel = 'Items : ';
        // }    
      });
    }
  }

  tabClick(tab: { index: number; }) {
    if(tab.index == 1)
      console.debug("");
  } 
}

@Component({
  selector: 'log-message-dialog',
  template: `
    <div class="dialog-container">
      <h1 mat-dialog-title>Log Message</h1>
      <div mat-dialog-content #dialogContent class="dialog-content">
        {{ data.message }}
      </div>
    </div>
  `,
  styleUrls: ['./admin.component.css'],
})

export class LogMessageDialogComponent implements AfterViewInit {
  @ViewChild('dialogContent') dialogContent: any;
  constructor(
    public dialogRef: MatDialogRef<LogMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string }
  ) {}

  ngAfterViewInit(): void {
    this.dialogContent.nativeElement.scrollTop = 0; // Ensure the content starts at the top
  }
  
  onClose(): void {
    this.dialogRef.close();
  }
}