//#region Imports & Component definition
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Language } from "@app/core/enums/Language";
import { HttpClient } from "@angular/common/http";
import { ValidationService } from "@app/core/services/validation.service";
import { environment } from "@env";
import { map } from "rxjs/operators";
import { HeaderService } from "@app/core/services/header.service";
import { Products } from "@app/shared/CSDAModels/Products";
import { GetCustomProductsResult } from "@app/shared/CSDAModels/GetCustomProductsResult";
import { GetCustomProductsDetail } from "@app/shared/CSDAModels/GetCustomProductsDetail";
import { SaveCustomProductsDetail } from "@app/shared/CSDAModels/SaveCustomProductsDetail";
import { SaveCustomProductsResult } from "@app/shared/CSDAModels/SaveCustomProductsResult";
import { Countries } from "@app/shared/CSDAModels/Countries";
import { CustomDictionariesService } from "@app/core/services/custom-dictionaries.service";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from "@angular/material/sort";
import Swal from "sweetalert2";
import { Observable } from "rxjs";
import { UnitOfMeasure } from "@app/core/enums/unit-of-measure";
import { Product } from "../product/models/product";
import { MatTableDataSource } from "@angular/material/table";
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from "@angular/material/tooltip";
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

const matTooltipDefaultOptions: MatTooltipDefaultOptions = {
  disableTooltipInteractivity: true,
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0
};

export enum Appearance {
  STANDARD = 'standard',
  FILL = 'fill',
  OUTLINE = 'outline',
  LEGACY = 'legacy',
}

@Component({
  templateUrl: './custom-products.component.html',
  styleUrls: ['./custom-products.component.scss'],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipDefaultOptions }]
})
//#endregion

export class CustomProductsComponent implements OnInit {

  //#region Inputs, Outputs and ViewChild
  @ViewChild('saveCustomProductsControl', { read: ElementRef }) saveCustomProductsControl:ElementRef;
  @ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() appearance: string | Appearance = Appearance.OUTLINE;
  //#endregion
  //#region Properties
  isProcessing = false;
  isMetric = true;
  searchInput = "";
  enableAdd = true;
  customProducts: Products[] = [];
  countriesList: Countries[];
  measures = this.customDictionaries.measures;
  modifiedProduct: Products;
	dataSource = new MatTableDataSource([]);
	displayedColumns: string[] = [
		"Pro_Name",
		"Pro_Desc",
		"Pro_CON_Ref",
		"Pro_HSCode",
		"Pro_Quantity",
		"Pro_Weight",
		"Pro_UnitValue",
		"UnitOfMeasure",
    "actions"
	];
  
  //#endregion  
  //#region Constructor
  constructor(
    private customDictionaries: CustomDictionariesService,
		private translateService: TranslateService,
    private httpClient: HttpClient,
    private validationService: ValidationService,
		private headerService: HeaderService
  )
    { }
  //#endregion
  //#region OnInit
  //---------------------------------------------------
  // Enable display and set default values of the forms
  //---------------------------------------------------
  ngAfterViewInit() {
    setTimeout(() => {
      this.countriesList = JSON.parse(sessionStorage.getItem("Countries")) as Countries[];
      if (this.paginator && this.paginator._intl) {
        this.paginator._intl.itemsPerPageLabel = 'Items : ';
      }      
    }, 0);
  }
  ngOnInit(): void {
    this.isProcessing = true;

    //-------------------
    // Set the page title
    //-------------------
    this.headerService.setTitle(this.translateService.instant("app.customProducts.title"));

    //---------------------------------------------------------------------------------
    // Subscribe to the translate service to update the title when the language changes
    //---------------------------------------------------------------------------------
    this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.headerService.setTitle(this.translateService.instant("app.customProducts.title"));
    }); 

    this.getCustomProducts().subscribe(result => {
      this.customProducts = result.Products;
      this.dataSource = new MatTableDataSource(this.customProducts);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isProcessing = false;
    });
  }
  //#endregion
  //#region Methods
	//---------------------------------------------------------------------
	// Used to get the name of the country since the dataSource have the id
	//---------------------------------------------------------------------
	getCountryName(Pro_CON_Ref: number) {
		var country: Countries = this.countriesList.find(c => c.CON_ID == Pro_CON_Ref);

		if(country)
			return country.CON_Name;
		else
			return "";
	}
  getUOMName(code: UnitOfMeasure): string | undefined {
    const measure = this.measures.find(m => m.code === code);
    return measure ? this.translateService.instant(measure.name) : undefined;
  }
  getCustomProducts(): Observable<GetCustomProductsResult> {
    const Detail: GetCustomProductsDetail = {
      Language: Language.English,
      IsDevelopmentEnvironment: false
    };

    return this.httpClient.post<GetCustomProductsResult>(environment.CSDAEndPoint + "/CSDA/GetCustomProducts", Detail)
      .pipe(
          map(data => {
              var validation = this.validationService.validate(data);
              var IsValid:boolean = true;

              if (validation.isValid === false) {
                  Swal.fire('', validation.message, "error");
                  IsValid = false;
              }

              return data;
          })
      );    
  }

  onUnitOfMeasureChange(value, customPackage: Products) {
    var customPackageFound = this.customProducts.find(cp => cp.Pro_Id == customPackage.Pro_Id);
    
    if(value == UnitOfMeasure.Imperial) {
      this.isMetric = false;
    }
    else {
      this.isMetric = true;
    }
  }


  customProductsValid(): boolean {
    //---------------------------------------------------------------
    // Validate contacts in a Template-Driven Two Way Binding context
    //---------------------------------------------------------------
    var element: Products;
    var IsValid = true;

    for(element of this.customProducts) {
      if(!element.Pro_Name && element.Pro_Action != "DELETE") {
        IsValid = false;
      }
    }

    if(this.customProducts.filter(c => c.Pro_Action != "DELETE").length == 0)
      IsValid = false;
    
    return IsValid;
  }

  onAddCustomProduct() {
    this.customProducts.push({
      Pro_Id: -this.customProducts.length - 1,
      Pro_Action: "ADD", // Makes the line in edit mode automatically
      Pro_Name: "",
      Pro_Desc: "",
      Pro_CON_Ref: null,
      Pro_HSCode: "",
      Pro_Quantity: null,
      Pro_UnitValue: null,
      Pro_Div_Ref: null,
      Pro_ShopifyId: null,
      Pro_Width: null,
      Pro_Length: null,
      Pro_Height: null,
      Pro_Weight: null,
      UnitOfMeasure: UnitOfMeasure.Metric
    });

    this.dataSource = new MatTableDataSource(this.customProducts);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.applySearch(this.searchInput);
  }

  onRemoveCustomProduct(customProduct: Products)
  {
    var customProductFound = this.customProducts.find(cp => cp.Pro_Id == customProduct.Pro_Id);
    customProductFound.Pro_Action = "DELETE";

    Swal.fire({
      title: this.translateService.instant("app.misc.delete"),
      icon: "question",
      showDenyButton: true,
      denyButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes"),

    }).then((result) => {
      if (result.isConfirmed) {
        this.isProcessing = true;
        let products: Products[] = [customProductFound];
    
        var Detail: SaveCustomProductsDetail = {
          //--------------------
          // Deep clone Products
          //--------------------
          Products: JSON.parse(JSON.stringify(products)),
          Language: Language.English,
          IsDevelopmentEnvironment: false
        }

        return this.httpClient.post<SaveCustomProductsResult>(environment.CSDAEndPoint + "/CSDA/SaveCustomProducts", Detail)
        .subscribe(data => {
              this.isProcessing = false;
              var validation = this.validationService.validate(data);
                var IsValid:boolean = true;
    
                if (validation.isValid === false) {
                    Swal.fire('', validation.message, "error");
                    IsValid = false;
                }
                else {
                  this.customProducts = this.customProducts.filter(product => product.Pro_Id !== customProduct.Pro_Id);
                  this.dataSource = new MatTableDataSource(this.customProducts);
                  this.dataSource.sort = this.sort;
                  this.dataSource.paginator = this.paginator;
                  this.applySearch(this.searchInput);
                }
    
                return IsValid
            })
      }
    });
  }

	/** Find the specified string in the data table
	 * @param searchValue - The partial or complet word to find
	 */
	applySearch(searchValue: string): void {

		/** If the filter value is an empty string we clear the seach bar */
		if (!searchValue) {
			this.searchInput = '';
      this.enableAdd = true;
		}
    else
      this.enableAdd = false;

		/** Then we apply filter */
		this.dataSource.filter = searchValue.trim().toLowerCase();
	}
    
  onCancel(row: Products) {
    if(row.Pro_Action == "ADD") {
      this.customProducts = this.customProducts.filter(product => product.Pro_Id !== row.Pro_Id);
    }
    else {
      this.customProducts = this.customProducts.map(cp => {
        if (cp.Pro_Id == row.Pro_Id) {
          return this.modifiedProduct; // Revert back to the original
        } else {
          return cp;
        }
      });
    }

    this.dataSource = new MatTableDataSource(this.customProducts);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.applySearch(this.searchInput);
  }
  
  onModifyCustomProduct(customProduct: Products) {
    this.modifiedProduct = {...customProduct};
    var customProductFound = this.customProducts.find(cp => cp.Pro_Id == customProduct.Pro_Id);
    customProductFound.Pro_Action = "UPDATE";
  }

  onSaveCustomProduct(customProduct: Products) {
    this.isProcessing = true;
    var customProductFound = this.customProducts.find(cp => cp.Pro_Id == customProduct.Pro_Id);
    let products: Products[] = [customProductFound];

    var Detail: SaveCustomProductsDetail = {
      //--------------------
      // Deep clone Products
      //--------------------
      Products: JSON.parse(JSON.stringify(products)),
      Language: Language.English,
      IsDevelopmentEnvironment: false
    }

    return this.httpClient.post<SaveCustomProductsResult>(environment.CSDAEndPoint + "/CSDA/SaveCustomProducts", Detail)
    .pipe(
      catchError(error => {
        return throwError(error);
      })
    )    
    .subscribe(data => {
      this.isProcessing = false;
      var validation = this.validationService.validate(data);
      var IsValid:boolean = true;

      if (validation.isValid === false) {
          Swal.fire('', validation.message, "error");
          IsValid = false;
      }
      else {
        Swal.fire({
          icon: "info",
          title: this.translateService.instant("app.misc.saved"),
          confirmButtonText: 'OK'
        }).then((sweetAlertResult) => {});
          
        customProductFound.Pro_Action = "";
      }

      return IsValid
    },
    error => {
      this.isProcessing = false;

      Swal.fire({
        icon: "info",
        title: error,
        confirmButtonText: 'OK'
      }).then((sweetAlertResult) => {});
    })
  }
  //#endregion
}
