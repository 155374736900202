export enum PackageType {
    NONE = 0,
    ENVELOPPE = 1,
    BOX = 2,
    PALETTE = 3,
    CARRIER_PACK = 4,
    CUSTOM = 5,
    BUNDLE = 6,
    WOODEN_CRATE = 7,
    SPECIMEN = 8,
    CYLINDER = 9,
    COOLER = 10
}