<!-- Import Orders Page -->
<div class="">
    <div style="font-weight: bold;color:red">
      <span>{{'app.misc.importNote' | translate}}</span><br>
    </div>
    <br>
    <button mat-raised-button color="primary" mat-button (click)="getTemplate()">
        {{ 'app.importOrders.getTemplate' | translate }}
        <mat-icon class="material-icons">
          download
        </mat-icon>
    </button>
    <br>
    <app-upload-orders></app-upload-orders>
</div>
  
<!-- The overlay to display when the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>
  